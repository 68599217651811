export const updateByPropertyName = (propertyName, value) => ({ [propertyName]: value });

export  function toDataURL(url, callback) {
    const httpRequest = new XMLHttpRequest();
    httpRequest.onload = function () {
        const fileReader = new FileReader();
        fileReader.onloadend = function () {
            callback(fileReader.result);
        }
        fileReader.readAsDataURL(httpRequest.response);
    };
    httpRequest.open('GET', url,true);
    httpRequest.responseType = 'blob';
    httpRequest.send();
}