import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import moment from "moment";
import 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";


import store from './redux/store';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import Routes from './routes';
// import Demo from './component/common/demo';

const font = "'Lato', sans-serif";

moment().utcOffset("+00:00");
// moment.tz.setDefault("Asia/Shanghai");
disableReactDevTools();


function disableReactDevTools() {
    // Ensure the React Developer Tools global hook exists
    if (!isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        return;
    }

    const NO_OP = () => {};

    // Replace all global hook properties with a no-op function or a null value
    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]
        )
            ? NO_OP
            : null;
    }
}

function isFunction(obj) {
    return typeof obj === 'function' || false;
}

function isObject(obj) {
    var type = typeof obj;
    return type === 'function' || (type === 'object' && !!obj);
}
const theme = createMuiTheme({
    fontFamily: font,
    typography: {
        fontFamily: font,
        useNextVariants: true,
    },
});

function BootStrapApp() {
    return (
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                <ThemeProvider theme={theme}>
                    <Routes />
                </ThemeProvider>
            </MuiPickersUtilsProvider>`
        </Provider>
    )
}

ReactDOM.render(<BootStrapApp />, document.getElementById('root'));
registerServiceWorker();
