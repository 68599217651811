import { API } from 'aws-amplify';

import {
    takeEvery,
    fork,
    all,
    put,
} from 'redux-saga/effects';
import {
    FETCH_STAFF,
    fetchStaff,
    FETCH_STUDENTS,
    fetchStudents
} from '../actions/post-manage';
import { REQUEST } from '../actions/common';

/* async function saveInAWSStorage(files) {
    return await Promise.all(files.map(async (file) => {
        const path = `${moment().year()}/${moment().month() + 1}/${moment().date()}/${v4()}.${file.type.split('/')[1]}`;
        const {
            key
        } = await Storage.put(path, file, {
            contentType: file.type
        });
        return await Object.assign(file, {
            preview: URL.createObjectURL(file),
            key: `public/${key}`
        })
    }));
} */


function* fetchStaffGenerator(action) {
    try {
        const init = {
            body: {
                groupid: action.query
            }
        }
        const payload = yield API.post('get_staff', '', init)
        if (!payload.errorMessage) {
            yield put(fetchStaff.success(payload.data))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchStaff.failure(error))
    }
}

function* fetchStudentsGenerator(action) {
    try {
        const init = {
            body: {
                groupid: action.query
            }
        }
        const payload = yield API.post('get_student', '', init)
        if (!payload.errorMessage) {
            yield put(fetchStudents.success(payload.data))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchStudents.failure(error))
    }
}
/** watcher */

function* watcherfetchStaff() {
    yield takeEvery(FETCH_STAFF[REQUEST], fetchStaffGenerator)
}

function* watcherfetchStudents() {
    yield takeEvery(FETCH_STUDENTS[REQUEST], fetchStudentsGenerator)
}

export default function* rootPostManage() {
    yield all([
        fork(watcherfetchStaff),
        fork(watcherfetchStudents),
    ]);
}