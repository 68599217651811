import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Amplify from 'aws-amplify'
import * as routes from './path';
import awsConfig from '../utils/aws-config';
import Layout from '../layout/layout';
import Login from '../container/login';
import ProtectRoute from '../component/common/ProtectedRoute';
import LoginRoute from '../component/common/LoginRoute';

export default function Routes() {
  Amplify.configure(awsConfig)
  return (
    <BrowserRouter>
      <Switch>
        <LoginRoute component={Login} path={routes.SIGN_IN} />
        <ProtectRoute component={Layout} path={routes.ROOT} />
      </Switch>
    </BrowserRouter>
  );
}

