import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SaveAlt from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import attendance from '../service/attendance';
import ReactExport from "react-data-export";

import { setDateRange, setClassId, setSearchText, fetchAttendanceData } from '../redux/actions/attendance';

import * as routes from '../routes/path';


const styles = theme => ({
    rootContainer: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        margin: theme.spacing(1),
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(1) * 2,
    },
    tableContainer: {
        margin: theme.spacing(1),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    paper: {
        ...theme.mixins.gutters(),
        padding: theme.spacing(1) * 2,
        minWidth: 200,
    },
    emptyRow: {
        display: 'flex',
        justifyContent: "center"
    },
    fab: {
        margin: theme.spacing(1),
    },
});


const dateFormat = 'YYYY/MM/DD';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const INITIAL_STATE = {
    fromdate: moment().format('YYYY/MM/DD'),
    todate: moment().format('YYYY/MM/DD'),
    _Groups: [],
    classid: null,
    search: '',
    tableRow: [],
    data: []

}

const { RangePicker } = DatePicker;

function StatusCard({ classes, ...props }) {
    return (
        <Paper className={classes.paper} elevation={1}>
            <Grid container wrap="nowrap" spacing={4}>
                <Grid item>
                    <Typography variant="body1" component="h2" >
                        {props.title}:
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6" component="h3" >
                        <span style={{ color: props.color }}> {props.value} </span>
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

class AttendanceSummary extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        const { fromdate, todate, classid } = this.props;
        this.fetchSummaryByClass(fromdate, todate, classid);
    }

    onRangePickerChange = (date, dateString) => {
        const { dispatch, classid } = this.props;
        const fromdate = dateString[0], todate = dateString[1];
        const data = { fromdate, todate };
        dispatch(setDateRange(data));
        this.fetchSummaryByClass(fromdate, todate, classid);
    }

    handleSelectChange = event => {
        const { dispatch, fromdate, todate } = this.props;
        const classid = event.target.value;
        dispatch(setClassId(classid));
        this.fetchSummaryByClass(fromdate, todate, classid);

    };

    fetchSummaryByClass = async (fromdate, todate, classid) => {
        const { dispatch } = this.props;
        if (fromdate && todate && classid) {
            const body = { fromdate, todate, classid }
            const { data } = await attendance.summaryByClass(body);
            // this.setState({ tableRow: data, data, search: '', });
            dispatch(fetchAttendanceData(data));
        }
    }

    handleTextSearch = event => {
        const { value } = event.target;
        const { dispatch } = this.props;
        dispatch(setSearchText(value));
    }

    redirectToUserEdit = data => {
        const { childid } = data
        this.props.history.push(routes.USERS_EDIT, { childid, tab: 1 })
    }

    render() {
        const { classes, search, classid, fromdate, todate, classList: _Groups, tableRow } = this.props;
        const { data } = this.state;

        const Present = tableRow.length && tableRow.map(row => row.Present).reduce((a, b) => a + b);
        const Absent = tableRow.length && tableRow.map(row => row.Absent).reduce((a, b) => a + b);
        const Unmarked = tableRow.length && tableRow.map(row => row.Unmarked).reduce((a, b) => a + b);
        const Total = Present + Absent + Unmarked;



        return (
            <Fragment>
                <Paper className={classes.rootContainer}>
                    <Grid container >
                        {fromdate === todate &&
                            <Grid container item xs={12}  >
                                <Grid item xs={12}   >
                                    <Paper className={classes.paper} elevation={1}>
                                        <b>Attendance for : {' '}
                                            {moment(fromdate, dateFormat).format('Do MMM')}
                                        </b>  {' '}
                                        <Grid container item xs={12} direction="row" justify="space-around"  >
                                            <Grid item  >
                                                <StatusCard classes={classes} title="Total" value={Total} color="#7f0ce9" />
                                            </Grid>
                                            <Grid item  >
                                                <StatusCard classes={classes} title="Present" value={Present} color="green" />
                                            </Grid>
                                            <Grid item >
                                                <StatusCard classes={classes} title="Absent" value={Absent} color="red" />
                                            </Grid>
                                            <Grid item >
                                                <StatusCard classes={classes} title="Unmarked" value={Unmarked} color="grey" />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        }

                        <Grid container item xs={12} justify="space-around" alignItems="baseline">
                            <Grid item >
                                <b>
                                    Time Period : {' '}
                                </b>
                                <RangePicker
                                    defaultValue={[moment(fromdate, dateFormat), moment(todate, dateFormat)]}
                                    size='small'
                                    onChange={this.onRangePickerChange}
                                />
                            </Grid>
                            <Grid item>
                                <b>
                                    Group : {' '}
                                </b>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        value={classid || 1}
                                        onChange={this.handleSelectChange}
                                        displayEmpty
                                        name="classid"
                                        className={classes.selectEmpty}>
                                        <MenuItem value={-1}>
                                            All Group
                                        </MenuItem>
                                        {_Groups.map(group => (
                                            <MenuItem
                                                key={group.groupid}
                                                value={group.groupid}
                                            >
                                                {group.GroupName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Search By Name"
                                    type="search"
                                    name="search"
                                    value={search}
                                    onChange={this.handleTextSearch}
                                    className={classes.textField}
                                    margin="normal" />
                            </Grid>
                            <Grid item >
                                <ExcelFile element={
                                    <IconButton aria-label="Download" className={classes.button}>
                                        <SaveAlt />
                                    </IconButton >
                                }
                                >
                                    <ExcelSheet data={data} name="Attendance">
                                        <ExcelColumn label="# of Students" value="childid" />
                                        <ExcelColumn label="firstname" value="firstname" />
                                        <ExcelColumn label="lastname" value="lastname" />
                                        <ExcelColumn label="Absent" value="Absent" />
                                        <ExcelColumn label="Present" value="Present" />
                                        <ExcelColumn label="Unmarked" value="Unmarked" />
                                    </ExcelSheet>

                                </ExcelFile>



                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.tableContainer}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >Child Name</TableCell>
                                            <TableCell >Present</TableCell>
                                            <TableCell >Absent</TableCell>
                                            <TableCell >Unmarked</TableCell>
                                            <TableCell >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <Fragment>
                                            {tableRow.map(row => {
                                                return (
                                                    <TableRow key={row.childid}>
                                                        <TableCell >{row.firstname} {row.lastname}</TableCell>
                                                        <TableCell >{row.Present}</TableCell>
                                                        <TableCell >{row.Absent}</TableCell>
                                                        <TableCell >{row.Unmarked}</TableCell>
                                                        <TableCell >
                                                            <Button
                                                                variant="fab"
                                                                mini
                                                                color="primary"
                                                                aria-label="Edit"
                                                                className={classes.fab}
                                                                onClick={(e) => { this.redirectToUserEdit(row) }}
                                                            >
                                                                <Icon>edit_icon</Icon>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    {!tableRow.length && <span className={classes.emptyRow}>No data</span>}
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Fragment >
        );
    }
}

const mapStateToProps = state => ({
    Groups: state.currentUser.userInfo.Groups,
    ...state.attendance
});

const component = withStyles(styles)(AttendanceSummary)

export default connect(mapStateToProps)(component) 
