import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

const getGroupCache = state => state.currentUser.userInfo.Groups;
const getUserCache = state => state.cache.userCache;

const getEventList = state => state.event.eventList;
const getEventListById = state => state.event.eventById;
const rsvpEventResponse = state => state.event.rsvpEventResponse;
const rsvpFilterValue = state => state.event.rsvpFilterValue;
const searchText = state => state.event.searchText;
const userRSVPEventResponseSelector = state => state.event.userRSVPEventResponse;
const getCurrentEventSelector = state => state.event.currentEventId

const findGroupName = (groupCache) => (group) => {
    const groupName = (groupCache[group] && Object.keys(groupCache[group]).length) ? groupCache[group].GroupName : String(group)
    const profile_photo = (groupCache[group] && Object.keys(groupCache[group]).length) ? groupCache[group].profile_photo : ''
    return {
        groupId: group,
        groupName,
        icon: profile_photo
    }
}

function checkUserStatus(event, currentUser) {
    let result = {
        attend: 'Not Sure',
        children: 0,
        adult: 0
    }
    if (event.event_response.length > 0) {
        const filterUser = event.event_response.find(response => response.user === currentUser)
        if (filterUser) {
            const { user, ...rest } = filterUser
            result = { ...rest }
        }
    }
    return result;
}

function filterByResponse(res, rsvpFilterValue) {
    if (rsvpFilterValue !== 'Sent') {
        if (res.status.attend === rsvpFilterValue) {
            return res
        }
        if (rsvpFilterValue === 'No Reply' && res.status.attend !== 'Yes' && res.status.attend !== 'No' && res.status.attend !== 'Maybe') {
            return res
        }
    } else {
        return res
    }
}

function filterRow(data, text) {
    const searchString = text.toLowerCase()
    if (searchString !== '') {
        const newTableRow = data.filter(row => (
            row.name.toLowerCase().includes(searchString) ||
            row.email.toLowerCase().includes(searchString)
        ))
        return newTableRow;
    } else {
        return data;
    }
}

function convertToMoment(dates) {
    if (Array.isArray(dates)) {
        const result = dates.map(date => ({
            ...date,
            event_date: moment.utc(date.event_date).toDate(),
            slotTime: Array.isArray(date.slotTime) ?
                date.slotTime.map(slot => ({
                    ...slot,
                    start_time: moment(slot.start_time, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                    end_time: moment(slot.end_time, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                    spot: slot.spots || [],
                })) : [],
        }))
        return result
    }
    return []
}

const getGroupWithName = createSelector(
    [getEventList, getGroupCache, getUserCache, userRSVPEventResponseSelector],
    (eventList = [], groupCache = {}, userCache = {}, rsvpEvent = []) => {
        if (
            eventList.length > 0
            //&& rsvpEvent.length > 0
            && Object.keys(groupCache).length
            && Object.keys(userCache).length
        ) {
            const updatedEventList = eventList.map(event => {
                if (event.event_type === 3) {
                    return event.parentTeacherResponse.map(eventSlot => {
                        const startDateObj = {
                            start_date: eventSlot.event_date,
                            end_date: eventSlot.event_date,
                            start_time: eventSlot.slotTime[0].start_time,
                            end_time: eventSlot.slotTime[eventSlot.slotTime.length - 1].end_time
                        }
                        return Object.assign({}, event, startDateObj);
                    })
                }

                if (event.event_type === 4 && event.volunteerDetail) {
                    const { volunteer, daterange, daterangetype, selectedDays, volunteertype } = event && event.volunteerDetail || {};
                    const _daterange = daterange ? { from: moment(daterange.from).toDate(), to: moment(daterange.to).toDate() } : {};

                    return volunteer && volunteer.length && volunteer.map(eventSlot => {
                        const length = eventSlot && eventSlot.slotTime && eventSlot.slotTime.length
                        const startDateObj = {
                            start_date: eventSlot.event_date,
                            end_date: eventSlot.event_date,
                            start_time: eventSlot && eventSlot.slotTime && eventSlot.slotTime[0] && eventSlot.slotTime[0].start_time,
                            end_time: eventSlot && eventSlot.slotTime && eventSlot.slotTime[length - 1] && eventSlot.slotTime[length - 1].end_time
                        }

                        return Object.assign({}, event, startDateObj, { daterange: _daterange }, { daterangetype }, { selectedDays }, { volunteertype });
                    })
                }
                return event;
            });
            return _.flattenDepth(updatedEventList, 1).map(event => {
                const findRsvpEvent = rsvpEvent.length > 0 && rsvpEvent.find(item => item.event_id === event.event_id)
                return {
                    ...event,
                    start_date: event.start_date && moment.utc(event.start_date),
                    end_date: event.end_date && moment.utc(event.end_date),
                    // start_time: event.start_time && moment.utc(event.start_time).format('YYYY-MM-DDTHH:mm:ssZ'),
                    //  end_time: event.end_time && moment.utc(event.end_time).format('YYYY-MM-DDTHH:mm:ssZ'),
                    volunteer: event.volunteerDetail ? convertToMoment(event.volunteerDetail.volunteer) : [],
                    parentTeacherResponse: event.parentTeacherResponse ? convertToMoment(event.parentTeacherResponse) : [],
                    user_response: findRsvpEvent ? findRsvpEvent.user_response : null,
                    groups: event.groups ? event.groups.filter(group => group).map(findGroupName(groupCache)) : [],
                    parents: event.users ? event.users.split(',').map(parent => userCache[parent]) : [],
                    img_url: [{ key: event.img_url, name: '' }],
                    attachments: event.attachments ? event.attachments.split(',').map((attachment, index) => ({
                        key: attachment,
                        name: `attachment${index + 1}`
                    })) : []

                };
            });
        }
        return []

    })

const getGroupWithNameFromEventId = createSelector(
    [getEventListById, getGroupCache, getUserCache],
    (eventList = [], groupCache = {}, userCache = {}) => {
        if (eventList.length > 0 && Object.keys(groupCache).length && Object.keys(userCache).length) {
            return eventList.map(event => {
                const slotTime = event.volunteerDetail ? event.volunteerDetail.volunteer[0].slotTime.map(slot => {
                    const object = { ...slot }
                    object.spot = slot.spots
                    return object
                }) : [];
                return {
                    ...event,
                    start_date: event.start_date && moment.utc(event.start_date),
                    end_date: event.end_date && moment.utc(event.end_date),
                    //  start_time: event.start_time && moment.utc(event.start_time),
                    //  end_time: event.end_time && moment.utc(event.end_time),
                    daterange: (event.volunteerDetail && event.volunteerDetail.daterange) ? { from: moment(event.volunteerDetail.daterange.from).toDate(), to: moment(event.volunteerDetail.daterange.to).toDate() } : {},
                    daterangetype: event.volunteerDetail ? event.volunteerDetail.daterangetype : null,
                    selectedDays: event.volunteerDetail &&  event.volunteerDetail.selectedDays  ?[... event.volunteerDetail.selectedDays] : [],
                    volunteertype: event.volunteerDetail ? event.volunteerDetail.volunteertype : null,
                    volunteer: event.volunteerDetail ? convertToMoment(event.volunteerDetail.volunteer) : [],
                    slotTime: slotTime,
                    parentTeacherResponse: convertToMoment(event.parentTeacherResponse),
                    groups: event.groups ? event.groups.filter(group => group).map(findGroupName(groupCache)) : [],
                    parents: event.users ? event.users.split(',').map(parent => userCache[parent]) : [],
                    img_url: [{ key: event.img_url, name: '' }],
                    attachments: event.attachments ? event.attachments.split(',').map((attachment, index) => ({
                        key: attachment,
                        name: `attachment${index + 1}`
                    })) : []

                };
            });
        }
        return []
    })

const getDataForRsvp = createSelector(
    [rsvpEventResponse, getUserCache, rsvpFilterValue, searchText],
    (event = {}, userCache = {}, rsvpFilterValue, searchText) => {
        if (Object.keys(event).length && Object.keys(userCache).length) {
            const row = event.parents && event.parents.map(parent => {
                let childName = '', display_name = '';

                if (userCache[parent]) {
                    const { parent_of } = userCache[parent]
                    childName = parent_of[Object.keys(parent_of)[0]];
                    display_name = userCache[parent].display_name
                }

                return {
                    id: parent,
                    event_id: event.event_id,
                    name: display_name,
                    childName: childName,
                    email: parent,
                    status: checkUserStatus(event, parent),

                }
            }).filter(res => filterByResponse(res, rsvpFilterValue))
            return filterRow(row, searchText)
        }
        return []

    })

const getCurrentEvent = createSelector(
    [getGroupWithName, getCurrentEventSelector],
    (events = [], currentEventId = null) => {
        if (events.length > 0 && currentEventId) {
            return events.find(event => event.event_id === currentEventId)
        }
        return null
    }
)

export const EventSelector = {
    getGroupWithName,
    getGroupWithNameFromEventId,
    getDataForRsvp,
    getCurrentEvent
};