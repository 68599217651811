import {
    createSelector
} from 'reselect';

const getPosts = state => state.feed.posts;

const getPinnedPosts = state => state.feed.pinned.posts;

const getUserCache = state => state.cache.userCache;

const getPostsWithUser = createSelector(
    [getPosts, getUserCache], (posts = [], userCache) => {
        if (posts.length > 0 && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.comments.map(comment => ({
                        ...comment,
                        user: userCache[comment.user]
                    })),
                    user: userCache[post.user]
                };
            });
        }
        return [];
    }
)

const getPinnedPostsWithUser = createSelector(
    [getPinnedPosts, getUserCache], (posts = [], userCache) => {
        if (posts.length > 0 && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.comments.map(comment => ({
                        ...comment,
                        user: userCache[comment.user]
                    })),
                    user: userCache[post.user]
                };
            });
        }
        return [];
    }
)




export const FeedSelector = {
    getPostsWithUser,
    getPinnedPostsWithUser
};