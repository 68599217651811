import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import red from '@material-ui/core/colors/blue';
import CardHeader from '@material-ui/core/CardHeader';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { formValueSelector, change, unregisterField, reduxForm, Form } from 'redux-form';

import RsvpSettings from './RsvpSetting';
import NotificationAlert from './NotificationAlertForm';
import GroupParent from './GroupParentsForm';
import GeneralDetails from './GeneralDetailsForm';

import { setInitialValue } from '../../../redux/actions/event';
import { GroupSelector } from '../../../redux/selector';
import { EVENT_TYPES } from '../../../shared/enums';
import { validate } from '../../common/reduxForm/validation/generalevent';
import { colors } from '../../../shared/consts';
import { showToastMessage } from '../../../redux/actions/component';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        width: 50 * theme.spacing(1),
    },
    rickTextEditor: {
        height: 50 * theme.spacing(1),
        overflow: 'auto'
    },
    avatar: {
        backgroundColor: red[500],
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
    },
});

class GeneralDetailCardComponent extends Component {

    state = {
        open: false
    }

    handleNextStep = async () => {
        const {
            generalDetailsubmitForm,
            setStepperActiveStep,
            nextStep
        } = this.props;
        await generalDetailsubmitForm();
        setStepperActiveStep(nextStep);
    }

    onFormSubmit = data => {

        const submitData = () => {
            const randomColor = colors[Math.floor(Math.random() * colors.length)];
            data.event_color = randomColor;
            this.props.setInitialValue(data);
            this.handleNextStep();
        }

        if (data.groupParentSelection === "parents") {
            if ((data.parents && data.parents.length === 0) || !data.parents) {
                this.props.dispatch(showToastMessage({ message: 'Select One parent At least', type: 'error' }))
            } else {
                submitData();
            }
        } else if (data.groupParentSelection === "group") {
            if ((data.groups && data.groups.length === 0 || !data.groups)) {
                this.props.dispatch(showToastMessage({ message: 'Select One Group At least', type: 'error' }))
            } else {
                submitData();
            }
        } else {
            submitData();
        }

       

    }

    render() {
        const {
            classes,
            setInitialValue,
            groups = [],
            handleSubmit,
            valid: isValidForm,
            initialValues,
            setStepperActiveStep,
            error
        } = this.props;

        const isRsvp = String(initialValues.event_type) === EVENT_TYPES.RSVP_Event
        const isMeeting = String(initialValues.event_type) === EVENT_TYPES.Parent_Teacher_Event
        const isVolunteer = String(initialValues.event_type) === EVENT_TYPES.Volunteer_Event

        return (
            <div className={classes.root}>
                <Form onSubmit={handleSubmit(this.onFormSubmit)}>
                    <Grid container>
                        <Grid item xs={12} className={classes.control}>
                            <Grid container spacing={5}>
                                <Grid item xs={7}>
                                    <Card className="cardComponent">
                                        <CardHeader
                                            title="General Details" className="cardtitleText" />
                                        <CardContent>
                                            <GeneralDetails
                                                isMeeting={isMeeting}
                                                isVolunteer={isVolunteer}
                                                setInitialValue={setInitialValue}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Card className="cardComponent">
                                                <CardHeader
                                                    title="Add groups / parents" className="cardtitleText" />
                                                <CardContent>
                                                    <GroupParent
                                                        groups={groups}
                                                        changeGroupList={this.props.changeGroupList}
                                                        generalFormValues={this.props.generalFormValues}
                                                        removeField={this.props.removeField}
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Card className="cardComponent">
                                                <CardHeader
                                                    title="Set Event Reminders" className="cardtitleText" />
                                                <CardContent>
                                                    <NotificationAlert />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        {isRsvp &&
                                            (<Grid item xs={12}>
                                                <Card className="cardComponent">
                                                    <CardHeader
                                                        title="RSVP Settings" className="cardtitleText" />
                                                    <CardContent>
                                                        <RsvpSettings />
                                                    </CardContent>
                                                </Card>
                                            </Grid>)
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}
                        ContentProps={{ 'aria-describedby': 'message-id' }}
                        message="All fields are required, Please provide details"
                    />
                    <div className={classes.actionButton}>
                        <Button variant="contained" disabled={true}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!isValidForm}
                        >
                            Next
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

const selector = formValueSelector('generalDetailsForm')
const mapStateToProps = state => {
    return {
        groups: GroupSelector.getGroupInfoWithParents(state),
        generalFormValues: selector(state, 'groupParentSelection', 'parents', 'groups'),
        initialValues: state.event.formInitialValue
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setInitialValue: (payload) => dispatch(setInitialValue(payload)),
        changeGroupList: group => dispatch(change('generalDetailsForm', 'groups', group)),
        removeField: field => dispatch(unregisterField("generalDetailsForm", field))
    }
};

GeneralDetailCardComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const generalDetailCardComponent = reduxForm({
    form: "generalDetailsForm",
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(GeneralDetailCardComponent);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(generalDetailCardComponent));
