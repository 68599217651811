import {
    Auth
} from 'aws-amplify';

// AWS Mobile Hub Project Constants
const setHeader = async () => {
    const token = (await Auth.currentSession()).idToken.jwtToken;
    return {
        'Authorization': token,
        'content-type': 'application/json'
    }
};

const API = {
    'graphql_endpoint': 'https://3tfntf2atnfszabdcv3wsjjoa4.appsync-api.us-east-1.amazonaws.com/graphql',
    'graphql_headers': async () => {
        const token = (await Auth.currentSession()).idToken.jwtToken;
        return ({
            'Authorization': token
        })
    },
    // 'graphql_endpoint_iam_region': 'my_graphql_apigateway_region',
    'endpoints': [{
        'name': "reset_password",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/resetpasswordflow",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_user_info",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/me",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_child_of_user",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/user/me/children",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_students",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/child",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "add_students",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/child/add",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "edit_students",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/child/update",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "delete_student",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/child/delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "search_user",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/search",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "bulupload",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/bulkupload",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "bulupload_status",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/bulkupload/status",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_users",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "add_users",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/add",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "add_users_db",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/adddb",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "edit_users",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/update",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "delete_users",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "delete_users_db",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/deletedb",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_groups",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/groups",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "add_group",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/groups/add",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "delete_group",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/groups/delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "update_group",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/groups/update",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_users_count",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/dashboard/usercount",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_students_count",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/dashboard/studentcount",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_lastlogin",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/lastlogin",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_user_detail",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/details",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_user_child_spouse",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/users/students",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "add_groups_students_add",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/groups/students/add",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "child_group_delete",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/child/group-delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_child_by_id",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/child/id",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_loggedin_user_child",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/user/me/children",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "unmarked_attendance",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/attendance/today/unmarked",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "summary_attendance",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/attendance/today/summary",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "summarybyclass",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/attendance/summarybyclass",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "child_attendance",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/child/attendance",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "mark_attendance",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/attendance/markattendance",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "user_cache",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/config/usercache",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_posts",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "add_posts",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/add",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "edit_posts",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/edit",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "delete_posts",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "add_comments",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/comments/add",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "edit_comments",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/comments/edit",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "delete_comments",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/comments/delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "post_like",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/like",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "post_unlike",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/unlike",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "post_view",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/posts/view",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_staff",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/group/staff",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_student",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/group/children",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "create_event",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/create",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "edit_event",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/edit",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "delete_event",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_event",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/get",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "set_rsvp_event_response",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/event-response",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "update_rsvp_event_response",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/update-event-response",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get-event-response",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/get-event-response",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get-user-response",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/get-user-response",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "change-owner",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/change-owner",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "spot-delete",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/spot/delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "event-child-delete",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/child/delete",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "getchildrenforgroups",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/group/getchildrenforgroups",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "getChildrenForTeacherGroups",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/group/getchildrenforteachergroups",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "get_photos",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/photos",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "send_invitation",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/sendinvitation",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
    {
        'name': "send_invitation_mark",
        'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/admin/sendinvitation/mark",
        'service': "lambda",
        'region': "us-east-1",
        'custom_header': setHeader
    },
     {
            'name': "get-users",
            'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/events/get-users",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "send-email",
            'endpoint': "https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/prod/email/generic",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
    ]
};

export default API;
