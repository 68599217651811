import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles"
import { DialogContent, Avatar } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(1),
        color: 'darkblue',
        cursor: "pointer"
    },
    selectedChip: {
        margin: theme.spacing(1),
        color: 'red',
        cursor: "pointer"
    },
    avatar: {
        margin: 10,
        backgroundColor: blue[100],
        color: blue[600],
    },
    dialogContent: {
        padding: 8
    }
}));


function SignupDialog(props) {
    const classes = useStyles();
    const { open, onClose, data } = props;

    function handleClose() {
        onClose();
    }

    function handleListItemClick(value) {
        onClose(value);
    }


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth={true} aria-labelledby="simple-dialog-title">
            <DialogTitle id="simple-dialog-title">Select Children</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <List>
                    {data.map((item, index) => (
                        <ListItem key={index} button onClick={() => handleListItemClick(item)}>
                            <ListItemAvatar>
                                <Avatar
                                    className={classes.avatar}
                                    src={item.pictureurl === "<DEFAULT>" ? '' : item.pictureurl}
                                >
                                    {item.firstname[0]}{item.lastname[0]}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${item.firstname}${item.lastname}`} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>

        </Dialog>
    );
}

SignupDialog.propTypes = {
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
};

export default SignupDialog
