import React from 'react';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import ProgressBarWithNumber from "../../common/ProgressBarWithNumber";
import ParentImg from "../../../assets/images/21.png";
import GenericImg from "../../../assets/images/20.png";
import VolunteerImg from "../../../assets/images/22.png";
import RsvpImg from "../../../assets/images/23.png";


function Invitation(props) {
    const { data, loginUser, userRole } = props
    const { eventname, start_date, user_response, userResponse, event_onwer, event_id, event_type, percentage, total_slots } = data;
    const isEventOwner = loginUser === event_onwer
    const avtarstyle = { width: "25px", height: "25px" }
    const response = event_type === 4 ? userResponse : user_response
    return (
        <div>
            { event_type === 1 ? null :
                <div className="invitation" onClick={() => {
                    props.onClickOnInviation(event_id)
                }}>
                    <Typography color="primary" variant="h6">
                        <span className="invitationTitle">{eventname}</span>
                        {event_type === 2 && (
                            <span className="titleIntend">
                        <Moment utc format="ddd D, MMMM YYYY">
                            {start_date}
                        </Moment>
                    </span>
                        )}

                        {response && response.length > 0 && event_type !== 2 && (
                            <span className="titleIntend">
                        <Moment utc format="ddd D, MMMM YYYY">
                            {`${response[0].event_date}`}
                        </Moment>
                    </span>
                        )}

                        <span style={{float: "right"}}>
                    {event_type === 4 ?
                        <img alt="group" src={VolunteerImg} style={avtarstyle} title="Volunteer Event"/> :
                        event_type === 3 ?
                            <img alt="group" src={ParentImg} style={avtarstyle} title="Parent-Teacher Event"/> :
                            event_type === 2 ?
                                <img alt="group" src={RsvpImg} style={avtarstyle} title="Rsvp Event"/> :
                                event_type === 1 ?
                                    <img alt="group" src={GenericImg} style={avtarstyle} title="Generic Event"/> :
                                    null

                    }
                </span>

                    </Typography>

                    {/* <Typography color="primary" variant="h6" className="invitationSubtitle">
                Our yearly fair to bring out community togethers....
            </Typography> */}

                    {(response && event_type === 2) && (
                        <Typography color="primary" variant="h6">
                            <span className="response">Your response:</span>
                            <span className="responseAns">
                        {`${response.attend}( ${response.adult} adults ${response.children} kids)`}
                    </span>
                        </Typography>
                    )}

                    {(response && response.length > 0 && event_type !== 2) && (
                        <span>
                    {(userRole === "admin" || userRole === "teacher") && (data && (data.event_type === 3 || data.event_type === 4)) ?
                        <span
                            className="responseAns">{`${response && response.length} out of ${total_slots} filled`}</span> :
                        <Typography color="primary" variant="h6">
                            <span className="response">Your response:</span>
                            <span className="responseAns">
                        {`${response[0].firstname} ${response[0].lastname} conference spot is on `}
                                <Moment utc format="ddd D, MMMM YYYY">
                                {`${response[0].event_date}`}
                            </Moment> at &nbsp;
                                <Moment format="hh:mm a" date={response[0].start_time}/> - <Moment format="hh:mm a"
                                                                                                   date={response[0].end_time}/>
                    </span>
                        </Typography>
                    }
                </span>
                    )}

                    {(response && response.length === 0 || !response) && (
                        <span>
                    {(userRole === "admin" || userRole === "teacher") && (data && (data.event_type === 3 || data.event_type === 4)) ?
                        <span
                            className="responseAns">{`${response && response.length} out of ${total_slots} filled`}</span> :
                        <Typography color="primary" variant="h6">
                            <span className="response">Your response:</span>
                            <span className="responsePending"> Pending</span>
                        </Typography>
                    }
                </span>
                    )}

                    {isEventOwner && (
                        <ProgressBarWithNumber percentage={percentage}/>
                    )}
                </div>
            }
        </div>
    );
}

export default Invitation;