import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Field } from "redux-form";
import renderCheckbox from "../../common/reduxForm/RenderCheckbox";
import RenderSwitch from "../../common/reduxForm/RenderSwitch";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(1) * 2,
    },
    textField: {
        width: '100%',
    },
    chip: {
        margin: theme.spacing(1) / 2,
    },
    parentOpenLink: {
        cursor: 'pointer'
    },
});

class NotificationAlertForm extends Component {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} className={classes.control}>
                        <Grid container spacing={5}>

                            <Grid item xs={12}>
                                <Field
                                    name="notifyOneDayAgo"
                                    label='One day ago'
                                    component={RenderSwitch}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="notifyTwoDayAgo"
                                    label='Two day ago'
                                    component={RenderSwitch}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="notifyThreeDayAgo"
                                    label='Three day ago'
                                    component={RenderSwitch}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="notifyParent"
                                    label='Notify Parents once the event is created'
                                    component={renderCheckbox}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(NotificationAlertForm);;
