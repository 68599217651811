import {
    FETCH_POST,
    SAVE_COMMENT,
    UPDATE_COMMENT,
    DELETE_COMMENT,
    DELETE_POST,
    LIKE_POST,
    UNLIKE_POST,
    APPLY_POST_FILTER,
    FETCH_PINNED_POST,
    POST_VIEW,
    CREATE_POST
} from "../actions/feed-post";
import {
    REQUEST,
    SUCCESS,
    FAILURE
} from "../actions/common";

const INITIAL_STATE = {
    posts: [],
    query: {
        itemsperpage: 10,
        page: 0,
        hasMoreItems: true,
        groups: []
    },
    error: null,
    isLoading: false,
    pinned: {
        posts: [],
        query: {
            itemsperpage: 10,
            page: 0,
            hasMoreItems: true,
            groups: []
        },
        error: null,
        isLoading: false,
    },
}

const feedPost = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_POST[REQUEST]:
            return { ...state,
                isLoading: true }
        case CREATE_POST[SUCCESS]:
            return { ...state,
                isLoading: false }
        case APPLY_POST_FILTER:
            return {
                ...state,
                posts: [],
                query: {
                    ...state.query,
                    groups: action.payload
                }
            }
        case FETCH_POST[REQUEST]:
            return {
                ...state,
                query: {
                    ...action.query,
                    hasMoreItems: false
                },
                posts: action.query.page > 0 ? [...state.posts] : [],
            }
        case FETCH_POST[SUCCESS]:
            return {
                ...state,
                query: {
                    ...state.query,
                    hasMoreItems: Object.values(action.payload.posts).length ? true : false,
                    itemsperpage: action.payload.ipg,
                    page: action.payload.pg
                },
                posts: [...state.posts, ...Object.values(action.payload.posts).reverse()],
            }
        case FETCH_POST[FAILURE]:
            return {
                ...state,
                error: action.error
            }

        case DELETE_POST[SUCCESS]:
            return {
                ...state,
                // posts: state.posts.filter(post => post.postid !== action.payload.postid)
            }
        case LIKE_POST[SUCCESS]:
            return {
                ...state,
                posts: state.posts.map(post => {
                    if (post.postid === action.payload.postid) {
                        post.likes.selflike = true;
                        post.likes.count = post.likes.count + 1
                        post.likes.users = [...post.likes.users, action.payload.user]
                    }
                    return post
                })
            }
        case UNLIKE_POST[SUCCESS]:
            return {
                ...state,
                posts: state.posts.map(post => {
                    if (post.postid === action.payload.postid) {
                        post.likes.selflike = false;
                        post.likes.count = post.likes.count - 1;
                        post.likes.users = post.likes.users.filter(user => user !== action.payload.user)
                    }
                    return post
                })
            }
        case SAVE_COMMENT[REQUEST]:
        case UPDATE_COMMENT[REQUEST]:
        case DELETE_COMMENT[REQUEST]:
        case POST_VIEW[REQUEST]:
            return {
                ...state
            }
        case SAVE_COMMENT[SUCCESS]:
        case UPDATE_COMMENT[SUCCESS]:
        case DELETE_COMMENT[SUCCESS]:
        case POST_VIEW[SUCCESS]:
            return {
                ...state,
                posts: [...state.posts]
            }
        case SAVE_COMMENT[FAILURE]:
        case UPDATE_COMMENT[FAILURE]:
        case DELETE_COMMENT[FAILURE]:
            return {
                ...state
            }

        case FETCH_PINNED_POST[REQUEST]:
            return {
                ...state,
                pinned: {
                    ...state.pinned,
                    query: {
                        ...action.query,
                        hasMoreItems: false
                    },
                    posts: action.query.page > 0 ? [...state.pinned.posts] : []
                }
            }
        case FETCH_PINNED_POST[SUCCESS]:
            return {
                ...state,
                pinned: {
                    ...state.pinned,
                    query: {
                        ...state.pinned.query,
                        hasMoreItems: Object.values(action.payload.posts).length ? true : false,
                        itemsperpage: action.payload.ipg,
                        page: action.payload.pg
                    },
                    posts: [...state.pinned.posts, ...Object.values(action.payload.posts).reverse()],
                    error: null
                }
            }

        case FETCH_PINNED_POST[FAILURE]:
            return {
                ...state,
                pinned: {
                    ...state.pinned,
                    error: action.error
                }
            }
        default:
            return state
    }
}

export default feedPost;