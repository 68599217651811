import {
    GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE,
    FETCH_AUTH_DATA_FAILURE, FETCH_AUTH_DATA_SUCCESS
} from "../actions/current-user";

const INITIAL_STATE = {
    auth: {},
    userInfo: {},
}

const currentUser = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.data
            }
        case FETCH_AUTH_DATA_SUCCESS:
            return {
                ...state,
                auth: action.data
            }
        case FETCH_AUTH_DATA_FAILURE:
        case GET_USER_INFO_FAILURE:
            return state
        default:
            return state
    }
}

export default currentUser