import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Slide from '@material-ui/core/Slide';
import Slider from "react-slick";
import Prev from "../../assets/images/righta.PNG"
import Left from "../../assets/images/lefta.PNG"
import FeedCard from './FeedCard';
import { toDataURL } from '../../utils/utils';

const styles = theme => ({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        margin: theme.spacing(1) * 0.10,
    },
    positionRelative: {
        position: 'relative',
    },
    moreText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        color: '#FDFF32',
        cursor: 'pointer',
        width: '100%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontSize: '18px'
    },
    dialogDivContent: {
        backgroundColor: '#0000009c',
    }

});


const Transition = React.forwardRef((props, ref) => (
    <Slide {...props} direction="up" ref={ref} />
))

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img className={className}
             onClick={onClick}
             src={Prev}
             style={{...style, borderRadius: 50, left: 5, height: 35, border: "solid 2px black", width: 35, zIndex: 2}}
             alt="arrow_left"/>
    );
}

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img className={className}
             onClick={onClick}
             src={Left}
             style={{...style, borderRadius: 50, right: 5, height: 35, border: "solid 2px black", width: 35, zIndex: 2}}
             alt="arrow_left"/>
    );
}

function CardMediaComponent(props) {
    const { image, classes, onClick } = props;
    return (
        <CardMedia
            style={{ margin: '5px' }}
            className={classes.media}
            image={image}
            title={image}
            onClick={() => { onClick(image) }}
        />
    )
}

class ImagePreview extends Component {
    state = {
        openPreviewModel: false,
        slideIndex: 0,
        updateCount: 0
    };

    openPreviewImageModel = (index = 0) => {
        const { data, postViewAction } = this.props;
        if (postViewAction) {
            postViewAction.request(data);
        }

        this.setState({ openPreviewModel: true });
        setTimeout(() => {
            this.slider.slickGoTo(index);
        }, 0);

    };

    closePreviewImageModel = () => {
        this.setState({ openPreviewModel: false });
    };

    downLoadImage = (index) => {
        const dataUrl =
            this.props.Images[index]
        // 'https://www.tutorialspoint.com/videotutorials/images/tutor_connect_home.jpg'
        const dataUrlArray = dataUrl.split('/').reverse();
        const filename = dataUrlArray[0];

        toDataURL(dataUrl, (dataUrl) => {
            const a = document.createElement("a");
            a.href = dataUrl;
            a.setAttribute("download", filename);
            const b = document.createEvent("MouseEvents");
            b.initEvent("click", false, true);
            a.dispatchEvent(b);
            return false;
        })
    }

    handleKeyPress = (e) => {
        if(e.keyCode === 39){
            this.slider.slickNext()
        }else if(e.keyCode === 37){
            this.slider.slickPrev()
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    render() {
        const { classes, Images } = this.props;
        const ImagesSize = Images.length;

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            // adaptiveHeight: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            afterChange: () =>
                this.setState(state => ({ updateCount: state.updateCount + 1 })),
            beforeChange: (current, next) => this.setState({ slideIndex: next })
        };

        return (
            <Fragment>
                <Grid container spacing={0}>
                    {!!ImagesSize && Images.map((image, index) => (
                        <Fragment key={index}>

                            {((ImagesSize === 3 && index === 2) ||
                                (ImagesSize === 1)) && (
                                    <Grid item xs={12}>
                                        <CardMediaComponent
                                            image={image}
                                            classes={classes}
                                            onClick={(e) => { this.openPreviewImageModel(index) }} />
                                    </Grid>
                                )}

                            {!(ImagesSize === 3 && index === 2) &&
                                (ImagesSize !== 1 && index < 3) && (
                                    <Grid item xs={6}>
                                        <CardMediaComponent
                                            image={image}
                                            classes={classes}
                                            onClick={(e) => { this.openPreviewImageModel(index) }} />
                                    </Grid>
                                )}

                            {(index === 3) && (
                                <Grid item xs={6} className={classes.positionRelative}>
                                    <CardMediaComponent
                                        image={image}
                                        classes={classes}
                                        onClick={(e) => { this.openPreviewImageModel(index) }} />
                                    <Grid>
                                        <Typography
                                            onClick={(e) => { this.openPreviewImageModel(index) }}
                                            variant="h6"
                                            className={classes.moreText}>
                                            {ImagesSize - (index + 1) ? <span>View All {ImagesSize} Images </span> : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Fragment>
                    ))}
                </Grid>

                <Dialog
                    fullScreen
                    className={classes.dialogDivContent}
                    PaperComponent="div"
                    open={this.state.openPreviewModel}
                    onClose={this.closePreviewImageModel}
                    TransitionComponent={Transition}
                >
                    <DialogContent style={{ overflowX: 'hidden' }}>
                        <Grid container justify="space-between">
                            <IconButton
                                style={{ color: '#ffffff' }}
                                color="primary"
                                aria-label="Save"
                                onClick={() => { this.downLoadImage(this.state.slideIndex) }}
                            >
                                <SaveIcon />
                            </IconButton>
                            <IconButton
                                style={{ color: '#ffffff' }}
                                color="primary"
                                onClick={this.closePreviewImageModel}
                                aria-label="Close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>

                        <Grid container spacing={1} >
                            <Grid item xs={8}>
                                <Slider ref={slider => (this.slider = slider)} {...settings}>
                                    {!!ImagesSize && Images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={image}
                                        />
                                    ))}
                                </Slider>
                            </Grid>

                            <Grid item xs={4}>
                                <FeedCard data={this.props.data} />
                            </Grid>
                        </Grid>

                    </DialogContent>
                </Dialog>
            </Fragment >
        )
    }
}

export default withStyles(styles)(ImagePreview);
