import React, { Component } from "react";
import { Storage } from "aws-amplify";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import renderHTML from 'react-render-html';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Popover from '@material-ui/core/Popover';
import Moment from 'react-moment';
import classnames from "classnames";
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import CalImg from "../../../assets/images/calendar-2.png";
import AlarmImg from "../../../assets/images/alarm-clock.png";
import LocationImg from "../../../assets/images/location.png";
import NotificationImg from "../../../assets/images/notification.png";
import GroupImg from "../../../assets/images/group.png";

import { toDataURL } from "../../../utils/utils";
import { EVENT_TYPES } from "../../../shared/enums";
import RsvpResponse from "../RsvpAttend";
import AsyncImage from "../../common/reduxForm/AsyncImage";
import PreviewMeeting from "../../Event/MeetingDateTime/PreviewMeeting";
import PreviewVolunteer from "../../Event/Volunteer/PreviewVolunteer";
import studentService from "../../../service/student";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    // height: 400,
    // width: '100%',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px'
  },
  centerContent: {
    width: "100%",
    margin: "20px auto",
  },
  title: {
    fontSize: '40px',
    color: '#3f51b5',
  },
  location: {
    alignItems: 'center',
    marginTop: '25px'
  },
  alignment: {
    marginBottom: '20px',
  },
  clockImg: {
    height: '30px',
    width: '30px',
    float: 'left',
    margin: '5px'
  },
  description: {
    border: '1px solid #e2e2e2',
    borderRadius: '5px',
    padding: '8px',
    marginBottom: '20px'
  },
  imageContent: {
    margin: '8px',
    height: "20px",
    position: "relative",
    float: 'left'
  }
});

const other = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  }
}

class PreviewComponent extends Component {
  state = {
    anchorEl: null,
    open: false,
    openReminder: false,
    attachment: [],
    loggedinChildList: []
  };

  componentDidMount() {
    this.getLoggedinStudentList();
  }

  async getLoggedinStudentList() {
    try {
      const result = await studentService.getChildOfUser();
      this.setState({ loggedinChildList: result.data })
    } catch (error) {
      console.log('error', error)
    }
  }

  handleClickButton = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  };

  handleReminder = (event) => {
    this.setState({
      openReminder: true,
      anchorEl: event.currentTarget
    });
  };

  handleDownload = async (file) => {
    const dataUrl = await Storage.get(file.key, { level: "public" });

    toDataURL(dataUrl, (dataUrl) => {
      const a = document.createElement("a");
      a.href = dataUrl;
      a.setAttribute("download", file.name);
      const b = document.createEvent("MouseEvents");
      b.initEvent("click", false, true);
      a.dispatchEvent(b);
      return false;
    })

  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleReminderClose = () => {
    this.setState({
      openReminder: false,
    });
  };

  handleNextStep = () => {
    const {
      history,
      addEvent,
    } = this.props;
    addEvent.request({ history });
  };

  handlePreviousStep = () => {
    const {
      setStepperActiveStep,
      previousStep
    } = this.props;
    setStepperActiveStep(previousStep);
  };

  render() {
    const { classes, event } = this.props;
    const {
      open,
      anchorEl,
    } = this.state;

    const remindersArray = [
      { name: 'notifyOneDayAgo', status: event.notifyOneDayAgo },
      { name: 'notifyTwoDayAgo', status: event.notifyTwoDayAgo },
      { name: 'notifyThreeDayAgo', status: event.notifyThreeDayAgo },
    ]

    const reminders = remindersArray.filter(notify => notify.status === 1 || notify.status === true)
    const isRsvp = String(event.event_type) === EVENT_TYPES.RSVP_Event
    const isMeeting = String(event.event_type) === EVENT_TYPES.Parent_Teacher_Event
    const isVolunteer = String(event.event_type) === EVENT_TYPES.Volunteer_Event

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.control}>
            <Grid container spacing={5}>
              <Grid item xs={8} className="centerdiv" style={{ textAlign: 'inherit' }}>
                <Card style={{ display: 'flex' }} className="cardComponent" >
                  <AsyncImage style={{ width: '50%', height: 'fit-content' }} className={classes.cover} src={event.img_url[0].key} />
                  <Grid container justify="center" style={{ width: '50%', alignItems: 'center' }}>
                    <span className={classes.title}> {event.eventname} </span>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={10} className="centerBottomDiv">
                <Card className="cardComponent">
                  <div className={classes.details}>
                    <CardContent
                      className={classnames(classes.content)}
                    >
                      <Grid item style={{ marginTop: '195px' }}>

                        {!isMeeting && event.groups && !!event.groups.length && (
                          <Popover
                            id="render-props-popover"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={this.handleClose}
                            {...other}
                            className="groupPopper"
                          >
                            <Typography
                              color="primary"
                              variant="h6"
                              className="eventDialogSubTitle"
                            >
                              <p>
                                {event.groups && event.groups.map((group, key) => (
                                  <span
                                    className="groupChip"
                                    key={group.groupId}
                                  >
                                    {group.groupName}
                                  </span>
                                ))}
                              </p>
                            </Typography>
                          </Popover>
                        )}

                        {!isMeeting &&
                          <Grid container justify="space-between" className={classes.alignment} >
                            <div>
                              <img
                                className={classes.clockImg}
                                src={CalImg}
                                alt="previcon"
                              />
                              { event && event.event_type === "4" && event.volunteertype === "1" ?
                                <span style={{float: 'right', marginTop: "10px"}}>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event && event.volunteer && event.volunteer[0] && event.volunteer[0].event_date}
                                  </Moment>
                                </span> :
                                  event && event.event_type === "4" && (event.volunteertype === "2" || event.volunteertype === "3") ?
                                <span style={{float: 'right', marginTop: "10px"}}>Multiple Days</span> :
                                  event && event.event_type === "4" && event.volunteertype === "4" ?
                                <span style={{float: 'right', marginTop: "10px"}}>No Specific day</span> :
                                  event && (event.event_type === "1" || event.event_type === "2") ?
                                <span style={{float: 'right'}}>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event && event.start_date}
                                  </Moment>
                                  <br/>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event && event.end_date}
                                  </Moment>
                                </span> :
                                <span style={{float: 'right'}}>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event.start_date}
                                  </Moment>
                                  <br/>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event.end_date}
                                  </Moment>
                                </span>
                              }
                            </div>

                            <div>
                              <img
                                alt="previcon"
                                src={AlarmImg}
                                className={classes.clockImg}
                              />
                              <span style={{ float: 'right' }}>
                                <Moment format="h:mm a">
                                  {event.start_time}
                                </Moment>
                                <br />
                                <Moment format="h:mm a">
                                  {event.end_time}
                                </Moment>
                              </span>
                            </div>

                            <div className="eventDialogcontent centerContent rsvp-around box">
                              <div className="prev-section-one">
                                <img
                                  alt="previcon"
                                  src={GroupImg}
                                  className={classes.imageContent}
                                  style={{ cursor:"pointer" }}
                                  onClick={this.handleClickButton}
                                />
                                <p className="locationSection">
                                  {event.groups && event.groups.length} Groups
                              </p>
                              </div>
                            </div>

                            <div className="prev-section-one">
                              <img
                                className={classes.imageContent}
                                src={LocationImg}
                                alt="previcon"
                              />
                              <p className="locationSection">
                                {event.location}
                              </p>
                            </div>

                            <div>
                              <img
                                alt="previcon"
                                src={NotificationImg}
                                className={classes.imageContent}
                                onClick={this.handleReminder}
                              />
                              <p className="locationSection">
                                {reminders.length} Reminders
                            </p>
                            </div>
                          </Grid>}

                        {event.eventdesc && <div className={classes.description}> {renderHTML(event.eventdesc)} </div>}

                        {isRsvp &&
                          <RsvpResponse
                            readOnly={true}
                            event={event}
                          />}


                        {(event.attachments && !!event.attachments.length) && (
                          <div className="eventDialogcontent eventContainer">
                            <strong className="note">Attachments</strong>
                            <br />
                            <div className="customRow">
                              {event.attachments.map((attachment, key) => (
                                <Chip
                                  key={key}
                                  label={attachment.name}
                                  color="primary"
                                  variant="outlined"
                                  onClick={e => { this.handleDownload(attachment) }}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </Grid>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isMeeting && (
          <PreviewMeeting
            event={event}
            readOnly={true}
            data={this.state.loggedinChildList}
          />
        )}

        {isVolunteer && <PreviewVolunteer event={event} readOnly={true} />}

        <div className={classes.actionButton}>
          <Button variant="contained" onClick={this.handlePreviousStep}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNextStep}
          >
            Done
          </Button>
        </div>
      </div>
    );
  }
}


PreviewComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(PreviewComponent));
