import React from 'react';
import { Route } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { setClassList } from '../redux/actions/attendance';
import LinearProgress from '@material-ui/core/LinearProgress';
import Photos from '../container/photos'
import * as  routes from '../routes/path';
import AdminRoutes from '../routes/AdminRoutes';
import ParentRoutes from '../routes/ParentRoutes'
import { userInfoSuccess, fetchAuthUserSuccess } from '../redux/actions/current-user';
import CustomSneakbars from '../component/common/CustomizedSnackbars';

import authService from '../service/auth';
import OtherRoutes from '../routes/OtherRoutes';
import { fetchUserCatch } from '../redux/actions/cache';
import logo from '../assets/images/logo.png';
import userAvatar from '../assets/images/user-avtar.png';

const ITEM_HEIGHT = 48;
const drawerWidth = 240;
const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
];

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: '#000',
        backgroundColor: '#fff',
        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.16)'
    },
    header: {
        flexGrow: 1,
        font: ' 15px/28px "Lato", sans-serif',
    },
    name:{
        font: '700 15px/28px "Lato", sans-serif',
        textTransform: 'capitalize',
    },
    rightContainer: {
        marginRight: 12,
    },
    smallAvatar: {
        width: 30,
        height: 30,
    },
    logoButton: {
        width:'50px',
        marginLeft: 16,
        marginRight: 36,
        cursor: 'pointer',
        transform: 'rotate(2deg)'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(1) * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(1) * 9 + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
    },
    newEvent: {
        flex: 5
    }
});

const INITIAL_STATE = {
    openDrawer: true,
    anchorElProfileMenu: null,
    anchorElNotificationMenu: null,
    open: false
}

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    async componentWillMount() {
        const { dispatch } = this.props;
        const signInUserSession = JSON.parse(localStorage.getItem('hmUser'));
        if (signInUserSession) {
            dispatch(fetchAuthUserSuccess(signInUserSession));

            const userResponse = await authService.getUserDetail();
            if (userResponse.role !== 'parent'){
                dispatch(fetchUserCatch.request());
            }
           
            if (userResponse.Username) {
                const classList = this.convertGroupsObjectToArray(userResponse.Groups);
                // dispatch(fetchUserCacheRequest());
                dispatch(setClassList(classList));
                dispatch(userInfoSuccess(userResponse));
            }
        }
    }

    convertGroupsObjectToArray(Groups = {}) {
        const classList = [];
        for (const key in Groups) {
            if (Groups.hasOwnProperty(key)) {
                const element = Groups[key];
                classList.push(element)
            }
        }
        return classList;
    }

    // handleDrawer = () => {
    //     this.setState((prevState) => ({ openDrawer: !prevState.openDrawer }));
    // };

    handleProfileMenu = event => {
        this.setState({ anchorElProfileMenu: event.currentTarget });
    };

    handleProfileClose = () => {
        this.setState({ anchorElProfileMenu: null });
    };

    handleNotificationMenu = event => {
        this.setState({ anchorElNotificationMenu: event.currentTarget });
    };

    handleNotificationClose = () => {
        this.setState({ anchorElNotificationMenu: null });
    };

    onClickLogout = (event) => {
        const { dispatch } = this.props;
        dispatch({type : 'LOGOUT_USER'})
        localStorage.removeItem('hmUser');
        this.props.history.push('/signin');
    }

    mainMenuListItemsParent = (
        <div>
             <Link className="removeclass" to={routes.Photos}>
                <ListItem button>
                    <ListItemIcon>
                        <i className="material-icons"> photos </i>
                    </ListItemIcon>
                    <ListItemText primary="Gallery" />
                </ListItem>
            </Link>
        </div>
    );

    mainMenuListItems = (
        <div>
            <Link className="removeclass" to={routes.WELCOME}>
                <ListItem button>
                    <ListItemIcon>
                        <i className="material-icons"> subject </i>
                    </ListItemIcon>
                    <ListItemText primary="Post Feed" />
                </ListItem>
            </Link>

            <Link className="removeclass" to={routes.NEWSLETTER}>
                <ListItem button>
                    <ListItemIcon>
                        <i className="material-icons"> poll </i>
                    </ListItemIcon>
                    <ListItemText primary="News Letter" />
                </ListItem>
            </Link>

            {/* <Link className="removeclass" to={routes.PORTFOLIO}>
                <ListItem button>
                    <ListItemIcon>
                        <i className="material-icons"> work </i>
                    </ListItemIcon>
                    <ListItemText primary="Portfolio" />
                </ListItem>
            </Link> */}

            { this.props.currentUser.userInfo.role === "parent" ? null :
                <Link className="removeclass" to={routes.BRIEFCASE}>
                    <ListItem button>
                        <ListItemIcon>
                            <i className="material-icons"> work </i>
                        </ListItemIcon>
                        <ListItemText primary="Briefcase"/>
                    </ListItem>
                </Link>
            }

            <Link className="removeclass" to={routes.EVENT}>
                <ListItem button>
                    <ListItemIcon>
                        <i className="material-icons"> event_available </i>
                    </ListItemIcon>
                    <ListItemText primary="Calender" />
                </ListItem>
            </Link>
             <Link className="removeclass" to={routes.Photos}>
                <ListItem button>
                    <ListItemIcon>
                        <i className="material-icons"> photos </i>
                    </ListItemIcon>
                    <ListItemText primary="Gallery" />
                </ListItem>
            </Link>

            {/* <Divider /> */}
        </div >
    );

    adminMenuListItems = (
        <div>
            <Link className="removeclass" to={routes.DASHBOARD}>
                <ListItem button>
                    <ListItemIcon>
                        <i className="material-icons"> school </i>
                    </ListItemIcon>
                    <ListItemText primary="School Profile" />
                </ListItem>
            </Link>

            <Link className="removeclass" to={routes.StudentSummary}>
                <ListItem button>
                    <ListItemIcon>
                        <i className="material-icons"> update </i>
                    </ListItemIcon>
                    <ListItemText primary="Attendance Summary" />
                </ListItem>
            </Link>
        </div >
    );

    render() {
        const { classes, currentUser: { auth, userInfo: { role } }, pageLoaderCount } = this.props;
        const { anchorElProfileMenu, anchorElNotificationMenu, openDrawer } = this.state;
        const openProfileMenu = Boolean(anchorElProfileMenu);
        const openNotificationMenu = Boolean(anchorElNotificationMenu);


        return (
            <div className={classes.root}>
                <CssBaseline />

                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar)}
                >
                    {pageLoaderCount > 0 && <LinearProgress color="secondary" variant="query" className={classes.lineprogress} />}
                    <Toolbar disableGutters={true}>
                        <div>
                           <img className={classes.logoButton} src={logo} alt="Logo" />
                        </div>
                        <Typography variant="h6" color="inherit" noWrap className={classes.header}>
                            Welcome <span className={classes.name}> {this.props.currentUser.userInfo.given_name} </span>
                        </Typography>

                        {auth.idToken &&
                            <div className={classes.rightContainer}>
                                {/* <IconButton
                                    aria-owns={openNotificationMenu ? 'menu-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleNotificationMenu}
                                    color="inherit">
                                    <Badge badgeContent={8} color="secondary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton> */}

                                <Popper
                                    open={openNotificationMenu}
                                    onClose={this.handleNotificationClose}
                                    anchorEl={anchorElNotificationMenu}
                                    transition
                                    disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            id="menu-list-grow"
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleNotificationClose}>
                                                    <MenuList style={{
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: 200,
                                                        overflow: 'auto'
                                                    }
                                                    }>
                                                        {options.map(option => (
                                                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={this.handleNotificationClose}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                                <IconButton
                                    aria-owns={openProfileMenu ? 'menu-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleProfileMenu}
                                    color="inherit"
                                >
                                    {
                                        (auth.idToken && auth.idToken.payload && auth.idToken.payload.picture) === "<DEFAULT>" ?
                                            <Avatar xs={2} aria-label="Recipe" className={classes.avatar} src={userAvatar}/> :
                                            <Avatar
                                                className={classes.smallAvatar}
                                                alt={auth.idToken && auth.idToken.payload && this.props.currentUser.userInfo.given_name}
                                                src={auth.idToken && auth.idToken.payload && this.props.currentUser.userInfo.picture}
                                        />
                                    }

                                </IconButton>
                                <Popper
                                    id="menu-appbar"
                                    open={openProfileMenu}
                                    anchorEl={anchorElProfileMenu}
                                    onClose={this.handleProfileClose}
                                    transition
                                    disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            id="menu-list-grow"
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleProfileClose}>
                                                    <MenuList>
                                                        <MenuItem onClick={this.handleProfileClose}>
                                                            {this.props.currentUser.userInfo.given_name} {this.props.currentUser.userInfo.family_name}
                                                        </MenuItem>
                                                        <MenuItem onClick={this.onClickLogout}>LogOut</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        }

                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: openDrawer,
                        [classes.drawerClose]: !openDrawer,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: openDrawer,
                            [classes.drawerClose]: !openDrawer,
                        }),
                    }}
                    open={openDrawer}
                >
                    <div className={classes.toolbar}>

                    </div>
                    <Divider />
                    <List>
                        {role === 'teacher' && this.mainMenuListItems}
                        {role === 'parent' && this.mainMenuListItemsParent}
                       
                        {role === 'admin' && this.mainMenuListItems}
                        <Divider />
                        {role === 'admin' && this.adminMenuListItems}
                    </List>

                </Drawer>

                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {role === 'teacher' && <Route component={OtherRoutes} path={routes.ROOT} />}
                    {role === 'admin' && <Route component={OtherRoutes} path={routes.ROOT} />}
                    {role === 'admin' && <Route component={AdminRoutes} path={routes.ROOT} />}
                    {role === 'parent' && <Route component={ParentRoutes} path={routes.Photos} />}

                </main>
                <CustomSneakbars />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    pageLoaderCount: state.component.pageLoaderCount,
    toast: state.component.toast
});




export default connect(mapStateToProps)(withStyles(styles)(Layout));