import {
    Auth,
    API
} from 'aws-amplify';

const authenticateUserApi = (username, password) => {
    return Auth.signIn(username, password)
        .then(user => user)
        .catch(err => err)
}

const getUserDetail = () => {

    return API.get('get_user_info', '', '')
        .then(response => response)
        .catch(error => error)

}

const resetPassword = (user) => {
    const init = {
        body: {
            user
        }
    }
    return API.post('reset_password', '', init).then(res => res).catch(error => error)
}

const forgotPasswordSubmit = (username, code, new_password) => {
    return Auth.forgotPasswordSubmit(username, code, new_password)
}

const authService = {
    authenticateUserApi,
    getUserDetail,
    resetPassword,
    forgotPasswordSubmit
}

export default authService;