import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { reduxForm } from 'redux-form';

import PostForm from "./PostForm";
import { POST_TYPE } from "./reduxForm/context";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Chip, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
    card: {
        backgroundColor: "#f6f6f6",
        maxWidth: 700,
        marginBottom: '25px',
        width: 600
    },
    mb10: {
        marginBottom: '10px'
    },
    fontBold: {
        fontWeight: 'bold'
    },
    filter: {
        fontSize: '14px',
        fontWeight: 'normal',
        opacity: 0.5,
        cursor: 'pointer',
        paddingLeft: '10px'
    },
    progress: {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    filterStyle:{
        color: "#0303ff",
        fontSize: '15px',
        padding: 2
    }
});

const validate = values => {
    const errors = {}
    const requiredFields = [
        'groupids'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (
        !values['post']
        && !(values['img'] && values['img'].length > 0)
        && !(values['vid'] && values['vid'].length > 0)
    ) {
        errors['post'] = 'required'
    }

    return errors
}

class CreatePost extends Component {

    handleFormSubmit = (value) => {
        const { createPostAction, fetchStaffAction, fetchStudentsAction } = this.props;
        const {
            groupids, posttype,
            post = "", posttime = "",
            img = [], vid = [], misc = [],
            review_users = [], childids = [],
            pinned_mode = "", pinned_topic = "", pinned_until = "",
        } = value;


        const body = {
            groupids: [groupids],
            post,
            img: [...img],
            vid: [...vid],
            misc: [...misc],
            posttime,
            review_mode: 0,
            review_users: [],
            pinned_mode: 0,
            pinned_topic: "",
            pinned_until: "",
            draft_mode: 0,
            childids: [],
        }

        if (review_users.length > 0) {
            body.review_mode = 1;
            body.review_users = review_users;
        }

        if (posttype === POST_TYPE[1].value) {
            body.draft_mode = 1;
        }

        if (posttype === POST_TYPE[2].value) {
            body.childids = childids
        }

        if (pinned_mode === 1) {
            body.pinned_post = 1;
            body.pinned_topic = pinned_topic;
            body.pinned_until = pinned_until
        }
        createPostAction.request(body);
        const groupidInfo = Number(localStorage.getItem('groupids'))
        if(typeof groupidInfo === "number"){
            setTimeout(() => {
                this.props.change('groupids', groupidInfo);
                fetchStaffAction.request(groupidInfo);
                fetchStudentsAction.request(groupidInfo);
            },2000)
        }
    }

    handleFilter = (e) => {
        this.props.openDialogAction('OPEN_POST_FILTER')
    }

    render() {
        const { classes, filterpost, loading, ...other } = this.props;
        let childs = (filterpost.childs && filterpost.childs.length)
        let groups = (filterpost.groups && filterpost.groups.length)
        return (
            <Grid>
                <Grid item className={classes.mb10}>
                    <Typography variant="h5" className={classes.fontBold}>
                        Feed
                        {
                            childs || groups ?
                                <span className={classes.filterStyle} onClick={this.handleFilter}>
                            Filter
                        </span> :
                                <span className={classes.filter} onClick={this.handleFilter}>
                            Filter
                        </span>
                        }
                        &nbsp;
                        { filterpost.groups && filterpost.groups.length ? filterpost.groups.map(g => (
                            <span style={{marginRight: 5}}>
                                <Chip
                                    label={g}
                                    variant="outlined"
                                    color="primary"
                                />
                            </span>
                        )) : null }

                    </Typography>
                </Grid>
                
                {loading && 
                <Grid className={classes.progress}>
                    <CircularProgress size={80}/>
                </Grid>}
                <Grid className={classes.card}>
                    <PostForm
                        {...other}
                        handleFormSubmit={this.handleFormSubmit}
                    />
                </Grid>
            </Grid >
        );
    }
}

const wrapperForm = reduxForm({
    form: 'managePost',
    validate
})(CreatePost)

const styleWrapper = withStyles(styles)(wrapperForm);

export default styleWrapper

