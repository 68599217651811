import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DialogContent from '@material-ui/core/DialogContent';
import { RsvpAttend } from "../RsvpAttend";
import withDialog from "../../common/hoc/Dialog";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class EditRsvp extends Component {
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  updateRSVP = (data) => {
    const { row, updateRsvpEventResponseAction } = this.props;
    data.user = row.email
    updateRsvpEventResponseAction.request(data);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.rowC}>
            <Grid item xs={12}>
              <div className="eventDialogTitle">
                <div className="titleDiv">Edit Message</div>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <RsvpAttend
                    readOnly={false}
                    eventId={this.props.row.event_id}
                    updateRSVP={this.updateRSVP}
                    updateComponent={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </div>
    );
  }
}


EditRsvp.propTypes = {
  classes: PropTypes.object.isRequired
};

const wrapperStyle = withStyles(styles)(EditRsvp)
export default withDialog(wrapperStyle)
