import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EnhancedTable from "./EnhancedTable";

const styles = theme => ({
    root: {
        width: 720,
    },
    singleTab: {
        height: 60,
        backgroundColor: "#efefef",
        marginRight: 10,
        minWidth: 72,
    },
    tabsParent: {
        width: 500,
    },
    headerTab: {
        backgroundColor: "#FFF",
        boxShadow: "none"
    },
    tabSelected: {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        backgroundColor: "#FFF",
    },
    tabsIndicator: {
        backgroundColor: "#FFF"
    },

});

class CustomTabs extends Component {

    render() {
        const { classes, rsvpFilterValue, handleRsvpFilterAction } = this.props;
        const tabStyle = { root: classes.singleTab, selected: classes.tabSelected };
        return (
            <div className={classes.root}>
                <AppBar position="static" color="default" className={classes.headerTab}>
                    <Tabs
                        value={rsvpFilterValue}
                        onChange={(event, value) => { handleRsvpFilterAction(value) }}
                        variant="fullWidth"
                        classes={{ root: classes.tabsParent, indicator: classes.tabsIndicator }}
                    >
                        <Tab label="Sent" value="Sent" classes={tabStyle} />
                        <Tab label="Yes" value="Yes" classes={tabStyle} />
                        <Tab label="Maybe" value="Maybe" classes={tabStyle} />
                        <Tab label="No" value="No" classes={tabStyle} />
                        <Tab label="No Reply" value="No Reply" classes={tabStyle} />
                    </Tabs>
                </AppBar>

                <EnhancedTable
                    rowsRsvp={this.props.rowsRsvp}
                    handleRsvpSearchAction={this.props.handleRsvpSearchAction}
                    currentUser={this.props.currentUser}
                    handleEditRecord={this.props.handleEditRecord}
                    selected={rsvpFilterValue}
                />
            </div>
        );
    }
}


CustomTabs.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(CustomTabs));
