import {
    API
} from 'aws-amplify';
import {
    takeEvery,
    fork,
    all,
    put
} from 'redux-saga/effects'

import {
    FETCH_USER_CACHE,
    fetchUserCatch
} from '../actions/cache'
import { REQUEST } from '../actions/common';

function* fetchUserCacheData(action) {
    try {
        const data = yield API.get('user_cache', '');
        yield put(fetchUserCatch.success(data))
    } catch (error) {
        yield put(fetchUserCatch.failure(error))
    }
}


function* watchFetchProducts() {
    
    yield takeEvery(FETCH_USER_CACHE[REQUEST], fetchUserCacheData)
}


export default function* rootCache() {
    yield all([
        fork(watchFetchProducts),
    ]);
}