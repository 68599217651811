import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';


function AsyncImage(props) {
  const [src, setSrc] = useState('')

  useEffect(() => {
    getImage(props.src);
  }, [props.src])

  async function getImage(imgId) {
    const src = await Storage.get(imgId, { level: "public" });
    setSrc(src)
  };
  
  return (
    <img
      {...props}
      src={src}
      alt={props.alt}
    />
  )

}

AsyncImage.defaultProps = {
  src: '',
  alt: ''
};

export default AsyncImage;