import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import {
    TextField, withStyles, Button, Typography,
    Card, CardActions, Avatar, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import { updateByPropertyName } from '../utils/utils';
import authService from '../service/auth';
import { userInfoSuccess, fetchAuthUserSuccess } from '../redux/actions/current-user';
import awsConfig from '../utils/aws-config';
import * as Routes from '../routes/path';
import CacheBuster from "../component/cachebuster";
import Loader from "../assets/jss/components/Loader";

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(https://source.unsplash.com/user/360memos/likes/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
        padding: '3em'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    errorMessage: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    header: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20
    }
});

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (<TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
/>);


const INITIAL_STATE = {
    userName: '',
    password: '',
    errorMessage: '',
    resetErrorMessage: '',
    open: false,
    email: '',
    code: '',
    new_password: '',
    isMailSend: false
}

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = INITIAL_STATE
    }
    onTextChange = (event) => {
        const { name, value } = event.target;
        this.setState(updateByPropertyName(name, value));
        if (name === 'email') {
            this.setState({ isMailSend: false })
        }
        if (name === 'code' || name === 'new_password') {
            this.setState({ resetErrorMessage: '' })
        }
    }
    onFormSubmit = async (data) => {
        const { dispatch, history } = this.props;
        this.setState({isLoader: true})
        try {
            const { userName, password } = data;
            const authResponse = await authService.authenticateUserApi(userName, password);
            if (authResponse.signInUserSession) {
                const { signInUserSession } = authResponse;
                dispatch(fetchAuthUserSuccess(authResponse))
                localStorage.setItem('hmUser', JSON.stringify(signInUserSession))
                const userResponse = await authService.getUserDetail();
                if (userResponse.Username) {
                    dispatch(userInfoSuccess(userResponse))
                    history.push(Routes.ROOT);
                    this.setState({
                        isLoader: false
                    })
                }
            } else {
                this.setState(updateByPropertyName('errorMessage', authResponse.message,{isLoader: false}));
                throw authResponse.message
            }
        } catch (error) {
            this.setState({
                isLoader: false
            })
        }
    }
    handleClickOpenModel = () => {
        this.setState({ open: true, email: '' });
    };
    handleCloseModel = () => {
        this.setState({ open: false });
    };

    resetPassword = (event) => {
        event.preventDefault();
        const { email } = this.state;
        authService.resetPassword(email);
        this.setState({ isMailSend: true })
    }

    changePassword = async (event) => {
        try {
            event.preventDefault();
            const { email, code, new_password } = this.state;
            if (email && code && new_password) {
                const result = await authService.forgotPasswordSubmit(email, code, new_password);
                this.setState({ isMailSend: false, open: false, resetErrorMessage: '' })
                console.log(result);
            } else {
                console.log(email, code, new_password)
            }
        } catch (error) {
            this.setState({ resetErrorMessage: error.message })
        }

    }

    render() {
        const { classes, handleSubmit } = this.props;
        const { errorMessage, email, code, new_password, isMailSend, resetErrorMessage, isLoader } = this.state;
        return (
            <div className={classes.main}>
                {isLoader ? <Loader/> : null}
                <Card className={classes.card}>
                    <div className={classes.header}>{awsConfig.app_header}</div>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    <form onSubmit={handleSubmit(this.onFormSubmit)}>
                        <div className={classes.errorMessage} style={{color: "red"}}> {errorMessage}</div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    name="userName"
                                    component={renderInput}
                                    label="Username"
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    component={renderInput}
                                    label="Password"
                                    type="password"
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                className={classes.button}
                                fullWidth
                            >
                                SIGN IN
                            </Button>
                        </CardActions>
                    </form>
                    <div>
                        <button className="btn btn-link" onClick={this.handleClickOpenModel}>Forget Password</button>
                    </div>
                </Card>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseModel}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To reset password, please enter your email address here. please check your email.
                    </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            value={email}
                            name="email"
                            label="Email Address"
                            type="email"
                            onChange={this.onTextChange}
                            fullWidth
                        />
                        {isMailSend && (
                            <Fragment>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    value={code}
                                    name="code"
                                    label="code"
                                    type="number"
                                    onChange={this.onTextChange}
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    value={new_password}
                                    name="new_password"
                                    label="New Password"
                                    type="password"
                                    onChange={this.onTextChange}
                                    fullWidth
                                />
                            </Fragment>
                        )}
                        <Typography color="error">{resetErrorMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseModel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={isMailSend ? this.changePassword : this.resetPassword} color="primary">
                            {isMailSend ? 'Change Password' : 'Reset Password'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <CacheBuster>
                    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                        if (loading) return null;
                        if (!loading && !isLatestVersion) {
                            // You can decide how and when you want to force reload
                            refreshCacheAndReload();
                        }

                        return (
                            <div className="App">
                                <header className="App-header">
                                    <p>
                                        version - <code>v{global.appVersion}</code>
                                    </p>
                                </header>
                            </div>
                        );
                    }}
                </CacheBuster>
            </div>
        );
    }
}

const loginForm = reduxForm({
    form: 'loginForm',
    enableReinitialize: true,
})(Login)

const mStyles = withStyles(styles)(loginForm);

const mapStateToProps = state => ({ state });

export default connect(mapStateToProps)(mStyles);

