import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Cancel';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { connect } from "react-redux";

import { change } from 'redux-form'

const styles = {
    avatar: {
        margin: "10px 10px 10px 25px",
    },
    avatarLable: {
        margin: "10px 20px",
    },
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
    groupSection: {
        margin: '25px 0px',
    },
    noSpace: {
        margin: 0,
        padding: 0
    },
    selectionSection: {
        borderRight: '1px solid #0093AF',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
    },
    scrollingWrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        minHeight: '150px',
    },
    card: {
        flex: '0 0 auto',
    },
    backgroundParents: {
        backgroundColor: "rgba(0, 0, 0, .05);",
    },
    rowC: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    dialogContent: {
        padding: 0,
        overflow: 'scroll',
        overflowX: "hidden"
    },
    titleStyle: {
        textAlign: "center",
        color: "#000",
    },
    checkBox: {
        color: 'rgb(73,80,183)',
        '&$checked': {
            color: 'rgb(73,80,183)',
        },
    },
    checked: {},
};

class GroupParentsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            option: [],
            groupSelection: '',
            name: [],
            GroupName: '',
            parents: [],
            inputParentNames: [],
            inputGroupNames: [],
            groupList: [],
            parentlist: [],
        };
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    componentDidMount = () => {
        /*Pre load parent when open dialog*/
        this.setState({
            parents: this.props.groups[0].parents,
            parentlist: this.props.parentlist
        });
    }

    handleToggle = parent => () => {
        const { parentlist } = this.state;
        let allParents = [...parentlist];
        const isParentExist = parentlist.map(parent => parent.username).indexOf(parent.username);

        if (isParentExist === -1) {
            allParents = allParents.concat(parent);
        } else {
            allParents = allParents.filter(allParent => allParent.username !== parent.username)
        }

        this.setState({ parentlist: allParents });
    };

    handleCancel = () => {
        this.props.onClose(this.props.value);
    };

    handleOk = () => {
        this.props.saveParent(this.state.parentlist)
        this.props.onClose(this.state.value);
    };

    handleChange = key => (event, value) => {
        this.setState({ [key]: value, });
    };

    handleSelectGroup = (GroupName) => (event) => {
        let selectedGroups = this.props.groups.filter(group => GroupName.indexOf(group.GroupName) > -1);
        let parents = [];
        selectedGroups.forEach(group => {
            parents = parents.concat(group.parents);
        });
        this.setState({ [event.target.name]: event.target.value, parents });
    };

    render() {
        const { groups, classes,open,onClose } = this.props;
        const { inputGroupNames, parentlist } = this.state;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                fullWidth={true}
                open={open}
                onClose={onClose}
                >

                <DialogTitle id="confirmation-dialog-title">Group/Parents Selection</DialogTitle>

                <Grid container justify="flex-start" alignItems="center" className={classes.scrollingWrapper}>
                    {groups.map((option, index) => (
                        <div
                            key={index}
                            onClick={this.handleSelectGroup(option.GroupName)}
                            className={classes.card}
                            value={inputGroupNames}
                            name="inputGroupNames"
                        >
                            <Avatar className="groupAvtar">{(option.GroupName).charAt(0)}</Avatar>
                            <Typography className="groupAvatarLable">
                                {
                                    option.GroupName.length <= 13 ?
                                        option.GroupName :
                                        option.GroupName.substring(0, 10) + "..."}
                            </Typography>
                        </div>
                    ))}
                </Grid>

                <Divider variant="middle" />
                <DialogContent className="dialogContent">
                    <div className={classes.rowC}>
                        <Grid item xs={6}>
                            <Typography color="primary" variant="h6" className={classes.titleStyle}>
                                List of Members  from Group
                            </Typography>
                            <List className="parentList">
                                {this.state.parents.map(parent => (
                                    <ListItem key={parent.username} role={undefined} dense button
                                        onClick={this.handleToggle(parent)}>
                                        <ListItemAvatar>
                                            <Avatar className="avatarColor">{(parent.display_name).charAt(0)}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText className="parentName" primary={parent.display_name} />
                                        <Checkbox icon={<CheckCircleOutline />}
                                            checkedIcon={<CheckCircle />}
                                            classes={{
                                                root: classes.checkBox,
                                                checked: classes.checked,
                                            }}
                                            checked={parentlist.map(parent => parent.username).indexOf(parent.username) !== -1}
                                            tabIndex={-1}
                                            disableRipple />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={6} className={classes.backgroundParents}>
                            <Typography color="primary" variant="h6" className={classes.titleStyle}>
                                All selected Members
                            </Typography>
                            <List className="parentList">
                                {parentlist.map(parent => (
                                    <ListItem key={parent.username} role={undefined} dense button>
                                        <ListItemAvatar>
                                            <Avatar className="avatarColor">P</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText className="parentName" primary={parent.display_name} />
                                        <ListItemSecondaryAction>
                                            <IconButton aria-label="Remove" onClick={this.handleToggle(parent)}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </div>
                </DialogContent>
                <Divider variant="middle" />
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleOk} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => state


const mapDispatchToProps = dispatch => {
    return {
        saveParent: parent => dispatch(change("generalDetailsForm", "parents", parent))
    }
};



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupParentsDialog));