import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SignupParentTeacher from '../component/Event/MeetingDateTime/SignupParentTeacher';
import { EventSelector } from "../redux/selector";
import {
  setInitialValue, 
  getEventById,
  setEventResponse,
  deleteTimeSlot,
  resetStepperActiveStep,
  fetchEventResponse,
  removeChildEvent
} from '../redux/actions/event';

const mapStateToProps = state => ({
  events: EventSelector.getGroupWithNameFromEventId(state),
  eventResponse:state.event.rsvpEventResponse,
});



const mapDispatchToProps = dispatch => {
  return {
    setInitialValue: bindActionCreators(setInitialValue, dispatch),
    getEventById: bindActionCreators(getEventById, dispatch),
    setEventResponse: bindActionCreators(setEventResponse, dispatch),
    deleteTimeSlot: bindActionCreators(deleteTimeSlot, dispatch),
    removeChildEventAction: bindActionCreators(removeChildEvent, dispatch),
    resetStepperActiveStep: bindActionCreators(resetStepperActiveStep, dispatch),
    fetchEventResponseAction: bindActionCreators(fetchEventResponse, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupParentTeacher)
