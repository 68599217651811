import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Popconfirm } from 'antd'
import {
    withStyles, Grid, Card, Avatar,
    Typography, Button, Input,
} from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import CustomLoadingOverlay from "../component/common/customLoadingOverlay.jsx";
import GroupManage from '../component/groupManage';
import groupService from '../service/group';
import { userInfoSuccess } from '../redux/actions/current-user';
import authService from '../service/auth';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    row: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(1) * 2,
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1) * 2,
    },
    select: {
        margin: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },

});

class Group extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            category: 'all',
            columnDefs: this.createColumnDefs(),
            frameworkComponents: {
                customLoadingOverlay: CustomLoadingOverlay,
            },
            loadingOverlayComponent: "customLoadingOverlay",
            loadingOverlayComponentParams: { loadingMessage: "Loading groups.... Please wait.." },
            onGridReady: this.onGridReady,
            rowData: [],
            currentGroup: {},
            style: {
                width: '100%',
                height: '100%'
            }
        }
    }

    createColumnDefs() {
        return [
            {
                headerName: "Profile",
                field: "pictureurl",
                autoHeight: true,
                cellRendererFramework: this.profilePictureRenderer,
                width: 80
            },
            { headerName: "GroupName", field: "groupname", width: 100 },
            { headerName: "Description", field: "desc", width: 100 },
            { headerName: "Attendance Mode", field: "attendance_mode", width: 100 },
            {
                headerName: "Action",
                field: "childid",
                width: 200,
                cellRendererFramework: this.actionColumnRenderer,
                autoHeight: true
            },
        ]
    }

    profilePictureRenderer = event => {
        const { classes } = this.props;
        const { profile_photo, groupname } = event.data;
        if (profile_photo) {
            return (<Avatar className={classes.avatar} alt={groupname[0]} src={profile_photo} />)
        }
        return (<Avatar className={classes.avatar}>{groupname[0].toUpperCase()}</Avatar >)
    }

    componentDidMount() {
        this.fetchGroup();
    }

    fetchGroup = async () => {
        try {
            this.startLoading();
            const response = await groupService.getGroupList();
            const data = response.map(group => {
                return {
                    ...group,
                    groupname: group.groupname.split('_').join(' '),
                };
            });
            this.setState({ rowData: data });
            setTimeout(() => {
                this.gridApi.resetRowHeights();
            }, 100);
            this.stopLoading();
            this.getAuthUserInfo();
        } catch (error) {

        }
    }

    async getAuthUserInfo() {
        const { dispatch } = this.props;
        const userResponse = await authService.getUserDetail();
        if (userResponse.Username) {
            dispatch(userInfoSuccess(userResponse))
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
        this.startLoading();
    }

    onColumnResized(event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    }
    onClickAddGroup = event => {
        this.setState({ open: true });
    }

    deleteChild = event => {
        const { groupname } = event.data;
        if (!groupname) return;
        const body = {
            groupname,
        }
        groupService.deleteGroup(body)
            .then(res => console.log(res))
            .then(() => this.fetchGroup())
            .catch(err => console.log(err));
    }

    handleClickOpen = event => {
        const { data } = event;
        this.setState({ open: true, currentGroup: data });
    };

    onSelectionChanged = () => {
        console.log('On View')
        const selectedRows = this.gridApi.getSelectedRows();
        selectedRows.forEach((selectedRow, index) => {
            if (index !== 0) {
                console.log('0  index..')
            }
            const id = selectedRow.groupid
            this.props.history.push(`/admin/group/${id}`)
        });
    }

    actionColumnRenderer = event => {
        return (
            <div>
                <button type="button" className="btn btn-link" onClick={this.onSelectionChanged}>View</button>
                <Popconfirm title="Are you sure delete this task?" onConfirm={() => this.deleteChild(event)} okText="Yes" cancelText="No">
                    <button type="button" className="btn btn-link">Delete</button>
                </Popconfirm>
            </div>
        )
    }

    search = ev => {
        const value = ev.target.value ? ev.target.value.toString().trim() : '';
        this.gridApi.setQuickFilter(value);
    }

    handleClose = () => {
        this.setState({ open: false, currentGroup: {} });
    };

    handleSelectChange = (event) => {
        const { name, value } = event.target;
        const { child: { childList } } = this.props;
        this.setState({ [name]: value });
        if (value === 'all') {
            this.setState({ rowData: childList });
        } else {
            const data = childList && childList.filter(child => child.groups.some(group => group === value));
            this.setState({ rowData: data });
        }
        setTimeout(() => {
            this.gridApi.resetRowHeights();
        }, 100);
    }

    startLoading = () => {
        if (this.gridApi)
            this.gridApi.showLoadingOverlay();
    }

    stopLoading = () => {
        this.gridApi.hideOverlay();
    }



    render() {
        const { classes, currentUser: { userInfo } } = this.props;
        const { open, style } = this.state;
        const childProps = {
            open: open,
            handleClose: this.handleClose,
            fetchGroup: this.fetchGroup,
            currentGroup: this.state.currentGroup,
            userInfo,
        }

        return (
            <div className={classes.root}>
                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <Card className={classNames(classes.paper)}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography className={classes.typography} variant="h6" gutterBottom>Group Dashboard</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Input placeholder="Search" onChange={this.search} className={classes.input} inputProps={{ 'aria-label': 'Description', }} />
                                </Grid>
                                <Grid item xs={4} >
                                    <Button variant="contained" size="small" color="primary" className={classes.button} onClick={this.onClickAddGroup}>
                                        Add Group
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <Card >
                            <div className="ag-theme-balham" style={style}>
                                <AgGridReact
                                    frameworkComponents={this.state.frameworkComponents}
                                    loadingOverlayComponent={this.state.loadingOverlayComponent}
                                    loadingOverlayComponentParams={this.state.loadingOverlayComponentParams}
                                    animateRows={true}
                                    enableFilter={true}
                                    enableColResize={true}
                                    gridAutoHeight={true}
                                    onColumnResized={this.onColumnResized.bind(this)}
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}
                                    rowSelection="single"
                                    onSelectionChanged={this.onSelectionChanged}
                                    onGridReady={this.state.onGridReady} />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                <GroupManage {...childProps} />
            </div >
        );
    }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(withStyles(styles)(Group));