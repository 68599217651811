import { API } from 'aws-amplify';

class EventService {

    getEventList(body) {
        const init = {
            body
        }
        return API.post('get_event', '', init).then(res => res);
    }

    getEventById(body) {
        const init = {
            body
        }
        return API.post('get_event', '', init).then(res => res);
    }

    addEvent(body) {
        const init = {
            body
        }
        return API.post('create_event', '', init).then(res => res);
    }

    updateEvent(body) {
        const init = {
            body
        }
        return API.post('edit_event', '', init).then(res => res);
    }

    deleteEvent(body) {
        const init = {
            body
        }
        return API.post('delete_event', '', init).then(res => res);
    }

    setRsvpEventResponse(body) {
        const init = {
            body
        }
        return API.post('set_rsvp_event_response', '', init).then(res => res);
    }

    updateRsvpEventResponse(body) {
        const init = {
            body
        }
        return API.post('update_rsvp_event_response', '', init).then(res => res);
    }

    getRsvpEventResponse(body) {
        const init = {
            body
        }
        return API.post('get-event-response', '', init).then(res => res);
    }

    getUserResponse(body) {
        const init = {
            body
        }
        return API.post('get-user-response', '', init).then(res => res);
    }

    trasnferOwnerShip(body){
        const init = {
            body 
        }

        return API.post('change-owner', '', init).then(res => res);
    }

    deleteEventSlot(body){
        const init = {
            body 
        }

        return API.post('spot-delete', '', init).then(res => res);
    }

    removeChildFromSlot(body){
        const init = {
            body 
        }

        return API.post('event-child-delete', '', init).then(res => res);
    }

    getUserEmailIds(body) {
        const init = {
            body
        }
        return API.post('get-users', '', init).then(res => res);
    }

    sendEmail(body) {
        const init = {
            body
        }
        return API.post('send-email', '', init).then(res => res);
    }
}

export default new EventService();