import {
    createSelector
} from 'reselect';

const getDraftPosts = state => state.briefcase.draft.posts;
const getSchedulePosts = state => state.briefcase.schedule.posts;
const getReviewPosts = state => state.briefcase.review.posts;
const getUserCache = state => state.cache.userCache;

const getDraftPostsWithUser = createSelector(
    [getDraftPosts, getUserCache], (posts = [], userCache) => {
        if (posts.length > 0 && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.comments.map(comment => ({
                        ...comment,
                        user: userCache[comment.user]
                    })),
                    user: userCache[post.user]
                };
            });
        }
        return [];
    }
)

const getSchedulePostsWithUser = createSelector(
    [getSchedulePosts, getUserCache], (posts = [], userCache) => {
        if (posts.length > 0 && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.comments.map(comment => ({
                        ...comment,
                        user: userCache[comment.user]
                    })),
                    user: userCache[post.user]
                };
            });
        }
        return [];
    }
)

const getReviewPostsWithUser = createSelector(
    [getReviewPosts, getUserCache], (posts = [], userCache) => {
        if (posts.length > 0 && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.comments.map(comment => ({
                        ...comment,
                        user: userCache[comment.user]
                    })),
                    user: userCache[post.user]
                };
            });
        }
        return [];
    }
)


export const BriefCaseSelector = {
    getDraftPostsWithUser,
    getSchedulePostsWithUser,
    getReviewPostsWithUser
};