import React, { Fragment } from 'react';
import * as routes from './path';
import { Route } from 'react-router-dom';

import Dashboard from '../container/Dashboard';
import Students from '../container/student';
import Users from '../container/users';
import Parent from '../container/parent';
import Staff from '../container/staff';
import Group from '../container/group';
import AddStudentsBulk from '../container/addStudentsBulk';
import GroupDetail from '../container/groupDetail';
import AttendanceSummary from '../container/AttendanceSummary';
import ParentManageForm from '../component/parentManage';
import StaffManageForm from '../component/staffManage';
import UserManageForm from '../component/studentManage';
import Photos from '../container/photos'


export default function AdminRoutes() {
    return (
        <Fragment>
            <Route component={Dashboard} path={routes.DASHBOARD} exact />
            <Route component={Students} path={routes.USER} exact />
            <Route component={UserManageForm} path={routes.USERS_ADD} exact />
            <Route component={UserManageForm} path={routes.USERS_EDIT} exact />
            <Route component={Users} path={routes.USERS} exact />
            <Route component={Parent} path={routes.PARENTS} exact />
            <Route component={ParentManageForm} path={routes.PARENTS_ADD} exact />
            <Route component={ParentManageForm} path={routes.PARENTS_EDIT} exact />
            <Route component={Staff} path={routes.STAFF} exact />
            <Route component={StaffManageForm} path={routes.STAFF_ADD} exact />
            <Route component={StaffManageForm} path={routes.STAFF_EDIT} exact />
            <Route component={Group} path={routes.GROUP} exact />
            <Route component={AddStudentsBulk} path={routes.GROUPBULK} exact />
            <Route component={GroupDetail} path={routes.GROUPINFO} exact />
            <Route component={AttendanceSummary} path={routes.StudentSummary} exact />
            <Route component={Photos} path={routes.Photos} exact />
        </Fragment>
    );
}

