import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Popover from '@material-ui/core/Popover';

import dates from "../common/dates";
import AddEventButton from "./addEventButton/AddEventButton";
import EventPreview from './EventPreview';
import Invitation from "./MyInVitiation";

import GroupImg from "../../assets/images/group.png";
import ParentImg from "../../assets/images/parents.png";
import Lounge from "../../assets/images/lounge.PNG";
import OrangeClass from "../../assets/images/orange_class.PNG";

const localizer = momentLocalizer(moment);


const styles = theme => ({
  root: {
    width: "100%"
  },
  lineprogress: {
    top: 0
  },
  cardTitleText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  filter: {
    color: '#3f51b5',
    cursor: 'pointer'
  }
});

function Event({ event }) {
  const avtarstyle = { width: "20px", height: "20px", marginRight: "2px", fontSize: "12px" }
  let image = '';
  let content = '';
  if (event && event.groups && event.groups.length === 1) {
    // if(event.groups[0].groupName === "Lounge"){
    //     image = Lounge;
    // }else if(event.groups[0].groupName === "Orange_Class"){
    //     image = OrangeClass;
    // }else {
    //     image = GroupImg;
    // }
    // content = (event.groups[0].groupName).charAt(0);
    if (event.groups[0].icon !== ''){
      image = event.groups[0].icon
    }else{
      image = GroupImg
    }
  } else if (event && event.groups && event.groups.length > 1) {
    image = GroupImg;
  } else {
    image = ParentImg;
  }

  return (
      <span style={{ display: "flex" }}>
      <Avatar alt="group" src={image} style={avtarstyle}>{content}</Avatar>
      <strong style={{ color: "black", fontSize: "12px" }}>{event.eventname}</strong>
        {/* {event.desc && ":  " + event.desc} */}
    </span>
  );
}

function EventAgenda({ event }) {
  return (
      <span>
      <em style={{ color: "magenta" }}>{event.eventname}</em>
        {/* <p>{event.desc}</p> */}
    </span>
  );
}

function combineTimeAndDate(date, time) {
  const _date = moment.utc(`${date}`).format('YYYY-MM-DD')
  const _time = moment(`${time}`, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm:ss')
  return moment(`${_date} ${_time}`, 'YYYY-MM-DD HH:mm:ss').toDate()
}

class CalenderView extends React.Component {
  state = {
    anchorElFilter: null,
    filtertype: 'upcoming'
  }

  componentDidMount() {
    const body = { fltr: "upcoming" }
    this.props.fetchFilterEvent.request(body);
    this.props.getEvent.request();
    this.props.fetchUserRSVPEvent.request();
    this.props.resetStepperActiveStep();
  }

  toggleEditModal = event => {
    this.props.setCurrentEventId(event.event_id)
    this.props.openDialogAction('OPEN_PREVIEW_SCREEN')
  };

  onClickOnInviation = id => {
    const { events } = this.props;
    const event = events.find(event => event.event_id === id)
    this.toggleEditModal(event);
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.event_color || '#d6cbd4';
    const style = { backgroundColor: backgroundColor };
    return { style: style };
  };

  handleFilter = (event) => {
    this.setState({ anchorElFilter: event.currentTarget });
  }

  handleFilterClick = (type) => () => {
    this.setState({ filtertype: type || 'all' },()=>{
      const body = {}
      body.fltr = this.state.filtertype;
      this.props.fetchFilterEvent.request(body);
      this.handleFilterClose();
    })
  }

  handleFilterClose = () => {
    this.setState({ anchorElFilter: null });
  }

  newDataSet = () =>{
    const body = {}
    body.fltr = this.state.filtertype;
    this.props.fetchFilterEvent.request(body);
  }

  render() {
    const { classes, events, dialogProps,
      setInitialValue, filterUserResponse, count, currentEvent, userInfo
    } = this.props;
    const { anchorElFilter } = this.state;
    const openFilter = Boolean(anchorElFilter);
    const filterId = openFilter ? 'filter-popover' : undefined;

    const { open, typeOfDialog } = dialogProps
    const max = dates.add(dates.endOf(new Date(2015, 17, 1), "day"), -1, "hours")
    filterUserResponse && filterUserResponse.sort(function (a, b) {
      return b.event_id - a.event_id;
    });
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} >
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Card >
                  <CardHeader
                      className="header-bold cardtitleText"
                      title="My Calendar"
                  />
                  <CardContent className="calendarContainer" style={{ height: "100vh", overflowY: "auto" }}>

                    <Calendar
                        step={60}
                        popup
                        localizer={localizer}
                        showMultiDayTimes
                        max={max}
                        defaultView={"month"}
                        views={["month", "week", "day"]}
                        defaultDate={new Date()}
                        events={events}
                        eventPropGetter={(this.eventStyleGetter)}
                        onSelectEvent={this.toggleEditModal}
                        startAccessor={event => combineTimeAndDate(event.start_date, event.start_time)}
                        endAccessor={(event) => combineTimeAndDate(event.end_date, event.end_time)}
                        tooltipAccessor={event => (event.eventname)}
                        components={{ event: Event, agenda: { event: EventAgenda } }}
                    />

                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Card>
                      <Grid container justify="space-between" style={{ padding: 15 }}>
                        <span className={classes.cardTitleText}>My Invitiations</span>
                        <span className={classes.filter} aria-describedby={filterId} onClick={this.handleFilter}>
                        Filter {' '}
                          {this.state.filtertype}
                          ({count && count[this.state.filtertype] ? count && count[this.state.filtertype] : 0})
                      </span>

                      </Grid>
                      <CardContent >
                        {Array.isArray(filterUserResponse) && filterUserResponse.map(data =>
                            data &&
                            (<Invitation
                                onClickOnInviation={this.onClickOnInviation}
                                data={data}
                                key={data.event_id}
                                loginUser={this.props.userInfo.email}
                                userRole={userInfo && userInfo.role}
                            />)
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <AddEventButton userInfo={this.props.userInfo} />

        {typeOfDialog === "OPEN_PREVIEW_SCREEN" && currentEvent &&
        <EventPreview
            open={open}
            onClose={this.props.closeDialogAction}
            setInitialValue={setInitialValue}
            event={currentEvent}
            newDataSet={this.newDataSet}
            eventResponse={this.props.eventResponse}
            history={this.props.history}
            userInfo={this.props.userInfo}
            sendRsvpEventResponseAction={this.props.sendRsvpEventResponseAction}
            deleteEventAction={this.props.deleteEventAction}
            setEventResponse={this.props.setEventResponse}
            transferOwnerShip={this.props.transferOwnerShip}
            fetchEventResponseAction={this.props.fetchEventResponseAction}
            closeDialogAction={this.props.closeDialogAction}
            removeChildEventAction={this.props.removeChildEventAction}
        />
        }

        <Popover
            id={filterId}
            open={openFilter}
            anchorEl={anchorElFilter}
            onClose={this.handleFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
        >
          <Grid container direction="column">
            <span className={classes.filter} style={{ padding: 10 }} onClick={this.handleFilterClick('upcoming')}>Upcoming Signups({count && count.upcoming})</span>
            <span className={classes.filter} style={{ padding: 10 }} onClick={this.handleFilterClick('past')}>Past Signups({count && count.past})</span>
            <span className={classes.filter} style={{ padding: 10 }} onClick={this.handleFilterClick(null)}>All Signups({count && count.all})</span>
          </Grid>
        </Popover>

      </div>
    );
  }
}

CalenderView.propTypes = {
  classes: PropTypes.object
};

export default withRouter(withStyles(styles)(CalenderView));


