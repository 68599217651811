export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';

export const ROOT = '/';
export const WELCOME = '/';
export const BRIEFCASE = '/briefcase';
export const PORTFOLIO = '/portfolio';

export const NEWSLETTER = '/newsletter';
export const NEWSLETTER_CREATE = '/newsletter/edit'

export const EVENT = '/event';
export const MANAGE_EVENT = '/event/manage';
export const MANAGE_RSVP_EVENT = '/event/rsvpmanage';
export const EDIT_RSVP_EVENT = '/event/editrsvp';
export const MEETING_EVENT = '/event/meeting';
export const MEETING_EVENT_SIGNUP = '/event/editmeeting';
export const VOLUNTEER_EVENT_SIGNUP = '/event/editvolunteer';
export const VOLUNTEER_EVENT = '/event/volunteer';

// export const VOLUNTEER = '/volunteer';
// export const VOLUNTEER_ADD = '/volunteer/add';
// export const RSVP_ADD = '/volunteer/add_rsvp';

/**admin routes start */
export const ADMIN = '/admin'
export const DASHBOARD = '/admin/dashboard';
export const USER = '/admin/student';
export const USERS = '/admin/students';
export const USERS_ADD = '/admin/student/add'
export const USERS_EDIT  = '/admin/student/edit'

export const PARENTS = '/admin/parent';
export const PARENTS_ADD = '/admin/parent/add';
export const PARENTS_EDIT = '/admin/parent/edit';

export const STAFF = '/admin/staff';
export const STAFF_ADD = '/admin/staff/add';
export const STAFF_EDIT = '/admin/staff/edit';

export const GROUP = '/admin/group';
export const GROUPBULK = '/admin/group/bulk/:groupname';
export const GROUPINFO = '/admin/group/:groupname';
export const StudentSummary = '/admin/student/summary';
export const StaffSummary = '/admin/staff/summary';
export const Photos = '/photos';
/** admin routes end */

