import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
    Paper, Grid, Button, InputBase, IconButton,
    MenuItem, Select, InputLabel, FormControl
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import * as PATH from "../../routes/path";

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        minWidth: "150px",
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '12px 26px 12px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const styles = theme => ({
    searchTextBoxRoot: {
        display: 'flex',
        alignItems: 'center',
        width: 320,
    },
    iconButton: {
        padding: 10,
    }
});

class SecondGrid extends Component {

    handleCreateNewsletter = (e) => {
        e.preventDefault()
        this.props.history.push(PATH.NEWSLETTER_CREATE)
    }
    render() {
        const { classes } = this.props;
        return (
            <Grid item container spacing={1} alignContent="center">
                <Grid item >
                    <FormControl >
                        <InputLabel htmlFor="select-group">Group</InputLabel>
                        <Select
                            value=""
                            // onChange={}
                            input={<BootstrapInput name="group" id="select-group" />}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item >
                    <Paper className={classes.searchTextBoxRoot}>
                        <IconButton className={classes.iconButton} aria-label="Search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'Search Google Maps' }}
                        />
                    </Paper>
                </Grid>
                <Grid container item justify="flex-end" xs={5}>
                    <Button onClick={this.handleCreateNewsletter}> Create Newsletter</Button>
                </Grid>
                <Grid container justify="space-evenly">
                <Button onClick={this.handleCreateNewsletter}> Draft </Button>
                <Button onClick={this.handleCreateNewsletter}> Review </Button>
                <Button onClick={this.handleCreateNewsletter}> Published </Button>

                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(SecondGrid)


