import React, { Fragment, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from "classnames";
import InfiniteScroll from 'react-infinite-scroller';
import BriefCaseCard from './BriefCaseCard';
// import { FakeCard } from '../FeedCard/FeedCard';
import UpdatePost from '../../container/UpdatePost';

const useStyles = makeStyles(theme => ({
    content: {
        height: '82vh',
        marginTop: '30px',
        overflowX: 'hidden',
        overflowY: 'hidden',
        '&:hover': {
            overflowY: "scroll",
            // background: "#f00",
         },
    },
    cardContent: {
        marginLeft: theme.spacing(1) * 1,
        padding: theme.spacing(1) * 1,
        width: '380px'
    },
    headerContent: {
        position: "fixed",
        alignContent: 'center',
        zIndex: 999,
        textAlign: 'center',
        marginLeft: '10px'
    }
}));

function BriefCaseComponent(props) {
    const classes = useStyles();

    const draftItems = [];
    const scheduleItems = [];
    const reviewItem = [];
    // const fake = Array.from({ length: 2 }, (e, index) => (<FakeCard key={index} />));
    const {
        draft: {
            feeds: draftFeeds,
            query: { hasMoreItems: draftHasMoreItems },
        },
        schedule: {
            feeds: scheduleFeeds,
            query: { hasMoreItems: scheduleHasMoreItems },
        },
        review: {
            feeds: reviewFeeds,
            query: { hasMoreItems: reviewHasMoreItems },
        },
        userGroups,
        currentUser,
        deletePostAction,
        cache,
        getUserCatch
    } = props;

    useEffect(() => {
        loadDraftItems(1);
        loadScheduleItems(1);
        loadReviewItems(1);
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        document.body.style.overflowY = "hidden";
        return () => {
            document.body.style.overflowY = "auto";
        };
    }, [draftFeeds, reviewFeeds, scheduleFeeds])


    function checkActionRule(type, data, currentUser) {
        switch (type) {
            case 'SCHEDULE_WITH_PUBLISH':
                return data.user.username === currentUser.userInfo.email || currentUser.userInfo.role === 'admin';
            case 'SCHEDULE_WITH_ACTION':
                return data.user.username === currentUser.userInfo.email || currentUser.userInfo.role === 'admin';
            case 'REVIEW_WITH_PUBLISH':
                return data.post_reviews.some(email => email === currentUser.userInfo.email)
                    || currentUser.userInfo.role === 'admin';
            case 'REVIEW_WITH_ACTION':
                return data.post_reviews.some(email => email === currentUser.userInfo.email)
                    || currentUser.userInfo.role === 'admin'
                    || data.user.username === currentUser.userInfo.email;
            default:
                return false;
        }
    }

    draftFeeds.forEach((item, i) => {
        draftItems.push(
            <BriefCaseCard
                isPublish={true}
                isEditOrDelete={true}
                data={item}
                key={i}
                showPopOver
                currentUser={currentUser}
                deletePostAction={deletePostAction}
                editPostAction={props.editPostAction}
                openDialogAction={props.openDialogAction}
                formInitializeAction={props.formInitializeAction}
                userList={cache && cache.userCache}
                type="draft"
            />)
    })

    scheduleFeeds.forEach((item, i) => {
        scheduleItems.push(
            <BriefCaseCard
                isPublish={checkActionRule('SCHEDULE_WITH_PUBLISH', item, currentUser)}
                isEditOrDelete={checkActionRule('SCHEDULE_WITH_ACTION', item, currentUser)}
                data={item}
                key={i}
                showPopOver
                currentUser={currentUser}
                deletePostAction={deletePostAction}
                editPostAction={props.editPostAction}
                openDialogAction={props.openDialogAction}
                formInitializeAction={props.formInitializeAction}
                userList={cache && cache.userCache}
                type="schedule"
            />)
    })

    reviewFeeds.forEach((item, i) => {
        reviewItem.push(
            <BriefCaseCard
                isPublish={checkActionRule('REVIEW_WITH_PUBLISH', item, currentUser)}
                isEditOrDelete={checkActionRule('REVIEW_WITH_ACTION', item, currentUser)}
                data={item}
                key={i}
                showPopOver
                currentUser={currentUser}
                deletePostAction={deletePostAction}
                editPostAction={props.editPostAction}
                openDialogAction={props.openDialogAction}
                formInitializeAction={props.formInitializeAction}
                userList={cache && cache.userCache}
                type="review"
            />)
    })

    function loadDraftItems(page) {
        const { fetchDraftPostAction, userGroups } = props;
        const groups = userGroups.map(group => group.groupid);
        if (!!groups.length) {
            const query = {
                'page': page - 1,
                'itemsperpage': 10,
                'groups': groups
            }
            fetchDraftPostAction.request(query);
        }
    }

    function loadScheduleItems(page) {
        const { fetchSchedulePostAction, userGroups } = props;
        const groups = userGroups.map(group => group.groupid);
        if (!!groups.length) {
            const query = {
                'page': page - 1,
                'itemsperpage': 10,
                'groups': groups
            }
            fetchSchedulePostAction.request(query);
        }
    }

    function loadReviewItems(page) {
        const { fetchReviewPostAction, userGroups } = props;
        const groups = userGroups.map(group => group.groupid);
        if (!!groups.length) {
            const query = {
                'page': page - 1,
                'itemsperpage': 10,
                'groups': groups
            }
            fetchReviewPostAction.request(query);
        }
    }

    if (userGroups.length !== 0) {
        return (
            <Fragment >
                <Grid container>

                    <Grid item xs={4}>
                        <Grid item xs={4} className={classes.headerContent} >
                            <h5> <b> Draft </b></h5>
                        </Grid>
                        <Grid>
                            <InfiniteScroll
                                id="custom-scroll"
                                className={classNames(classes.content, classes.style1)}
                                pageStart={0}
                                loadMore={loadDraftItems}
                                hasMore={draftHasMoreItems}
                                useWindow={false}
                                threshold={500}
                            >
                                    {draftFeeds.length > 0 && draftItems}
                            </InfiniteScroll>
                        </Grid>
                    </Grid>

                    <Grid item xs={4}>
                        <Grid item xs={4} className={classes.headerContent} >
                            <h5> <b> Schedule </b></h5>
                        </Grid>
                        <InfiniteScroll
                            id="custom-scroll"
                            className={classes.content}
                            pageStart={0}
                            loadMore={loadScheduleItems}
                            hasMore={scheduleHasMoreItems}
                            useWindow={false}
                        >
                            {scheduleFeeds.length > 0 && scheduleItems}
                        </InfiniteScroll>

                    </Grid>

                    <Grid item xs={4}>
                        <Grid item xs={4} className={classes.headerContent} >
                            <h5> <b>Review </b></h5>
                        </Grid>
                        <InfiniteScroll
                            id="custom-scroll"
                            className={classes.content}
                            pageStart={0}
                            loadMore={loadReviewItems}
                            hasMore={reviewHasMoreItems}
                            useWindow={false}
                        >
                            {reviewFeeds.length > 0 && reviewItem}
                        </InfiniteScroll>

                    </Grid>

                </Grid>
                <UpdatePost />
            </Fragment >
        );
    }
    return (<div />)
}

export default BriefCaseComponent;
