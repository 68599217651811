import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EventSelector } from "../redux/selector";
import {
  setInitialValue, getEvent, setRsvpResponseType,
  fetchUserRSVPEvent,
  setCurrentEventId,
  deleteEvent,
  setEventResponse,
  transferResponse,
  resetStepperActiveStep,
  fetchEventResponse,
  fetchFilterEvent,
  removeChildEvent
} from '../redux/actions/event';
import CalenderView from '../component/Event';
import { openDialogBox, closeDialogBox } from '../redux/actions/component';


const mapStateToProps = state => {
  return {
    userInfo: state.currentUser.userInfo,
    events: EventSelector.getGroupWithName(state),
    userRSVPEventResponse: state.event.userRSVPEventResponse,
    filterUserResponse:state.event.filterRSVPUserResponse,
    count: state.event.count,
    eventResponse:state.event.rsvpEventResponse,
    dialogProps: state.component.dialog,
    currentEvent: EventSelector.getCurrentEvent(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEvent: bindActionCreators(getEvent, dispatch),
    sendRsvpEventResponseAction: bindActionCreators(setRsvpResponseType, dispatch),
    setInitialValue: bindActionCreators(setInitialValue, dispatch),
    fetchUserRSVPEvent: bindActionCreators(fetchUserRSVPEvent, dispatch),
    fetchFilterEvent: bindActionCreators(fetchFilterEvent, dispatch),
    resetStepperActiveStep: bindActionCreators(resetStepperActiveStep, dispatch),
    setCurrentEventId: bindActionCreators(setCurrentEventId, dispatch),
    openDialogAction: bindActionCreators(openDialogBox, dispatch),
    closeDialogAction: bindActionCreators(closeDialogBox, dispatch),
    deleteEventAction: bindActionCreators(deleteEvent, dispatch),
    removeChildEventAction: bindActionCreators(removeChildEvent, dispatch),
    setEventResponse: bindActionCreators(setEventResponse, dispatch),
    transferOwnerShip: bindActionCreators(transferResponse, dispatch),
    fetchEventResponseAction: bindActionCreators(fetchEventResponse, dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CalenderView)
