import React from "react";
import classnames from "classnames"
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip, Popover, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { Popconfirm } from 'antd';

const styles = theme => ({
  dateTitme: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#05b9a4",
    padding: 20
  },
  centerContent: {
    width: "100%",
    margin: "20px auto"
  },
  table: {
    minWidth: 620,
    margin: "10px 35px",
    width: "95% !important"
  },
  customthead: {
    fontSize: 16
  },
  avatar: {
    float: "left",
    margin: "2px",
    fontSize: "14px",
    height: "20px",
    width: "20px",
  },
  innercell: {
    // borderBottom: "0px",
    width: "200px",
    padding: "5px"
  },
  innerCellWrap: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});


function PreviewVolunteer(props) {
  const {
    classes, event, data, readOnly, setEventResponse, removeChildEventAction,
    eventResponse: { userResponse = [] }, email
  } = props;

  const [openSignup, setOpenSignup] = React.useState(false);
  const [anchorElSignUp, setAnchorElSignUp] = React.useState(null);
  const [spotId, setSpotId] = React.useState('');

  const handleSignup = (spotId) => (event) => {
    setSpotId(spotId)
    setAnchorElSignUp(event.currentTarget);
    setOpenSignup(true)
  };

  const removeChild = (child, sportData) => (e) => {
    const events = event
    const body = {
      spotId: sportData.id,
      child_id: child.childid,
      eventid: events.event_id,
    }
    removeChildEventAction.request(body)
  }

  function closeSignup() {
    setOpenSignup(false)
  };

  function isSlotFull(userResponse, sportData) {
    const isFull = userResponse.find(res => res.spotId === sportData.id)
      ? userResponse.find(res => res.spotId === sportData.id)
        .childs.length === Number(sportData.person) : false

    return isFull
  }

  function sendSignupResponse(value) {
    const events = event
    if (value) {
      const body = {
        eventid: events.event_id,
        event_type: events.event_type,
        spotId,
        child_id: value.childid
      }
      setEventResponse.request(body)
    }
    closeSignup()
  }

  return (
    <>
      {event.volunteer.map((data, index) => (
        <Grid container key={index}>
          <Grid item xs={10} className={classes.centerContent}>
            <Grid container spacing={10}>
              <Grid item xs={12} className="centerdatetime">
                <Card className={classes.dateTimeCard}>
                  <Grid container>
                    { event && event.volunteertype !== "4" ?
                      <Grid item xs={4} className={classes.dateTitme}>
                        <Moment
                            utc
                            format="ddd D, MMMM YYYY"
                            date={data.event_date}
                        />
                      </Grid> :
                      <Grid item xs={6} className={classes.dateTitme}>
                        SignUp Deadline :&nbsp;
                        <Moment
                            utc
                            format="ddd D, MMMM YYYY"
                            date={data.event_date}
                        />
                      </Grid>
                    }
                  </Grid>

                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.customthead} >Time</TableCell>
                        { event && event.volunteertype !== "4" ? <TableCell className={classes.customthead} >Request</TableCell> : null }
                        { event && (event.event_type === 4 || event.event_type === "4") ? <TableCell className={classes.customthead}  >Comment</TableCell> : null }
                        <TableCell className={classes.customthead}  >filled</TableCell>
                        <TableCell className={classes.customthead}  >Participants</TableCell>
                        <TableCell className={classes.customthead} >Action</TableCell>
                      </TableRow>
                    </TableHead>
                    {data.slotTime.map((slotTimeData, index) =>
                      <TableBody>
                        {slotTimeData.spot !== undefined &&
                          slotTimeData.spot.map((sportData, index) => (
                            <TableRow key={index}>
                              { event && event.volunteertype !== "4" ?
                                <TableCell className={classes.innercell}>
                                  <Moment format="hh:mm a" date={slotTimeData.start_time}/>
                                  -
                                  <Moment format="hh:mm a" date={slotTimeData.end_time}/>
                                </TableCell> : null
                              }
                              <TableCell className={classes.innercell}>
                                <div key={index}>
                                  {sportData.description}
                                </div>
                              </TableCell>
                              { event && (event.event_type === 4 || event.event_type === "4") ?
                                <TableCell className={classes.innercell}>
                                  <div key={index}>
                                    { sportData && sportData.comment}
                                  </div>
                                </TableCell> : null
                              }
                              <TableCell className={classes.innercell}>
                                <div key={index}>
                                  {`${userResponse.find(res => res.spotId === sportData.id)
                                    ? userResponse.find(res => res.spotId === sportData.id)
                                      .childs.length : 0} Out of ${sportData.person}`}
                                </div>
                              </TableCell>
                              <TableCell className={classes.innercell}>
                                <div className={classes.innerCellWrap}>
                                  {userResponse.find(res => res.spotId === sportData.id)
                                    && userResponse.find(res => res.spotId === sportData.id)
                                      .childs.map((child, index) =>
                                        (
                                          <div key={child.childid} style={{ display: "flex" }}>
                                            <Tooltip title={`${child.firstname}' '${child.lastname}`}>
                                              <Avatar >
                                                {child.firstname[0].toUpperCase()}
                                              </Avatar>
                                            </Tooltip>
                                            {child.parents.some(parent => parent === email) &&
                                              <i className="fas fa-minus-circle removeicon" onClick={removeChild(child, sportData)} />
                                            }
                                          </div>
                                        ))
                                  }
                                </div>
                              </TableCell>

                              <TableCell className={classes.innercell}>
                                <Button
                                  variant="outlined"
                                  className={isSlotFull(userResponse, sportData) ? "fullButton" : "signupbutton"}
                                  onClick={handleSignup(sportData.id)}
                                  disabled={readOnly ? true : isSlotFull(userResponse, sportData) ? true : false}
                                >
                                  {isSlotFull(userResponse, sportData) ? "Full" : "Sign up"}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    )}
                  </Table>

                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}

      <Popover
        id="render-props-popover"
        open={openSignup}
        anchorEl={anchorElSignUp}
        onClose={closeSignup}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <List>
          {data && data.map((item, index) => (
            <ListItem key={index} button onClick={() => { sendSignupResponse(item) }}>
              <ListItemAvatar>
                <Avatar
                  src={item.pictureurl === "<DEFAULT>" ? '' : item.pictureurl}
                >
                  {item.firstname[0]}{item.lastname[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${item.firstname}${item.lastname}`} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}

PreviewVolunteer.defaultProps = {
  email: '',
  readOnly: false,
  eventResponse: { userResponse: [] }
}

export default withRouter(withStyles(styles)(PreviewVolunteer));
