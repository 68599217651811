import React, { Fragment } from 'react';
import * as routes from './path';
import { Route } from 'react-router-dom';
import Welcome from '../container/Home';
import ComingSoon from '../container/ComingSoon';
import Event from '../container/Event';
import ManageGenericEvent from '../container/ManageGenericEvent';
import ManageRsvp from '../container/ManageRsvp';
import ManageMeeting from '../container/ManageMeeting';
import ManageVoluntter from '../container/ManageVolunteer';
import UpdateRsvp from '../container/UpdateRsvp';
import SignupMeeting from '../container/SignupMeeting';
import BriefCase from '../container/BriefCase';
import NewsLetter from '../container/NewsLetter';
import EditNewsLetter from '../container/EditNewsLetter';
import SignupVolunteer from '../container/SignupVolunteer';
import Photos from '../container/photos'

export default function OtherRoutes() {
    return (
        <Fragment>
            <Route component={ComingSoon} path={routes.PORTFOLIO} exact />
            <Route component={NewsLetter} path={routes.NEWSLETTER} exact />
            <Route component={EditNewsLetter} path={routes.NEWSLETTER_CREATE} exact />
            <Route component={Welcome} path={routes.WELCOME} exact />
            <Route component={BriefCase} path={routes.BRIEFCASE} exact />
            <Route component={Event} path={routes.EVENT} exact />
            <Route component={ManageGenericEvent} path={routes.MANAGE_EVENT} exact />
            <Route component={UpdateRsvp} path={routes.EDIT_RSVP_EVENT} />
            <Route component={ManageRsvp} path={routes.MANAGE_RSVP_EVENT} exact />
            <Route component={ManageMeeting} path={routes.MEETING_EVENT} />
            <Route component={SignupMeeting} path={routes.MEETING_EVENT_SIGNUP} />
            <Route component={ManageVoluntter} path={routes.VOLUNTEER_EVENT} exact />
            <Route component={SignupVolunteer} path={routes.VOLUNTEER_EVENT_SIGNUP} />
            <Route component={Photos} path={routes.Photos} exact />
        </Fragment>
    );
}