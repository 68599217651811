import { connect } from 'react-redux';

import NewsLetter from "../component/NewsLetter";


const mapStateToProps = state => {
    return { state }
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetter);
