import {
    FETCH_DRAFT_POST, FETCH_SCHEDULE_POST, FETCH_REVIEW_POST
} from "../actions/breifcase";
import {
    REQUEST,
    SUCCESS,
    FAILURE
} from "../actions/common";

const INITIAL_STATE = {
    draft: {
        posts: [],
        query: {
            itemsperpage: 10,
            page: 0,
            hasMoreItems: true,
            groups: []
        },
        error: null,
        isLoading: false,
    },
    schedule: {
        posts: [],
        query: {
            itemsperpage: 10,
            page: 0,
            hasMoreItems: true,
            groups: []
        },
        error: null,
        isLoading: false,
    },
    review: {
        posts: [],
        query: {
            itemsperpage: 10,
            page: 0,
            hasMoreItems: true,
            groups: []
        },
        error: null,
        isLoading: false,
    }
}

const briefcase = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_DRAFT_POST[REQUEST]:
            return {
                ...state,
                draft: {
                    ...state.draft,
                    query: {
                        ...action.query,
                        hasMoreItems: false
                    },
                    posts: action.query.page > 0 ? [...state.draft.posts] : []
                }
            }

        case FETCH_REVIEW_POST[REQUEST]:
            return {
                ...state,
                review: {
                    ...state.review,
                    query: {
                        ...action.query,
                        hasMoreItems: false
                    },
                    posts: action.query.page > 0 ? [...state.review.posts] : []
                }
            }

        case FETCH_SCHEDULE_POST[REQUEST]:
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    query: {
                        ...action.query,
                        hasMoreItems: false
                    },
                    posts: action.query.page > 0 ? [...state.schedule.posts] : []
                }
            }

        case FETCH_DRAFT_POST[SUCCESS]:
            return {
                ...state,
                draft: {
                    ...state.draft,
                    query: {
                        ...state.draft.query,
                        hasMoreItems: Object.values(action.payload.posts).length ? true : false,
                        itemsperpage: action.payload.ipg,
                        page: action.payload.pg
                    },
                    posts: [...state.draft.posts, ...Object.values(action.payload.posts).reverse()],
                    error: null
                }
            }
        case FETCH_REVIEW_POST[SUCCESS]:
            return {
                ...state,
                review: {
                    ...state.review,
                    query: {
                        ...state.review.query,
                        hasMoreItems: Object.values(action.payload.posts).length ? true : false,
                        itemsperpage: action.payload.ipg,
                        page: action.payload.pg
                    },
                    posts: [...state.review.posts, ...Object.values(action.payload.posts).reverse()],
                    error: null
                }
            }

        case FETCH_SCHEDULE_POST[SUCCESS]:
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    query: {
                        ...state.schedule.query,
                        hasMoreItems: Object.values(action.payload.posts).length ? true : false,
                        itemsperpage: action.payload.ipg,
                        page: action.payload.pg
                    },
                    posts: [...state.schedule.posts, ...Object.values(action.payload.posts).reverse()],
                    error: null
                }
            }

        case FETCH_DRAFT_POST[FAILURE]:
            return {
                ...state,
                draft: {
                    ...state.draft,
                    error: action.error
                }
            }

        case FETCH_REVIEW_POST[FAILURE]:
            return {
                ...state,
                review: {
                    ...state.review,
                    error: action.error
                }
            }

        case FETCH_SCHEDULE_POST[FAILURE]:
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    error: action.error
                }
            }
        default:
            return state
    }
}
export default briefcase;