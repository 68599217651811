import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import GroupParentsDialog from './GroupParentsDialog';
import Typography from '@material-ui/core/Typography';
import { Field } from "redux-form";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { required } from '../../../component/CreatePost/reduxForm/validation'
import RenderRadioGroup from "../../common/reduxForm/RenderRadioGroup";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    textField: {
        width: '100%',
    },
    chip: {
        margin: theme.spacing(1) / 2,
    },
    parentOpenLink: {
        cursor: 'pointer'
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class GroupParentsForm extends Component {

    state = {
        groupParentSelection: '',
        name: [],
        groupName: '',
        parents: [],
        inputParentNames: [],
        inputGroupNames: [],
        selectedParents: [],
        open: false,
        groupList: []
    };

    componentDidMount() {
        if (this.props.generalFormValues.groups) {
            this.setState({ groupList: this.props.generalFormValues.groups })
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (this.props.generalFormValues.groupParentSelection !== nextProps.generalFormValues.groupParentSelection) {
            if (nextProps.generalFormValues.groupParentSelection === 'parents') {
                this.setState({ open: true })
            }

        }
        if (this.props.generalFormValues.groups !== nextProps.generalFormValues.groups) {
            this.setState({ groupList: nextProps.generalFormValues.groups })
        }
    }

    openPopUp = () => {
        return this.setState({ open: true });
    };

    handleSelectGroup = event => {
        const { groups, changeGroupList } = this.props;
        const selectedGroups = groups.filter(group => event.target.value.indexOf(group.GroupName) > -1);
        const groupList = selectedGroups.map(group => {
            return { groupId: group.groupid, groupName: group.GroupName }
        });
        changeGroupList(groupList);
    };

    handleClose = (value) => {
        this.setState({ open: false, value: value });
    };

    renderGroupSelectionOption = () => {
        const { classes, groups, generalFormValues: { groupParentSelection, parents } } = this.props;
        const { groupList: selectedGroups = [] } = this.state
        if (groupParentSelection === 'parents') {
            return (
                <div>
                    <Typography onClick={this.openPopUp} variant="h6" gutterBottom className="parentOpenLink">
                        Number of selected parents : {parents && parents.length}
                    </Typography>
                    <GroupParentsDialog
                        classes={{ paper: classes.paper }}
                        groups={groups}
                        open={this.state.open}
                        parentlist={parents || []}
                        onClose={this.handleClose} />
                </div>
            );
        } else if (groupParentSelection === 'group') {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12} className="group">
                        <FormControl component='fieldset' variant='outlined' >
                            <InputLabel>Select Group</InputLabel>
                            <Select
                                // multiple
                                value={selectedGroups.map(group => group.groupName)}
                                name='inputGroupNames'
                                label='Select group name'
                                onChange={this.handleSelectGroup}
                                className="groupSelect"
                                input={
                                    <OutlinedInput
                                        name='groupName'
                                        id='select-multiple-checkbox'
                                        labelWidth={100} />
                                }
                                renderValue={selected => selected.join(', ')}
                                MenuProps={MenuProps}>
                                {groups.map(group => (
                                    <MenuItem key={group.GroupName} value={group.GroupName}>
                                        <Checkbox color="primary" checked={selectedGroups.some(s => s.groupName === group.GroupName)} />
                                        <ListItemText primary={group.GroupName} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            );
        }
    };

    render() {
        const { classes, generalFormValues } = this.props;
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} >
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <FormLabel className="cardsubtitleText">Name of your group/parents</FormLabel>
                                <Field name="groupParentSelection" component={RenderRadioGroup} >
                                    <FormControlLabel value='group' control={<Radio color="primary" />}
                                        label='Select group' />
                                    <FormControlLabel value='parents' control={<Radio color="primary" />}
                                        label='Select parents' />
                                </Field>
                            </Grid>
                            {this.renderGroupSelectionOption()}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(GroupParentsForm);
