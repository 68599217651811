import React, { Component } from 'react'
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    backButton: {
        marginRight: theme.spacing(1),
    }
});



class NewsLetterReview extends Component {
    
    handleNext = () => {
        const { newsLetterActiveStep, activeStep } = this.props;
        newsLetterActiveStep(activeStep + 1);
    }

    handleBack = () => {
        const { newsLetterActiveStep, activeStep } = this.props;
        newsLetterActiveStep(activeStep - 1);
    }

    render() {
        const { activeStep,steps,classes } = this.props;
        return (
            <div>
                this is review page
                <div>
                    <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.backButton}
                    >
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        disabled={activeStep === steps.length - 1}
                    >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(NewsLetterReview)
