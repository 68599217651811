import {
    API
} from 'aws-amplify';
import {
    takeEvery,
    fork,
    all,
    put,
    select
} from 'redux-saga/effects'

import { reset } from "redux-form";
import { REQUEST } from '../actions/common';
import { closeDialogBox, showToastMessage } from "../actions/component";

import {
    fetchPost, FETCH_POST,
    CREATE_POST, createPost,
    EDIT_POST, editPost,
    DELETE_POST, deletePost,
    SAVE_COMMENT, saveComment,
    UPDATE_COMMENT, updateComment,
    DELETE_COMMENT, deleteComment,
    LIKE_POST, likePost,
    UNLIKE_POST, unlikePost, APPLY_POST_FILTER,
    FETCH_PINNED_POST, fetchPinnedPost, POST_VIEW, getPostView
} from '../actions/feed-post'

import {
    fetchDraftPost,
    fetchSchedulePost,
    fetchReviewPost
} from '../actions/breifcase'


function* fetchFeedPost(action) {
    try {
        const init = {
            body: action.query
        }
        const payload = yield API.post('get_posts', '', init)
        if (!payload.errorMessage) {
            yield put(fetchPost.success(payload))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchPost.failure(error))
    }
}

function* sfetchPinnedPost(action) {
    try {
        const init = {
            body: action.query
        }
        const payload = yield API.post('get_posts', '', init)
        if (!payload.errorMessage) {
            yield put(fetchPinnedPost.success(payload))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchPinnedPost.failure(error))
    }
}

function* createFeedPost(action) {
    try {
        const init = {
            body: action.query
        }

        yield API.post('add_posts', '', init)
        const query = yield select(state => state.feed.query);

        const query1 = yield select(state => state.feed.pinned.query)

        yield query.page = 0;
        yield query1.page = 0;

        yield put(createPost.success());
        if (action.query.draft_mode === 1) {
            yield put(showToastMessage({message: 'your draft post will be visible in breifcase', type: 'success'}));
        }else if(action.query && action.query.posttime){
            yield put(showToastMessage({message: 'your schedule post will be visible in breifcase', type: 'success'}));
        } else if(action && action.query && action.query.review_users && action.query.review_users.length ){
            yield put(showToastMessage({message: 'your review post will be visible in breifcase', type: 'success'}));
        }
        yield put(reset('managePost'));
        yield put(fetchPost.request(query));
        yield put(fetchPinnedPost.request(query1));

    } catch (error) {

    }
}

function* createEditPost(action) {
    try {

        const init = {
            body: action.query
        }

        const { errorMessage } = yield API.post('edit_posts', '', init)

        if (!errorMessage) {
            const groups = yield select(state => Object.values(state.currentUser.userInfo.Groups).map(group => group.groupid))
            const query = yield select(state => state.feed.query);
            const query1 = yield select(state => state.briefcase.draft.query);


            yield query.page = 0;
            yield query.groups = (!!query.groups.length) ? query.groups : groups
            yield query1.page = 0;
            yield query1.groups = (!!query1.groups.length) ? query1.groups : groups

            yield put(closeDialogBox());
            yield put(editPost.success());

            yield put(fetchPost.request({ ...query, groups: query1.groups }))
            yield put(fetchDraftPost.request(query1))
            yield put(fetchSchedulePost.request(query1))
            yield put(fetchReviewPost.request(query1))
        } else {
            const error = { message: errorMessage }
            throw error
        }

    } catch (error) {
        console.log(error.message)
    }
}

function* createDeletePost(action) {
    const init = {
        body: {
            postid: action.query.postid
        }
    }
    const query = yield select(state => state.feed.query);
    const query1 = yield select(state => state.briefcase.draft.query);
    const query2 = yield select(state => state.briefcase.schedule.query);
    const query3 = yield select(state => state.briefcase.review.query);

    yield API.post('delete_posts', '', init)
    yield put(deletePost.success(init.body))
    yield put(fetchPost.request(query))
    yield put(fetchDraftPost.request(query1))
    yield put(fetchSchedulePost.request(query2))
    yield put(fetchReviewPost.request(query3))
}

function* createSaveComment(action) {
    try {
        const init = {
            body: action.query
        }
        const user = yield select(state => state.currentUser.userInfo.email);
        const posts = yield select(state => state.feed.posts);
        const post = posts.find(post => post.postid === action.query.postid);
        const {
            data
        } = yield API.post('add_comments', '', init);
        const payload = {
            id: data,
            postid: action.query.postid,
            comment: action.query.comment,
            user,
            updatedtime: "a moment ago",
        }
        yield post.comments.unshift(payload);
        yield put(saveComment.success(post))
    } catch (error) {

    }
}

function* createUpdateComment(action) {
    try {
        const init = {
            body: action.query
        }
        const posts = yield select(state => state.feed.posts);
        const post = posts.find(post => post.postid === action.query.postid);
        const updatedComments = yield post.comments.map(comment => {
            if (comment.id === action.query.commentid) {
                comment.comment = action.query.comments;
                comment.updatedtime = "a moment ago";
            }
            return comment
        });
        post.comments = updatedComments;
        yield API.post('edit_comments', '', init);
        yield put(updateComment.success(post));
    } catch (error) {

    }
}

function* createDeleteComment(action) {
    try {
        const init = {
            body: {
                commentid: action.query.id
            }
        }
        const posts = yield select(state => state.feed.posts);
        const post = posts.find(post => post.postid === action.query.postid);

        const updatedComments = yield post.comments.filter(comment => comment.id !== action.query.id);
        post.comments = updatedComments;
        yield API.post('delete_comments', '', init);
        yield put(deleteComment.success(post))
    } catch (error) {

    }
}

function* createLikePost(action) {
    const init = {
        body: {
            postid: action.query.postid
        }
    }
    const user = yield select(state => state.currentUser.userInfo.email);
    yield put(likePost.success({
        ...init.body,
        user
    }))
    yield API.post('post_like', '', init)
}

function* createUnlikePost(action) {
    const init = {
        body: {
            postid: action.query.postid
        }
    }
    const user = yield select(state => state.currentUser.userInfo.email);
    yield put(unlikePost.success({
        ...init.body,
        user
    }))
    yield API.post('post_unlike', '', init)
}

function* fetchFeedPostWithFilter(action) {
    const query = yield select(state => state.feed.query);
    query.groups = action.payload;
    yield put(fetchPost.request(query))
}

function* feedPostView(action) {
    const init = {
        body: {
            postid: action.query.postid
        }
    }

    const posts = yield select(state => state.feed.posts);
    const post = posts.find(post => post.postid === action.query.postid);
    post.view_count++

    yield API.post('post_view', '', init);
    yield put(getPostView.success(post))
}

/** watcher  */

function* watcherPostView() {
    yield takeEvery(POST_VIEW[REQUEST], feedPostView)
}

function* watcherPostFilter() {
    yield takeEvery(APPLY_POST_FILTER, fetchFeedPostWithFilter)
}

function* watcherFetchFeedPost() {
    yield takeEvery(FETCH_POST[REQUEST], fetchFeedPost)
}

function* watcherFetchPinnedPost() {
    yield takeEvery(FETCH_PINNED_POST[REQUEST], sfetchPinnedPost)
}

function* watcherCreateFeedPost() {
    yield takeEvery(CREATE_POST[REQUEST], createFeedPost)
}

function* watcherEditPost() {
    yield takeEvery(EDIT_POST[REQUEST], createEditPost)
}

function* watcherDeletePost() {
    yield takeEvery(DELETE_POST[REQUEST], createDeletePost)
}

function* watcherSaveComment() {
    yield takeEvery(SAVE_COMMENT[REQUEST], createSaveComment)
}

function* watcherUpdateComment() {
    yield takeEvery(UPDATE_COMMENT[REQUEST], createUpdateComment);
}

function* watcherDeleteComment() {
    yield takeEvery(DELETE_COMMENT[REQUEST], createDeleteComment);
}

function* watcherLikeComment() {
    yield takeEvery(LIKE_POST[REQUEST], createLikePost);
}

function* watcherUnlikeComment() {
    yield takeEvery(UNLIKE_POST[REQUEST], createUnlikePost);
}

export default function* rootCache() {
    yield all([
        fork(watcherPostView),
        fork(watcherPostFilter),
        fork(watcherCreateFeedPost),
        fork(watcherEditPost),
        fork(watcherDeletePost),
        fork(watcherFetchFeedPost),
        fork(watcherFetchPinnedPost),
        fork(watcherSaveComment),
        fork(watcherUpdateComment),
        fork(watcherDeleteComment),
        fork(watcherLikeComment),
        fork(watcherUnlikeComment),
    ]);
}