import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import classname from "classnames";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RichTextEditor from 'react-rte';
import renderHTML from 'react-render-html';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import FileUpload from './FileUpload';
import ImageList from './ImageList';
import { Field, FieldArray, reduxForm, Form } from 'redux-form';
import {
    Button, Typography, Grid,
    TextField, MenuItem, IconButton, Popover, Icon, Dialog, Card
} from '@material-ui/core';

const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

const styles = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    padding: {
        padding: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
    paper: {
        // padding: theme.spacing(1),
    },
    icon: {
        fontSize: "smaller"
    },
});

class NewsLetterForm extends Component {

    state = {
        anchorElAdd: null,
        openDialog: false,
        content: [],
        editorState: RichTextEditor.createValueFromString('', 'html'),
        title: '',
        selectedDate: new Date()
    }

    handleNext = () => {
        const { newsLetterActiveStep, activeStep } = this.props;
        newsLetterActiveStep(activeStep + 1);
    }

    handleBack = () => {
        const { newsLetterActiveStep, activeStep } = this.props;
        newsLetterActiveStep(activeStep - 1);
    }

    handlePopoverOpen = (event) => {
        this.setState({ anchorElAdd: event.currentTarget })
    }

    handlePopoverClose = (event) => {
        this.setState({ anchorElAdd: null })
    }

    handleSelectEditorType = (type) => (e) => {
        if (type === 'TEXT') {
            this.setState({
                openDialog: true,
                anchorElAdd: null
            })
        }
    }

    handleCloseDialog = (e) => {
        this.setState({ openDialog: false })
    }

    handleSubmitText = (e) => {
        this.setState(prevState => {
            return ({
                openDialog: false,
                content: [...prevState.content, { type: 'TEXT', value: prevState.editorState.toString('html') }],
                editorState: RichTextEditor.createValueFromString('', 'html')
            })
        })
    }

    handleRemoveSelectedContent = (index) => (e) => {
        const result = this.state.content
        result.splice(index, 1)
        this.setState({ content: result })
    }

    handleTextChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    handleTextEditorChange = (value) => {
        this.setState({ editorState: value })
        const html = value.toString('html');
    }

    handleDateChange = (value) => {
        this.setState({ selectedDate: value })
    }


    render() {
        const { classes, activeStep, steps, FormValues } = this.props
        console.log(FormValues);
        // const {  img = [], vid = [], doc = [] } = FormValues;
        const { anchorElAdd, openDialog,
            content,
            editorState, title, selectedDate
        } = this.state;
        const openanchorAdd = Boolean(anchorElAdd);
        return (
            <>
                <Typography variant="h6" align="center">
                    News NewsLetter
                </Typography>
                <Form onSubmit={(this.props.handleFormSubmit)}>
                    <Grid container justify="center">
                        <Grid item>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Select"
                                className={classes.textField}
                                value={[]}
                                //onChange={handleChange('currency')}
                                SelectProps={{
                                    multiple: true,
                                    MenuProps: { className: classes.menu },
                                }}
                                helperText="Please select your currency"
                                margin="normal"
                                variant="outlined"
                            >
                                {currencies.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Select"
                                className={classes.textField}
                                value={[]}
                                //onChange={handleChange('currency')}
                                // value={values.currency}
                                SelectProps={{
                                    multiple: true,
                                    MenuProps: { className: classes.menu },
                                }}
                                helperText="Please select your currency"
                                margin="normal"
                                variant="outlined"
                            >
                                {currencies.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" >
                        <Grid container justify="flex-start" item xs={10}>
                            <DatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                label="Date"
                                helperText="No year selection"
                                className={classes.textField}
                                value={selectedDate}
                                onChange={this.handleDateChange}
                            />
                        </Grid>

                        <Grid item xs={10}>
                            <TextField
                                name="title"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                value={title}
                                className={classes.textField}
                                onChange={this.handleTextChange}
                                inputProps={{ 'aria-label': 'title' }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container  >
                        {Array.isArray(content) && content.map((item, index) => (
                            <Grid container item key={index} justify="center" >
                                <Grid item xs={9}   >
                                    <Card className={classname(classes.margin, classes.padding)}>
                                        <span>
                                            {renderHTML(item.value)}
                                        </span>

                                        {/* <FieldArray name="img" component={ImageList} list={img} /> */}
                                    </Card>
                                </Grid>
                                <Grid item xs={1} key={index}>
                                    <IconButton aria-label="Add" className={classes.margin} onClick={this.handleRemoveSelectedContent(index)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container>
                        <Grid item>
                            <IconButton aria-label="Add" className={classes.margin} onClick={this.handlePopoverOpen}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                            <>
                                <Popover
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                    classes={{
                                        paper: classes.paper,
                                    }}
                                    open={openanchorAdd}
                                    anchorEl={anchorElAdd}
                                    onClose={this.handlePopoverClose}

                                >
                                    <Grid container>
                                        <Grid item>
                                            <IconButton aria-label="text" className={classes.margin} onClick={this.handleSelectEditorType('TEXT')} >
                                                <i className={classname("fas fa-pencil-alt")} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item onClick={this.handleSelectEditorType('IMG')}>
                                            <Field
                                                name="img"
                                                component={FileUpload}
                                                label="Attach Image"
                                                type="file"
                                                accept="image/*"
                                                imagePrev={true}
                                                multiple={false}
                                                icon={<i className={classname("fas fa-camera")} />}
                                            />
                                        </Grid>
                                        <Grid item onClick={this.handleSelectEditorType('VIDEO')}>
                                            <Field
                                                name="vid"
                                                component={FileUpload}
                                                label="Attach Video"
                                                type="file"
                                                accept={[".mp4", ".mkv", ".avi"]}
                                                imagePrev={true}
                                                multiple={false}
                                                icon={<i className={classname("fas fa-video")} />}
                                            />
                                        </Grid>
                                        <Grid item onClick={this.handleSelectEditorType('DOC')}>
                                            <Field
                                                name="misc"
                                                component={FileUpload}
                                                label="Attach Doc"
                                                type="file"
                                                accept={[".pdf"]}
                                                imagePrev={true}
                                                multiple={false}
                                                icon={<i className={classname("fas fa-file-upload")} />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popover>
                            </>
                        </Grid>
                    </Grid>



                    <Grid container justify="space-around">
                        <Button
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.backButton}
                        >
                            Back
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleNext}
                            disabled={activeStep === steps.length - 1}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Grid>
                </Form>
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={openDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="text-dialog-title"
                    fullWidth={true}
                >
                    <Grid container className={classes.padding} >
                        <Grid container justify="flex-end" item xs={12} >
                            <Button
                                component="span"
                                className={classes.margin}
                                onClick={this.handleSubmitText}>
                                Done
                            </Button>
                        </Grid>
                        <Grid item >
                            <RichTextEditor
                                row={5}
                                value={editorState}
                                onChange={this.handleTextEditorChange}
                            />

                        </Grid>
                    </Grid>
                </Dialog>
            </>
        )
    }
}

const wrapperForm = reduxForm({
    form: 'manageUpload',
})(NewsLetterForm)

const styleWrapper = withStyles(styles)(wrapperForm);

export default styleWrapper

