import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    card: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
        // marginBottom: theme.spacing(1.5),
    },
}));


function HorizontalStepper(props) {
    const classes = useStyles();
    const { activeStep, children, steps } = props;

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Card className={classes.card}>
                {children}
            </Card>
        </div>
    );
}

export default HorizontalStepper
