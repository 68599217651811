import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Storage } from "aws-amplify";
import Popover from '@material-ui/core/Popover';
import renderHTML from 'react-render-html';
import Moment from 'react-moment';
import Chip from '@material-ui/core/Chip';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import CalImg from "../../../assets/images/calendar-2.png";
import AlarmImg from "../../../assets/images/alarm-clock.png";
import LocationImg from "../../../assets/images/location.png";
import GroupImg from "../../../assets/images/group.png";
import * as Route from "../../../routes/path";
import { toDataURL } from "../../../utils/utils";
import { EVENT_TYPES } from "../../../shared/enums";

import withDialog from "../../common/hoc/Dialog";
import RsvpResponse from "../RsvpAttend";
import AsyncImage from "../../common/reduxForm/AsyncImage";
import PreviewMeeting from "../../Event/MeetingDateTime/PreviewMeeting";
import studentService from "../../../service/student";
import { Button } from "@material-ui/core";
import PreviewVolunteer from "../Volunteer/PreviewVolunteer";
import TransferDialog from './TransferDialog';

const styles = theme => ({
  root: {
    flexGrow: 1
  },

  titleImage: {
    // width: "100%",
    objectFit: "cover",
  },
  dialogTitle: {
    boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
    padding: 0,
    height: '280px'
  },
  headerBtn: {
    margin: 'auto 0',
    color: '#05b9a4'
  },
  moreSubTitle: {
    padding: 10,
    textAlign: 'center',
    color: '#3c3989',
    cursor: 'pointer'
  },
  title: {
    fontSize: '40px',
    color: '#3f51b5',
  },
  location: {
    alignItems: 'center',
    marginTop: '25px'
  },
  alignment: {
    marginBottom: '20px',
  },
  clockImg: {
    height: '30px',
    width: '30px',
    float: 'left',
    margin: '5px'
  },
  description: {
    border: '1px solid #e2e2e2',
    borderRadius: '5px',
    padding: '8px',
    marginBottom: '20px'
  }
});

const other = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  }
}

class EventPreview extends Component {
  state = {
    open: false,
    openReminder: false,
    anchorEl: null,
    attachment: [],
    loggedinChildList: [],
    anchorElOpenMore: null,
    openTransfer: false,
    transferEmail: ''
  };

  componentDidMount() {
    const { fetchEventResponseAction, event } = this.props;
    const eventType = (event && event.event_type)
    if (eventType === 2 || eventType === 3 || eventType === 4) {
      fetchEventResponseAction.request({ event_id: event.event_id })
    }
    this.getLoggedinStudentList();
  }

  async getLoggedinStudentList() {
    try {
      const result = await studentService.getChildOfUser();
      this.setState({ loggedinChildList: result.data })
    } catch (error) {
      console.log('error', error)
    }
  }

  handleClickButton = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  };

  handleManage = () => {
    this.props.setInitialValue(this.props.event);
    this.props.closeDialogAction();
    if (this.props.event.event_type === 1) {
      this.props.history.push(Route.MANAGE_EVENT);
    } else if (this.props.event.event_type === 2) {
      this.props.history.push(Route.EDIT_RSVP_EVENT + '/' + this.props.event.event_id);
    } else if (this.props.event.event_type === 3) {
      this.props.history.push(Route.MEETING_EVENT_SIGNUP + '/' + this.props.event.event_id);
    } else if (this.props.event.event_type === 4) {
      this.props.history.push(Route.VOLUNTEER_EVENT_SIGNUP + '/' + this.props.event.event_id);
    }
  }

  handleCopy = () => {
    delete this.props.event.event_id;

    this.props.setInitialValue(this.props.event);
    if (this.props.event.event_type === 1) {
      this.props.history.push(Route.MANAGE_EVENT);
    } else if (this.props.event.event_type === 2) {
      this.props.history.push(Route.MANAGE_RSVP_EVENT);
    } else if (this.props.event.event_type === 3) {
      this.props.history.push(Route.MEETING_EVENT);
    } else if (this.props.event.event_type === 4) {
      this.props.history.push(Route.VOLUNTEER_EVENT);
    }
  }

  handleReminder = (event) => {
    this.setState({
      openReminder: true,
      anchorEl: event.currentTarget
    });
  };

  handleDownload = async (file) => {
    const dataUrl = await Storage.get(file.key, { level: "public" });

    toDataURL(dataUrl, (dataUrl) => {
      const a = document.createElement("a");
      a.href = dataUrl;
      a.setAttribute("download", file.name);
      const b = document.createEvent("MouseEvents");
      b.initEvent("click", false, true);
      a.dispatchEvent(b);
      return false;
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleReminderClose = () => {
    this.setState({
      openReminder: false,
    });
  };

  handleDeleteEvent = () => {
    const { event, deleteEventAction } = this.props
    deleteEventAction.request({ event_id: event.event_id })
  }

  handleMoreClick = (event) => {
    this.setState({
      anchorElOpenMore: event.currentTarget
    });
  }

  handleMoreClose = () => {
    this.setState({
      anchorElOpenMore: null
    });
  }

  handleTransferOpen = () => {
    this.setState({
      openTransfer: true,
      anchorElOpenMore: null
    });
  }

  handleTransferClose = () => {
    this.setState({
      openTransfer: false,
    });
  }

  handleTransferOk = (value) => {
    // email validation
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
      const { transferOwnerShip, event } = this.props;
      const body = {
        eventId: event.event_id,
        email: value
      }
      transferOwnerShip.request(body);
      this.setState({
        openTransfer: false,
        transferEmail: value
      });
    } else {
      this.setState({
        transferEmail: ''
      });
      console.log('invalid email');
    }

  }

  componentWillUnmount(){
    this.props.newDataSet("off")
  }

  render() {
    const { classes, event, userInfo, sendRsvpEventResponseAction } = this.props;
    const {
      open,
      anchorEl,
      anchorElOpenMore,
      openReminder,
      openTransfer,
      transferEmail
    } = this.state;

    const openMore = Boolean(anchorElOpenMore);
    const moreId = open ? 'simple-popover' : undefined;

    const isOwner = event.user === userInfo.Username || userInfo.role === 'admin' || userInfo.role === 'teacher'
    const isRsvp = String(event.event_type) === EVENT_TYPES.RSVP_Event
    const isMeeting = String(event.event_type) === EVENT_TYPES.Parent_Teacher_Event
    const isVolunteer = String(event.event_type) === EVENT_TYPES.Volunteer_Event

    // const remindersArray = [
    //   { name: 'notifyOneDayAgo', status: event.notifyOneDayAgo },
    //   { name: 'notifyTwoDayAgo', status: event.notifyTwoDayAgo },
    //   { name: 'notifyThreeDayAgo', status: event.notifyThreeDayAgo },
    // ]

    // const reminders = remindersArray.filter(notify => notify.status === 1 || notify.status === true)

    return (
      <div className={classes.root}>
        <DialogTitle id="confirmation-dialog-title" className={classes.dialogTitle}>
          <AsyncImage className={classes.titleImage} src={event.img_url[0].key} />
        </DialogTitle>

        <DialogContent style={{ overflowY: "hidden" }} >
          <div >
            <Grid item xs={12}>

              <Grid container justify="space-between" style={{ margin: '10px 0' }}>
                <Grid style={{ marginBottom: '5px' }}>
                  <span className={classes.title}> {event.eventname} </span>
                </Grid>
                <Grid>
                  <Grid>
                    {isOwner && (
                      <Button
                        color="primary"
                        className={classes.headerBtn}
                        onClick={this.handleManage}>Manage</Button>)}
                  </Grid>
                  <Popover
                    id={moreId}
                    open={openMore}
                    anchorEl={anchorElOpenMore}
                    onClose={this.handleMoreClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Grid container direction="column">
                      <span className={classes.moreSubTitle} onClick={this.handleDeleteEvent}                  >
                        Delete Event
                      </span>
                      <span className={classes.moreSubTitle} onClick={this.handleCopy}>
                        Copy Event
                      </span>
                      <span className={classes.moreSubTitle} onClick={this.handleTransferOpen}>
                        Transfer Ownership
                      </span>
                    </Grid>
                  </Popover>

                  <TransferDialog
                    open={openTransfer}
                    value={transferEmail}
                    onClose={this.handleTransferClose}
                    onTransfer={this.handleTransferOk}
                  />

                  <Grid>
                    {isOwner &&
                      (<Button
                        className={classes.headerBtn}
                        onClick={this.handleMoreClick}
                      >
                        More
                  </Button>)
                    }
                  </Grid>
                </Grid>
              </Grid>


              <div>
                <div>
                  {event.groups && !!event.groups.length && (<Popover
                    id="render-props-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    {...other}
                    className="groupPopper"
                  >
                    <Typography
                      color="primary"
                      variant="h6"
                      className="eventDialogSubTitle"
                    >
                      <p>
                        {event.groups && event.groups.map((group, key) => (
                          <span
                            className="groupChip"
                            key={group.groupId}
                          >
                            {group.groupName}
                          </span>
                        ))}
                      </p>
                    </Typography>
                  </Popover>)}

                    <Grid container justify="space-around" className={classes.alignment} >
                        <div>
                            <img className={classes.clockImg}
                                 src={CalImg}
                                 alt="previcon"
                            />
                            <p className="timeSection">
                                <Moment format="ddd D, MMMM YYYY">
                                    {event.start_date}
                                </Moment>
                                <br />
                                <Moment format="ddd D, MMMM YYYY">
                                    {event.end_date}
                                </Moment>
                            </p>
                        </div>
                        <div>
                            <img className={classes.clockImg}
                                 alt="previcon"
                                 src={AlarmImg}
                            />
                            <p className="timeSection">
                                <Moment format="h:mm a">
                                    {event.start_time}
                                </Moment>
                                <br />
                                <Moment format="h:mm a">
                                    {event.end_time}
                                </Moment>
                            </p>
                        </div>
                        <div className="eventDialogcontent centerContent rsvp-around box">
                            <div>
                                <img
                                    className="prevIcon1"
                                    alt="previcon"
                                    src={GroupImg}
                                    style={{ marginRight: '5px', height: "20px", position: "relative", cursor: "pointer" }}
                                    onClick={this.handleClickButton}
                                />
                                {event.groups && !!event.groups.length &&
                                <p className="locationSection" >
                                    {event.groups.length} Groups
                                </p>
                                }
                                {event.parents && !!event.parents.length &&
                                <p className="locationSection" >
                                    {event.parents.length} Invitation
                                </p>
                                }
                            </div>
                        </div>
                        {event.location &&
                        <div>
                            <img
                                className="prevIcon1"
                                src={LocationImg}
                                alt="previcon"
                                style={{ height: "20px" }}
                            />
                            <p className="locationSection" >
                                {event.location}
                            </p>
                        </div>}
                    </Grid>

                  {event.eventdesc && <div className={classes.description}> {renderHTML(event.eventdesc)} </div>}
                </div>

                {isRsvp &&
                  <RsvpResponse
                    event={event}
                    sendRsvpEventResponseAction={sendRsvpEventResponseAction}
                  />
                }

                {isMeeting &&
                  <PreviewMeeting
                    event={event}
                    readOnly={false}
                    data={this.state.loggedinChildList}
                    setEventResponse={this.props.setEventResponse}
                    eventResponse={this.props.eventResponse}
                    email={userInfo.email}
                    removeChildEventAction={this.props.removeChildEventAction}
                  />}

                {isVolunteer &&
                  <PreviewVolunteer
                    event={event}
                    readOnly={false}
                    data={this.state.loggedinChildList}
                    setEventResponse={this.props.setEventResponse}
                    eventResponse={this.props.eventResponse}
                    email={userInfo.email}
                    removeChildEventAction={this.props.removeChildEventAction}
                  />}
                {(event.attachments && !!event.attachments.length) && (
                  <div className="eventDialogcontent eventContainer">
                    <strong className="note">Attachments</strong>
                    <br />
                    <div className="customRow">
                      {event.attachments && event.attachments.map((attachment, key) => (
                        <Chip
                          key={key}
                          label={attachment.name}
                          color="primary"
                          variant="outlined"
                          onClick={e => { this.handleDownload(attachment) }}
                        />
                      ))}
                    </div>
                  </div>
                )}

              </div>
            </Grid>
          </div>
        </DialogContent>
      </div >
    );
  }
}


EventPreview.propTypes = {
  classes: PropTypes.object.isRequired,
};

const WrapperStyle = withStyles(styles)(EventPreview);

export default withDialog(WrapperStyle) 