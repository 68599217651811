import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles({
    center: {
        textAlign: 'center',
    },
    color: {
        color: '#3f51b5',
    }
});
const emails = ['username@gmail.com', 'user02@gmail.com'];
function TransferDialog(props) {
    const classes = useStyles();
    const { onClose,onTransfer, value, ...other } = props;
    const [input, setInput] = React.useState('');
    function handleClose() {
        onClose();
    }

    function handleTransferOk(){
        onTransfer(input)
    }

    function handleChange(e){
        setInput(e.target.value)
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="transfer-dialog-title" {...other}>
            <DialogTitle id="transfer-dialog-title" className={classes.center}>
                <span className={classes.color}> Transfer Ownership: </span>
            </DialogTitle>
            <DialogContent className={classes.center}>
                <DialogContentText className={classes.color}>
                    Are you sure you want to transfer to another organizer ?
            </DialogContentText>
                <Grid container justify="center" style={{ marginBottom: '16px' }}>
                    <Typography variant="subtitle1" className={classes.color} >
                        New Organizer Mail:
                    </Typography>
                    <input
                        className={classes.textField}
                        variant="outlined"
                        onChange={ handleChange } 
                    />
                </Grid>
                <DialogContentText className={classes.color}>
                    Please note that the new organizer must be a registered person on the 360 Platform at your school.
            </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-around' }}>
                <Button onClick={handleClose} color="primary">
                    Cancel
          </Button>
                <Button onClick={handleTransferOk} color="primary">
                    Transfer
          </Button>
            </DialogActions>
        </Dialog>
    );
}

TransferDialog.propTypes = {
    onClose: PropTypes.func,
    onTransfer: PropTypes.func,
    open: PropTypes.bool,
    value: PropTypes.string,
};

export default TransferDialog;