import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import blue from '@material-ui/core/colors/blue';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { Checkbox, ListItemText, InputLabel } from '@material-ui/core';
import studentService from '../../service/student';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 300,
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FilterDialog(props) {
  const classes = useStyles();
  const {
    closeDialogAction,
    dialogProps,
    currentUser: { email, role },
    GroupOptions: options,
    filterpost: {
      filtertype,
      groups,
      childs
    }
  } = props;
  const { open, typeOfDialog } = dialogProps;
  const [state, setState] = React.useState({ child: [], filterchild: [] })


  React.useEffect(() => {
    getStudentList()
  }, [])

  async function getStudentList() {
    try {
      const { data } = await studentService.getStudentList('all');
      let child = await data.map(child => {
        return {
          ...child,
          label: `${child.firstname} ${child.lastname}`
        }
      })
      if (role !== 'admin') {
        child = child.filter(item => item.parents.some(parent => parent === email))
      }

      setState({ ...state, child, filterchild: child })
    } catch (error) {

    }
  }

  function filterChildByGroup(value) {
    if (value.length === 0) {
      setState({ ...state, filterchild: state.child });
    } else {
      const filtergroup = getGroupIDByName(value, options, 'value');
      const data = state.child && state.child.filter(child => {
        const result = _.intersection(child.groups, filtergroup)
        return (result.length > 0) ? true : false
      })
      setState({ ...state, filterchild: data });
    }
  }

  function getGroupIDByName(selectitem, orginalArray, key) {
    let filtergroup = [];
    selectitem.forEach(element => {
      const result = orginalArray.find(option => element === option.label)
      filtergroup.push(result[key])
    });
    return filtergroup
  }

  function handleClose() {
    closeDialogAction();
  }

  function handleRadioChange(event) {
    props.handleFilterRadioChangeAction(event.target.value)
  }

  function handleGroupChange(event) {
    props.handleFilterSelectGroupAction(event.target.value);
    props.handleFilterSelectChildAction([]);
    filterChildByGroup(event.target.value);
  }

  function handleChildChange(event) {
    props.handleFilterSelectChildAction(event.target.value);
  }

  function handleDoneClick(event) {
    const filtergroup = getGroupIDByName(groups, options, 'value');
    if (filtergroup.length > 0) {
      props.applyPostFilterAction(filtergroup);
      closeDialogAction();
    } else {
      closeDialogAction();
    }
  }

  function handleResetFilter() {
    const result = options.map(element => element.value)
    closeDialogAction();
    props.applyPostFilterAction(result);
    props.handleFilterSelectGroupAction([]);
    props.handleFilterSelectChildAction([]);
  }

  return (
    <>
      {typeOfDialog === "OPEN_POST_FILTER" &&
        (
          <Dialog
            disableEscapeKeyDown
            disableBackdropClick
            open={open}
            fullWidth={true}
            maxWidth="sm"
            onClose={handleClose}
            scroll="body"
            aria-labelledby="simple-dialog-title"
          >
            <DialogTitle >
              <Grid container
                justify="space-between">
                <span style={{ fontSize: '28px', fontWeight: 'bold' }}>Filter</span>
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  name="filtertype"
                  className={classes.group}
                  value={filtertype}
                  onChange={handleRadioChange}
                >
                  <Grid container>
                    <FormControlLabel value="group" control={<Radio color="primary" />} label="Group" />
                    <FormControlLabel value="student" control={<Radio color="primary" />} label="Tagged Students" />
                  </Grid>
                </RadioGroup>
              </FormControl>

              <Grid container>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-group">Group</InputLabel>
                    <Select
                      style={{ width: 200, maxWidth: '350px' }}
                      multiple
                      value={groups}
                      onChange={handleGroupChange}
                      input={<BootstrapInput id="select-multiple-group" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {Array.isArray(options) &&
                        options.map((options, index) => (
                          <MenuItem key={index} value={options.label}>
                            <Checkbox checked={groups.indexOf(options.label) > -1} />
                            <ListItemText primary={options.label} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {filtertype === 'student' &&
                  (<Grid item>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="select-multiple-child">Child</InputLabel>
                      <Select
                        style={{ width: 200, maxWidth: '350px' }}
                        multiple
                        value={childs}
                        onChange={handleChildChange}
                        input={<BootstrapInput id="select-multiple-child" />}
                        renderValue={selected => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {Array.isArray(state.filterchild) &&
                          state.filterchild.map((options, index) => (
                            <MenuItem key={index} value={options.label}>
                              <Checkbox checked={childs.indexOf(options.label) > -1} />
                              <ListItemText primary={options.label} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>)
                }

              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleResetFilter} color="primary">
                Reset
              </Button>

              <Button onClick={handleDoneClick} color="primary">
                Done
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
}

FilterDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string,
};

export default FilterDialog

