import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import AsyncImage from "../../common/reduxForm/AsyncImage";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { split } from 'lodash'
import Table from '@material-ui/core/Table';
import Icon from '@material-ui/core/Icon';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SignupDialog from './SignupDialog';
import * as Route from "../../../routes/path";
import studentService from "../../../service/student";
import Moment from "react-moment";
import { Tooltip, Avatar, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import { Popconfirm } from "antd";
import renderHTML from 'react-render-html';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    cover: {
        height: 400,
        margin: "-50px 0px",
    },
    sidebarNotes: {
        margin: '10px',
        border: '1px solid',
        width: '92%',
        padding: '10px',
        minHeight: '130px'
    },
    sidebarSection: {
        margin: '10px',
    },
    control: {
        marginTop: 10,
        minHeight: 250
    },
    centerContent: {
        width: "100%",
        margin: "20px auto",
    },
    dateTimeCard: {
        padding: "0 0 20px 0",
        boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
        borderRadius: "12px"
    },
    dateTitme: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#05b9a4",
        padding: "10px 10px 10px 40px"
    },
    table: {
        minWidth: 620,
        // margin: "10px",
        // width: "95% !important"
    },
    customthead: {
        fontSize: 16
    },
    avatar: {
        float: "left",
        margin: "2px",
        fontSize: "14px",
        height: "20px",
        width: "20px",
    },
    innercell: {
        // borderBottom: "0px"
        width: "200px",
        padding: "5px"
    }
});

function isSlotFull(userResponse, slotData) {
    const isFull = userResponse.find(res => res.slotId === slotData.slotId)
        ? userResponse.find(res => res.slotId === slotData.slotId)
            .childs.length > 0 : false

    return isFull
}

class SignupParentTeacher extends Component {

    state = {
        image: "https://picsum.photos/200/300/?blur",
        value: 0,
        event_id: '',
        dates: [],
        open: false,
        anchorEl: null,
        childList: []
    }

    componentDidMount() {
        this.setEventId();
        this.props.resetStepperActiveStep();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { events } = newProps;
        if (events && events.length) {
            if (events[0].groups && events[0].groups.length) {
                const body = {
                    groupids: [...events[0].groups.map(group => group.groupId)]
                }
                this.getStudentListForGroups(body)
            } else if (events[0].parents && events[0].parents.length) {
                const body = {
                    users: [...events[0].parents.map(parent => parent.username)]
                }
                this.getChildrenForTeacherGroups(body)
            }
        }
    }

    setEventId() {
        const event_id = split(this.props.location.pathname, '/')[3];
        this.props.getEventById.request({ event_id });
        this.props.fetchEventResponseAction.request({ event_id })
        this.setState({ event_id })
    }

    async getStudentListForGroups(body) {
        try {
            const result = await studentService.getChildrenForGroups(body)
            this.setState({ childList: result.data })
        } catch (error) {
            console.log('error', error)
        }
    }

    async getChildrenForTeacherGroups(body) {
        try {
            const result = await studentService.getChildrenForTeacherGroups(body)
            this.setState({ childList: result.data })
        } catch (error) {
            console.log('error', error)
        }
    }

    getStripedStyle = (index) => {
        return { background: index % 2 ? '#f4f4f4' : 'white' };
    }

    editDetails = () => {
        this.props.setInitialValue(this.props.events[0])
        this.props.history.push(Route.MEETING_EVENT);
    }

    removeChild = (child, time) => (e) => {
        const { removeChildEventAction, events } = this.props;
        const body = {
            slotId: time.slotId,
            child_id: child.childid,
            eventid: events[0].event_id,
        }
        removeChildEventAction.request(body)
    }

    assingChild = (slotId) => (e) => {
        this.setState({ open: true, slotId });
    }

    handleDeleteSlot = (slotId) => (e) => {
        const { deleteTimeSlot, events } = this.props;
        const body = {
            eventid: events[0].event_id,
            slotId,
        }
        deleteTimeSlot.request(body);
    }

    onClose = (value) => {
        this.setState({ open: false });
        const { setEventResponse, events } = this.props;
        if (value) {
            const body = {
                eventid: events[0].event_id,
                event_type: events[0].event_type,
                slotId: this.state.slotId,
                child_id: value.childid
            }
            setEventResponse.request(body);
        }
    }

    render() {
        const { classes, events, eventResponse: { userResponse = [] } } = this.props;
        const { open } = this.state;
        const event = events[0];
        return (
            <div className={classes.root} >
                {event &&
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={12} className={classes.control}>
                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <Card className="cardComponent">
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} className="centerdiv">
                                                <AsyncImage className={classes.cover} src={event.img_url[0].key} />
                                            </Grid>
                                            <Grid item xs={12} className={classes.sidebarSection}>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    className="meetingDialogTitle"
                                                >
                                                    {event.eventname}
                                                </Typography>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    className="meetingDialogSubTitle"
                                                >
                                                    Location - {event.location}
                                                </Typography>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    className="meetingDialogSubTitle"
                                                >
                                                    Notes -
                                                </Typography>
                                                <div className={classes.sidebarNotes}>
                                                    {event.eventdesc && <div className={classes.description}> {renderHTML(event.eventdesc)} </div>}
                                                </div>
                                                {/* <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    className="meetingDialogTitleTwo"
                                                    style={{ marginBottom: 30 }}
                                                >
                                                    Summary
                                                </Typography> */}
                                                {/* <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    className="meetingDialogSummary"
                                                >
                                                    10% Filled
                                                </Typography> */}
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    className="editMeetingbutton meetingEditButton"
                                                    value="yes"
                                                    onClick={this.editDetails}>
                                                    Edit Details
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography
                                        color="primary"
                                        variant="h6"
                                        className="meetingDialogTitle"
                                    >
                                        Conference Spots Summary
                                        </Typography>

                                    {event.parentTeacherResponse.map((date, index) => (
                                        <Grid container key={index}>
                                            <Grid item xs={12} className={classes.centerContent}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={12} className="centerdatetime">
                                                        <Card className={classes.dateTimeCard}>
                                                            <Grid container>
                                                                <Grid item xs={4} className={classes.dateTitme}>
                                                                    <Moment format="ddd D, MMMM YYYY" date={date.event_date} />
                                                                </Grid>
                                                            </Grid>
                                                            <Table className={classes.table} fixedheader="false" style={{ width: "auto", tableLayout: "auto" }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={classes.customthead}>Time</TableCell>
                                                                        <TableCell className={classes.customthead}>Filled</TableCell>
                                                                        <TableCell className={classes.customthead}>Participant</TableCell>
                                                                        <TableCell className={classes.customthead}>Action</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {date.slotTime.map((time, index) =>
                                                                        <TableRow key={index} style={{ ...this.getStripedStyle(index) }}>
                                                                            <TableCell className={classes.innercell}>
                                                                                <Moment format="hh:mm a" date={time.start_time} />
                                                                                -
                                                                                <Moment format="hh:mm a" date={time.end_time} />

                                                                            </TableCell>
                                                                            <TableCell className={classes.innercell} >
                                                                                {`${userResponse.find(res => res.slotId === time.slotId)
                                                                                    ? userResponse.find(res => res.slotId === time.slotId)
                                                                                        .childs.length : 0} Out of 1`}
                                                                            </TableCell>
                                                                            <TableCell className={classes.innercell} >
                                                                                <div style={{ display: "flex" }}>
                                                                                    {userResponse.find(res => res.slotId === time.slotId)
                                                                                        && userResponse.find(res => res.slotId === time.slotId)
                                                                                            .childs.map((child, index) =>
                                                                                                (
                                                                                                    <div key={child.childid} style={{ display: "flex" }}>
                                                                                                        <Tooltip title={`${child.firstname} ${child.lastname}`}>
                                                                                                            <Avatar >
                                                                                                                {child.firstname[0].toUpperCase()}
                                                                                                            </Avatar>
                                                                                                        </Tooltip>
                                                                                                        <Popconfirm
                                                                                                            title="Are you sure delete this child?"
                                                                                                            onConfirm={this.removeChild(child, time)}
                                                                                                            okText="Yes"
                                                                                                            cancelText="No"
                                                                                                        >
                                                                                                            <i className="fas fa-minus-circle removeicon" />
                                                                                                        </Popconfirm>
                                                                                                    </div>
                                                                                                ))
                                                                                    }
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell className={classes.innercell} >
                                                                                <Tooltip title="Assign">
                                                                                    <IconButton
                                                                                        aria-label="Edit"
                                                                                        size="small"
                                                                                        onClick={this.assingChild(time.slotId)}
                                                                                        disabled={isSlotFull(userResponse, time)}
                                                                                    >
                                                                                        <EditIcon fontSize="small" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Popconfirm
                                                                                    title="Are you sure delete this slot?"
                                                                                    onConfirm={this.handleDeleteSlot(time.slotId)}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <Tooltip title="Delete">
                                                                                        <IconButton
                                                                                            aria-label="Delete"
                                                                                            size="small"
                                                                                        >
                                                                                            <DeleteIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Popconfirm>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                <SignupDialog
                    open={open}
                    onClose={this.onClose}
                    data={this.state.childList}
                />
            </div >
        );
    }
}

export default withStyles(styles)(SignupParentTeacher);
