import React from 'react';
import { Paper, Grid, Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ImagePreview from '../FeedCard/ImagePreview'
import TextEllipses from '../TextEllipses/TextEllipses';
import PopOverMenu from '../PopOverMenu/PopOverMenu';
import VideoPlayer from '../VideoPlayer';
import { POST_TYPE } from '../CreatePost/reduxForm/context';
import userAvatar from '../../assets/images/user-avtar.png';
import moment from 'moment';
import AccessTime from "@material-ui/icons/AccessTime";
import Moment from "../CreatePost/PostForm";


const useStyles = makeStyles(theme => ({
    cardContent: {
        margin: `${theme.spacing(1) * 3}px ${theme.spacing(1) * 1}px`,
        borderRadius: '15px',
        boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(1) * 1,
        position: 'relative',
        // Selects every two elements among any group of siblings.
        '&:first-child(2n)': {
            marginTop: 0
        },
    },
    cardDetail: {
        display: 'inline-block',
    },
    postAuthor: {
        fontWeight: 700
    },
    alignActionWrp: {
        padding: '8px 0 0 0',
        alignItems: 'center',
        marginBottom: '30px'
    },
    cardDescription: {
        padding: `${theme.spacing(1) / 2}px ${theme.spacing(1) * 1}px`,
    },
    padding: {
        padding: theme.spacing(1) * 2,
    },
    image: {
        width: 128,
        height: 100,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    absoluteIcon: {
        position: "absolute",
        top: "-9px",
        left: "-9px",
        color: '#6593F5',
    },
    actionImage: {
        height: '20px',
        marginRight: '2px',
        marginBottom: '2px',
    },
    likeImage: {
        padding: '12px 5px'
    },
    cardTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '13px',
        fontWeight: 'normal'
    },
    commentImage: {
        margin: '0 5px'
    },
    likeArea: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    p12: {
        padding: '12px'
    },
    avatar: {
        backgroundColor: red[500],
    },
    btnPublish: {
        padding: '4px 12px',
        borderRadius: '27px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#3cc89c !important'
    },
    name: {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    timeago: {
        fontSize: '14px',
        color: '#cecece'
    },
    wordWrap: {
        marginLeft: '2px'
    }
}));

function BriefCaseCard(props) {
    const classes = useStyles();
    const { data, showPopOver, isPublish, isEditOrDelete, type, currentUser, userList } = props;

    const { images, user, timeago, groupname, posttext, videos, post_reviews, posttime } = data;
    const Images = images.map(image => image.normal);
    const isPostOwner = showPopOver && isEditOrDelete;

    const postReview = post_reviews && post_reviews.length && post_reviews.filter(p => p !== currentUser.userInfo.Username)

    const obj = data

    function handleEditPost() {
        const data = {
            postid: obj.postid,
            groupids: obj.group_id,
            post: obj.posttext,
            img: [...obj.images.map(image => image.normal)],
            vid: [...obj.videos],
            posttype: obj.draftflag === 1 ? POST_TYPE[1].value : POST_TYPE[0].value,
            review_users: Boolean(obj.reviewflag) ? [...obj.post_reviews] : [],
            review_mode: Boolean(obj.reviewflag),
            childids: Array.isArray(obj.children) ? obj.children.map(child => child.id) : []
        }

        if (data.childids.length > 0) {
            data.posttype = POST_TYPE[2].value
        }

        props.formInitializeAction('editEvent', data)
        props.openDialogAction('EDIT_POST_FORM')
    }

    function handleDeletePost() {
        props.deletePostAction.request(data);
    };

    function handlePublishClick(event) {
        /*    const body1 = {
               groupids: [groupids],
               post,
               img: [...img],
               vid: [...vid],
               posttime,
               review_mode: 0,
               review_users: [],
               pinned_post: 0,
               pinned_topic: "",
               draft_mode: 0,
               childids: []
           } */

        const body = {
            postid: obj.postid,
            groupids: [obj.group_id],
            post: obj.posttext,
            img: obj.images,
            vid: obj.videos,

            draft_mode: obj.draftflag,
            review_mode: obj.reviewflag,
            review_users: obj.post_reviews,
            pinned_mode: obj.pinnedflag,
            pinned_topic: obj.pinned_topic,
            pinned_until: obj.pinned_until,
            posttime: ""
        }

        switch (type) {
            case 'draft':
                body.draft_mode = 0
                props.editPostAction.request(body)
                break;
            case 'review':
                body.review_mode = 0
                props.editPostAction.request(body)
                break;
            case 'schedule':

                props.editPostAction.request(body)
                break;

            default:
                return { obj }
        }
    }

    return (
        <Paper square={true} className={classes.cardContent}>

            <Grid container>

                <Grid item container alignItems="center" >
                    <Grid item>
                        {
                            user && user.profile_photo === "<DEFAULT>" ?
                                <Avatar xs={2}
                                        aria-label="Recipe"
                                        className={classes.avatar}
                                        src={userAvatar}/> :
                                <Avatar xs={2}
                                        aria-label="Recipe"
                                        className={classes.avatar}
                                        src={user.profile_photo}>
                                    {user.display_name[0]}
                                </Avatar>
                        }
                    </Grid>
                    <Grid item xs={9} className={classes.wordWrap} container justify="space-between">
                        <Grid item>
                            <b>
                                <b>
                                    <span className={classes.name}>{user.display_name}</span>
                                    <span className={classes.cardTitle}> posted to  </span>
                                    <span className={classes.name}> {groupname} </span>
                                </b>
                            </b>
                            <Typography>
                                <span className={classes.timeago}>{timeago}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {isPostOwner &&
                            <PopOverMenu
                                icon={<MoreVertIcon />}
                                handleEditPost={handleEditPost}
                                handleDeletePost={handleDeletePost}
                            />
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography component="div" className={classes.cardDescription}>
                        <TextEllipses lines={'3'} text={posttext} />
                    </Typography>
                </Grid>

                <Grid item container justify="flex-start">
                    {!!Images.length && (<ImagePreview Images={Images} data={data} />)}
                    <br />
                    {!!videos.length && videos.map(video =>
                        (<div style={{width: "75%"}}>
                            <VideoPlayer
                                key={video}
                                posterUrl=""
                                videoUrl={video}
                            /><br/>
                        </div>))}
                </Grid>

                { type === "schedule" ?
                    <Grid container item >
                        <Typography  >
                            Schedule for {moment(posttime + ".000Z").format('MMMM Do YYYY, hh:mm a')}
                        </Typography>
                    </Grid> : null
                }

                {type === "review" && currentUser && currentUser.userInfo && currentUser.userInfo.Username === user.username ?
                    <Grid item container>
                        <Grid container item justify="center">
                            <Typography variant="h6" className={classes.fontBold} style={{color: "blue"}}>
                                Published for Review
                            </Typography>
                        </Grid>
                        <Grid container item >
                            <Typography className={classes.fontBold}>
                                <span style={{fontWeight: "bold"}}>Reviewers -</span>
                                {postReview && postReview.length ? postReview.map((u, index) => <div key={index.toString()}>{u && userList[u] && userList[u].display_name}{index + 1 !== postReview.length ? "," : ""}</div>) : null}
                            </Typography>
                        </Grid>
                    </Grid> :
                    <Grid item container className={classes.alignActionWrp} justify="center">
                        <Grid item style={{position: 'absolute', bottom: '-15px'}}>
                            <Button disabled={!isPublish} onClick={handlePublishClick} variant="contained"
                                    color="primary" className={classes.btnPublish}>
                                Publish
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Grid>

        </Paper>
    )
}

export default BriefCaseCard;
