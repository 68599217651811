import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ImagePreview from './ImagePreview';
import CommentBox from './CommentBox';
import Favorite from '@material-ui/icons/Favorite';
import Checkbox from "@material-ui/core/Checkbox";
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import VideoPlayer from '../VideoPlayer';
import grey from '@material-ui/core/colors/grey';
import Tooltip from '@material-ui/core/Tooltip';
import TextEllipses from '../TextEllipses/TextEllipses';
import { POST_TYPE } from '../CreatePost/reduxForm/context';
import PopOverMenu from '../PopOverMenu/PopOverMenu';
import PDFIcon from "@material-ui/icons/DockTwoTone";
import {Chip} from "@material-ui/core";
import userAvatar from "../../assets/images/user-avtar.png"

const propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  card: {
    maxWidth: 600,
    marginBottom: theme.spacing(1) * 2,
    borderRadius: '0px',
    padding: '26px 26px 0 26px',
    width: '100%'
  },
  cardShadow: {
    borderRadius: '15px',
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.05)'
  },
  cardTitle: {
    color: 'rgb(0, 0, 0)',
    fontSize: '14px',
    fontWeight: 'normal'
  },
  timeago: {
    fontSize: '14px',
    color: '#cecece'
  },
  avatar: {
    backgroundColor: red[500],
    height: '45px',
    width: '45px'
  },
  commentBtn: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  actions: {
    display: 'block',
    padding: '0px'
  },
  p5: {
    padding: '5px'
  },
  likeArea: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  fakeAvatar: {
    backgroundColor: grey[500],
  },
  fake: {
    backgroundColor: grey[200],
    height: theme.spacing(1),
    margin: theme.spacing(1) * 2,
    // Selects every two elements among any group of siblings.
    '&:nth-child(2n)': {
      marginRight: theme.spacing(1) * 3,
    },
  },
  commentImage: {
    height: '20px',
    marginRight: '2px',
    marginBottom: '2px'
  },
  cardContent: {
    padding: theme.spacing(1)
  },
  padding0: {
    padding: 0,
  },
  padding10: {
    padding: '10px',
  },
  name: {
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 'bold'
  }
});

class NewFeed extends React.Component {
  state = {
    expanded: false,
    anchorElPostMenu: null,
    favorite: false,
    anchorElLikeUsers: null
  };


  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handlePostMenu = event => {
    this.setState({ anchorElPostMenu: event.currentTarget });
  };

  handlePostClose = () => {
    this.setState({ anchorElPostMenu: null });
  };

  handleEditPost = () => {
    const { data: obj } = this.props;
    const data = {
      postid: obj.postid,
      groupids: obj.group_id,
      post: obj.posttext,
      img: [...obj.images.map(image => image.normal)],
      vid: [...obj.videos],
      misc: [...obj.misc],
      posttype: POST_TYPE[0].value,
      review_users: Boolean(obj.reviewflag) ? [...obj.post_reviews] : [],
      review_mode: Boolean(obj.reviewflag),
      childids: Array.isArray(obj.children) ? obj.children.map(child => child.id):[]
    }

    if (data.childids.length > 0) {
      data.posttype = POST_TYPE[2].value
    }

    this.props.formInitializeAction('editEvent', data)
    this.props.openDialogAction('EDIT_POST_FORM')
  }

  handleDeletePost = () => {
    const { data } = this.props;
    this.props.deletePostAction.request(data);
      setTimeout(function(){
          window.location.reload();
      }.bind(this),1500);
  };

  handleFavoriteChange = (e) => {
    const { data } = this.props;
    this.setState(prevState => ({ favorite: !prevState.favorite }));
    if (e.target.checked) {
      this.props.likePostAction.request(data);
    } else {
      this.props.unlikePostAction.request(data);
    }
  }

  handleClickLike = (event) => {
    if (this.state.anchorElLikeUsers) {
      this.setState({ anchorElLikeUsers: null });
    } else {
      this.setState({ anchorElLikeUsers: event.currentTarget });
    }
  }

  render() {
    const { classes, data, currentUser, showPopOver, saveCommentAction, updateCommentAction, deleteCommentAction, userList } = this.props;
    const { posttext, images, timeago, view_count, likes, groupname, user, videos, comments, postid, misc } = data;
    const { anchorElLikeUsers } = this.state;
    const Images = images.map(image => image.normal);
    const isPostOwner = (currentUser && currentUser.userInfo && currentUser.userInfo.role === "admin") || (showPopOver && user.username === currentUser.userInfo.Username);
    const openLikePopper = Boolean(anchorElLikeUsers);
    const idLikePopper = openLikePopper ? 'simple-popper' : undefined;
    const student = (data.children || []).map(o =>(` ${o.firstname} ${o.lastname}`))
    return (
      <Fragment>
        <Card className={classnames(classes.card, { [classes.cardShadow]: showPopOver })}>
          <CardHeader
            className={classes.padding0}
            avatar={
                user.profile_photo === "<DEFAULT>" ?
                    <Avatar xs={2}
                            aria-label="Recipe"
                            className={classes.avatar}
                            src={userAvatar}/> :
                    <Avatar
                        aria-label="Recipe"
                        className={classes.avatar}
                        src={user.profile_photo}>
                      {user.display_name[0]}
                    </Avatar>
            }
            action={
              <Fragment>
                {isPostOwner &&
                  <PopOverMenu
                    icon={<MoreVertIcon />}
                    handleEditPost={this.handleEditPost}
                    handleDeletePost={this.handleDeletePost}
                  />
                }
                <br />
                <span className={classes.cardTitle}>{view_count} Views</span>
              </Fragment>
            }
            title={
              <b>
                <span className={classes.name}>{user.display_name}</span>
                <span className={classes.cardTitle}> {student.length ? "shared post of" : "posted to"} </span>
                <span className={classes.name}>
                    {student.length ? student.toString() : groupname}
                </span>
              </b>
            }
            subheader={
              <span className={classes.timeago}>{timeago}</span>
            }
          />
          <CardContent className={classes.cardContent}>
            <Typography style={{ paddingLeft: '20px' }} component="div" >
              <TextEllipses lines={'3'} text={posttext}/>
            </Typography>
            {!!Images.length && showPopOver && (<ImagePreview postViewAction={this.props.postViewAction} Images={Images} data={data} />)}
            <br />
            {!!videos.length && showPopOver && videos.map((video, index) =>
              (
                <div style={{width: "52%"}} key={index.toString()}>
                  <VideoPlayer
                    key={video}
                    posterUrl=""
                    videoUrl={video}
                    data={data}
                    postViewAction={this.props.postViewAction}
                  /><br/>
                </div>))}
            {!!videos.length && showPopOver && misc && misc.length ? <br/> : null}
            {!!misc.length && showPopOver && misc.map((doc, index) =>
              (<a
                key={index.toString()}
                href={doc}
                target="_blank"
                style={{marginRight: 5}}
              >
                <Chip
                  icon={<PDFIcon />}
                  label={`Doc${index+1}`}
                  className={classes.chip}
                  variant="outlined"
                  style={{cursor: "pointer"}}
                />
              </a>))}
          </CardContent>

          {showPopOver &&
            (<CardActions className={classes.actions} disableactionspacing="true">
              <div className={classes.likeArea}>
                <Grid >
                  <Checkbox
                    icon={<Favorite />}
                    checkedIcon={<Favorite />}
                    onChange={this.handleFavoriteChange}
                    checked={likes.selflike} />
                  <span aria-describedby={idLikePopper} onClick={this.handleClickLike}>
                    {likes.count} Likes
                  </span>
                </Grid>
                {likes.users.length > 0 && <Popover
                  id={idLikePopper} open={openLikePopper} anchorEl={anchorElLikeUsers}
                  onClose={this.handleClickLike}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Grid container>
                    <Grid item className={classes.padding10}>
                      {likes.users.map(name => (
                        <span key={name}>
                          {name && userList[name] && userList[name].display_name}
                          <br />
                        </span>
                      ))}
                    </Grid>
                  </Grid>
                </Popover>}

                <Grid className={classes.p5} onClick={this.handleExpandClick}>
                  <IconButton>
                    <ChatBubbleIcon />
                  </IconButton>
                  {comments.length} comments
                </Grid>
              </div>
            </CardActions>)
          }

          {showPopOver && !this.state.expanded && comments.length > 0 ? (
            <CommentBox
              showPopOver={showPopOver}
              comments={[comments[0]]}
              postid={postid}
              currentUser={currentUser}
              saveCommentAction={saveCommentAction}
              updateCommentAction={updateCommentAction}
              deleteCommentAction={deleteCommentAction}
            />
          ) :

            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
              <CommentBox
                comments={comments}
                postid={postid}
                currentUser={currentUser}
                saveCommentAction={saveCommentAction}
                updateCommentAction={updateCommentAction}
                deleteCommentAction={deleteCommentAction}
              />
            </Collapse>}
        </Card >
      </Fragment>

    );
  }
}

NewFeed.propTypes = propTypes;

export default withStyles(styles)(NewFeed);

export const FakeCard = withStyles(styles)((props) => {
  const { classes } = props;
  return (
    <Fragment>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.fakeAvatar}>
              <div className={classes.fake} />
            </Avatar>
          }
          action={<div className={classes.fake} />}
          title={<div className={classes.fake} />}
          subheader={<div className={classes.fake} />}
        />
        <CardContent>
          <Typography component="div">
            <div className={classes.fake} />
            <div className={classes.fake} />
            <div className={classes.fake} />
          </Typography>
        </CardContent>
      </Card >
    </Fragment>
  )
});