
export const EVENT_TYPES = Object.freeze({
    "Generic_Event": "1",
    "RSVP_Event": "2",
    "Parent_Teacher_Event": "3",
    "Volunteer_Event": "4"
});

export const EVENT_TYPES_VALUE = Object.freeze({
    "1": "Generic_Event",
    "2": "RSVP_Event",
    "3": "Parent_Teacher_Event",
    "4": "Volunteer_Event"
});
