import {
    createSelector
} from 'reselect';

const groupSelector = state => state.currentUser.userInfo.Groups;

const convertGroupOptions = createSelector(
    [groupSelector], (groupObject = {}) => {

        if (Object.keys(groupObject).length > 0) {
            const groups = Object.values(groupObject)
            return groups.map(group => ({ label: group.GroupName, value: group.groupid }))
        }
        return []
    }
)

export const MangePostSelector = {
    convertGroupOptions
};