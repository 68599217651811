import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import RsvpAttend from './RsvpAttend';
import { Typography } from '@material-ui/core';

function RsvpResponse(props) {

    const { event, readOnly } = props
    const { user_response = null } = event

    const [isModified, setIsModified] = useState(false)

    useEffect(() => {

        function checkIsModified() {
            const isModified = props.event.user_response ? true : false;
            setIsModified(isModified)
        }

        checkIsModified()
    }, [props.event])



    function updateRSVP(data) {
        props.sendRsvpEventResponseAction.request(data);
    }

    function handleUpdateClick(args) {
        setIsModified(false)
    }

    return (
        <>
            <Typography color="primary" variant="h6" className="rsvp-around">
                <span className="currentRSVP">Current RSVP</span>
                {!isModified && <span className="pendingRSVP">RSVP Pending</span>}
                {isModified && user_response &&
                    <span className="YES-2-Adults-2-children">
                        <span className="text-style-1">{user_response.attend} </span>
                        <br />
                        {user_response.adult} adult {' '} , {user_response.children} children
                    </span>
                }
            </Typography>

            {isModified &&
                <Typography color="primary" variant="h6" className="rsvpUpdate" onClick={handleUpdateClick}>
                    UPDATE RSVP
                </Typography>
            }

            {!isModified &&
                <>
                    <Typography color="primary" variant="h6" className="rsvpAttend">
                        Will You Attend?
                    </Typography>
                    <RsvpAttend
                        readOnly={readOnly}
                        eventId={event.event_id}
                        updateRSVP={updateRSVP}
                    />
                </>
            }
        </>
    )
}

RsvpResponse.propTypes = {
    event: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool
}

RsvpResponse.defaultPropTypes = {
    readOnly: false
}

export default RsvpResponse