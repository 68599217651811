import React from 'react'
import PropTypes from 'prop-types'
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import Moment from 'react-moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

const styles = theme => ({
  dateTimeCard: {
    padding: "0 0 20px 0",
    boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: "12px"
  },
  dateTitme: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#05b9a4",
    padding: 20
  },
  slotTime: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000",
    border: "1px solid rgba(143, 143, 150, 0.38)",
    borderRadius: "50px",
    padding: "0px 10px",
    textAlign: "center"
  },
  centerContent: {
    width: "100%",
    margin: "20px auto",
  },
  table: {
    minWidth: 620,
    margin: "10px 35px",
    width: "95% !important"
  },
  customthead: {
    fontSize: 16
  },
  innercell: {
    // borderBottom: "0px",
    width: "200px",
    padding: "5px"
  }
});

function isSlotFull(userResponse, slotData) {
  const isFull = userResponse.find(res => res.slotId === slotData.slotId)
    ? userResponse.find(res => res.slotId === slotData.slotId)
      .childs.length === 1 : false

  return isFull
}

function PreviewMeeting(props) {
  const {
    classes, event, data, readOnly, setEventResponse, removeChildEventAction,
    eventResponse: { userResponse = [] }, email
  } = props;

  const [openSignup, setOpenSignup] = React.useState(false);
  const [anchorElSignUp, setAnchorElSignUp] = React.useState(null);
  const [slotId, setSlotId] = React.useState('');

  const handleSignup = (slotId) => (event) => {
    setSlotId(slotId)
    setAnchorElSignUp(event.currentTarget);
    setOpenSignup(true)
  };

  const removeChild = (child, time) => (e) => {
    const events = event
    const body = {
      slotId: time.slotId,
      child_id: child.childid,
      eventid: events.event_id,
    }
    removeChildEventAction.request(body)
  }

  function closeSignup() {
    setOpenSignup(false)
  };

  function getStripedStyle(index) {
    return { background: index % 2 ? '#f4f4f4' : 'white' };
  }

  function sendSignupResponse(value) {
    const events = event
    if (value) {
      const body = {
        eventid: events.event_id,
        event_type: events.event_type,
        slotId,
        child_id: value.childid
      }
      setEventResponse.request(body)
    }
    closeSignup()
  }

  return (
    <>
      {event.parentTeacherResponse.map((date, index) => (
        <Grid container key={index}>
          <Grid item xs={10} className={classes.centerContent}>
            <Grid container spacing={10}>
              <Grid item xs={12} className="centerdatetime">
                <Card className={classes.dateTimeCard}>
                  <Grid container>
                    <Grid item xs={4} className={classes.dateTitme}>
                      <Moment format="ddd D, MMMM YYYY" date={date.event_date} />
                    </Grid>
                  </Grid>
                  <Table className={classes.table} fixedheader="false" style={{ width: "auto", tableLayout: "auto" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.customthead}>#</TableCell>
                        <TableCell align="left" className={classes.customthead}>Spots</TableCell>
                        <TableCell align="left" className={classes.customthead}>Participant</TableCell>
                        <TableCell align="left" className={classes.customthead}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {date.slotTime.map((time, index) =>
                        <TableRow key={index} style={getStripedStyle(index)}>
                          <TableCell component="th" scope="row">
                            {index}
                          </TableCell>
                          <TableCell align="left">
                            <Moment format="hh:mm a" date={time.start_time} />
                            -
                            <Moment format="hh:mm a" date={time.end_time} />
                          </TableCell>
                          <TableCell align="left">
                            {userResponse.find(res => res.slotId === time.slotId)
                              && userResponse.find(res => res.slotId === time.slotId)
                                .childs.map((child, index) =>
                                  (
                                    <span key={child.childid}>
                                      {child.firstname} {child.lastname}
                                      {child.parents.some(parent => parent === email) &&
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={removeChild(child, time)}
                                        > (delete)</span>
                                      }
                                    </span>)
                                )
                            }
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              className={isSlotFull(userResponse, time) ? "fullButton" : "signupbutton"}
                              onClick={handleSignup(time.slotId)}
                              disabled={(readOnly ? true : isSlotFull(userResponse, time) ? true : false)}
                            >
                              {isSlotFull(userResponse, time) ? "Full" : "Sign up"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Popover
                    id="render-props-popover"
                    open={openSignup}
                    anchorEl={anchorElSignUp}
                    onClose={closeSignup}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}

                  >
                    <List>
                      {data && data.map((item, index) => (
                        <ListItem key={index} button onClick={() => { sendSignupResponse(item) }}>
                          <ListItemAvatar>
                            <Avatar
                              className={classes.avatar}
                              src={item.pictureurl === "<DEFAULT>" ? '' : item.pictureurl}
                            >
                              {item.firstname[0]}{item.lastname[0]}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={`${item.firstname}${item.lastname}`} />
                        </ListItem>
                      ))}
                    </List>
                  </Popover>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  )
}

PreviewMeeting.propTypes = {
  event: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool
}

PreviewMeeting.defaultProps = {
  readOnly: false,
  email: '',
  eventResponse: { userResponse: [] }
}

export default withRouter(withStyles(styles)(PreviewMeeting));