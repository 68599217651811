import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import { v4 } from 'uuid';
import { Storage } from 'aws-amplify';
import { makeStyles } from '@material-ui/styles';
import Chip from "@material-ui/core/Chip";
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    },
    chip: {
        margin: theme.spacing(0.1),
        color: 'darkblue',
        cursor: "pointer",
        fontSize: "12px"
    },
    buttonProgress: {
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }

}));


export default function DropZone(props) {
    const classes = useStyles();
    const icon = props.icon;
    const label = props.label;
    const acceptedFileType = props.accept;
    const allowMultiple = props.multiple;

    const [loading, setLoading] = useState(false);

    //   const initialFile = props.input.value || [];
    //   const [files, setFiles] = useState(initialFile);

    const { getRootProps, getInputProps, } = useDropzone({
        accept: acceptedFileType,
        multiple: allowMultiple,
        onDrop: handleDrop
    });


    useEffect(() => {
        // setFiles(props.input.value)
    }, [props.input])

    async function handleDrop(acceptedFiles) {
        setLoading(true)
        const file = await Promise.all(acceptedFiles.map(uploadS3))
        const value = [...props.input.value || [], ...file]
        props.input.onChange(value);
        setLoading(false)
        // setFiles(file);
    }

    async function uploadS3(file) {
        const path = `${moment().year()}/${moment().month() + 1}/${moment().date()}/${v4()}.${file.name.split('.').pop()}`;
        const response = await Storage.put(path, file, { contentType: file.type });
        return response.key;
        // return Object.assign({ key: response.key, name: file.name });
    }

    return (
        <span {...getRootProps()}>
            <input {...getInputProps()} />
            <span className={classes.wrapper}>
                <Chip
                    icon={icon}
                    label={label}
                    variant="outlined"
                    className={classes.chip}
                    color="primary"
                />
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </span>
        </ span>
    );
}



