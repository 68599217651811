import {
    createSelector
} from 'reselect';

const getUserInfo = state => state.currentUser.userInfo;

const getGroupCache = state => state.cache.groupsCache;

const getUserCache = state => state.cache.userCache;

const getGroupInfoWithParents = createSelector(
    [getUserInfo, getGroupCache, getUserCache], (userInfo = {}, groups = {}, users) => {
        const { Groups: groupsInfo = {} } = userInfo;
        if (Object.keys(groupsInfo).length > 0 && Object.keys(groups).length > 0 && users) {
            return Object.keys(groupsInfo).map(groupId => {
                return {
                    ...groupsInfo[groupId],
                    parents: Array.isArray(groups[groupId]) ? groups[groupId].map(parent => users[parent]) : []
                }
            });
        }
        return [];
    }
)

export const GroupSelector = {
    getGroupInfoWithParents
};