import React, { Component } from 'react';
import {
    Avatar, Input, Grid, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import ReplyIcon from "@material-ui/icons/Send";
import userAvatar from "../../assets/images/user-avtar.png"

const styles = theme => ({
    container:{
        marginBottom: '5px',
        marginLeft: '2px',
        marginTop: '0px'
    },
    commentTextBox: {
        backgroundColor: grey[50],
        borderRadius: '5px',
        padding: '5px',
        borderStyle: 'solid',
        borderColor: '#bdc3c7',
        borderWidth: '1px',
        display: 'flex'
    },
    helpMessage: {
        color: grey[500]
    },
    w100:{ width: '100%' },
    icon: { transform: 'scaleX(-1)',alignSelf: 'center'}
});

class CreateComment extends Component {

    state = {
        commentText: ''
    }

    componentDidMount() {
        const { comment } = this.props;
        if (comment) {
            this.setState({
                commentText: comment.comment
            })
        }
    }


    handleTextChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleCreateCommentOnClick = (event) => {
        const { commentText } = this.state;
        const { comment } = this.props;

        if (commentText && comment) {
            const query = {
                comments: commentText,
                commentid: comment.id,
                postid: comment.postid
            }

            this.props.sendComment(query);
            this.setState({ commentText: '' });
        }

        if (!comment) {
            this.props.sendComment(commentText);
            this.setState({ commentText: '' });
        }
    }

    handleCommentTextKeyPress = (event) => {
        const { commentText } = this.state;
        const { comment } = this.props;

        if (event.key === 'Enter' && commentText && comment) {
            const query = {
                comments: commentText,
                commentid: comment.id,
                postid: comment.postid
            }

            this.props.sendComment(query);
            this.setState({ commentText: '' });
        }

        if (event.key === 'Enter' && !comment) {
            this.props.sendComment(commentText);
            this.setState({ commentText: '' });
        }
    }

    render() {
        const { commentText } = this.state;
        const { currentUser, classes } = this.props;
        const picture = currentUser.userInfo.picture;
        const pictureName = currentUser.userInfo.given_name[0];

        return (
            <Grid container direction="row" spacing={8} className={classes.container} >
                <Grid item xs={1} style={{padding: '0'}}>
                    {
                        picture  === "<DEFAULT>" ?
                            <Avatar xs={2} aria-label="Recipe" className={classes.avatar} src={userAvatar}/> :
                            <Avatar alt='user0image' src={picture}>
                                {pictureName}
                            </Avatar>
                    }
                </Grid>
                <Grid item container direction="row" xs={9} style={{padding: '0'}}>
                    <Grid item xs={12} className={classes.commentTextBox} >
                        <Input
                        className={classes.w100}
                            name="commentText"
                            value={commentText}
                            placeholder="Add a comment.."
                            autoComplete="off"
                            margin="none"
                            disableUnderline
                            onChange={this.handleTextChange}
                            onKeyPress={this.handleCommentTextKeyPress}
                        />
                            <ReplyIcon color="primary" onClick={this.handleCreateCommentOnClick}
                             />
                    </Grid>
                    <Typography variant="caption" gutterBottom className={classes.helpMessage}>
                        Press Enter to post.
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(CreateComment);
