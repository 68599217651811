import React, { Component, Fragment } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { Chip, Grid } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Collapse from '@material-ui/core/Collapse';
import classnames from 'classnames'
import Card from '@material-ui/core/Card';
import TextEllipses from '../TextEllipses/TextEllipses';
import CardContent from '@material-ui/core/CardContent';
import ImagePreview from '../FeedCard/ImagePreview';
import VideoPlayer from '../VideoPlayer';
import moment from 'moment';
import PDFIcon from "@material-ui/icons/DockTwoTone";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopOverMenu from '../PopOverMenu/PopOverMenu';
import {POST_TYPE} from "../CreatePost/reduxForm/context";


const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(1) * 2,
    },
    mb10: {
        marginBottom: '10px'
    },
    fontBold: {
        fontWeight: 'bold',
        textTransform: 'capitalize'
    },
    card: {
        maxWidth: 450,
        width: 390,
        marginBottom: theme.spacing(1) * 2,
        borderRadius: '0px',
        padding: '10px'
    },
    cardShadow: {
        borderRadius: '15px',
        boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.05)'
    },
    label: {
        padding: '0 10px',
        height: '27px',
        borderRadius: '10px',
        border: 'solid 1px #3cc89c',
        backgroundColor: '#ffffff',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width:'50%  '
    },
    club: {
        fontSize: '18px',
        color: '#3cc89c',
        textTransform: 'capitalize'
    },
    pinnedDate: {
        fontSize: '14px',
        color: '#212b9b'
    },
    posted: {
        fontSize: '12px',
        textAlign: 'left',
        color: '#cecece'
    },
    padding: {
        padding: '16px 16px 0 16px !important',
    },
    moreBtn: {
        width: '100%',
        background: 'aliceblue',
        border: 0,
        cursor: 'pointer',
        borderRadius: '10px'
    }
});

class PinnedPost extends Component {
    state = {
        expanded: false,
    };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    handleEditPost = () => {
        const { data: obj } = this.props;
        const data = {
            postid: obj.postid,
            groupids: obj.group_id,
            post: obj.posttext,
            img: [...obj.images.map(image => image.normal)],
            vid: [...obj.videos],
            misc: [...obj.misc],
            posttype: POST_TYPE[0].value,
            review_users: Boolean(obj.reviewflag) ? [...obj.post_reviews] : [],
            review_mode: Boolean(obj.reviewflag),
            childids: Array.isArray(obj.children) ? obj.children.map(child => child.id):[],
            draft_mode: 0,
            groupnames: obj.groupnames,
            pinned_post: 1,
            pinned_topic: obj.pinnedtopic,
            pinned_until: obj.pinneduntil,
        }

        if (data.childids.length > 0) {
            data.posttype = POST_TYPE[2].value
        }

        this.props.formInitializeAction('editEvent', data)
        this.props.openDialogAction('EDIT_POST_FORM')
    }

    handleDeletePost = () => {
        const { data } = this.props;
        this.props.deletePostAction.request(data);
        setTimeout(() => {
            this.props.loadPinPost(1)
        },2000)
    };


    render() {
        const { classes, data, currentUser } = this.props;
        const { user, posttext } = data
        const Images = data.images.map(image => image.normal);
        const videos = data.videos;
        const misc = data && data.misc;
        const isPostOwner = (currentUser && currentUser.userInfo && currentUser.userInfo.role === "admin") || (user.username === currentUser.userInfo.Username);
        return (
            <Grid>
                <Fragment>
                    <Card className={classnames(classes.card, classes.cardShadow)}>
                        <Grid container justify="space-between">
                            <Grid className={classes.label}>
                                <span className={classes.club}>   {data.groupname} </span>
                            </Grid>
                            <Grid style={{ alignSelf: 'center' }}>
                                <span className={classes.pinnedDate}> Pinned till {moment(data && (data.pinned_until || data.pinneduntil)).format('MMM Do')}</span>
                            </Grid>
                            <Grid>
                                { isPostOwner ?
                                    <PopOverMenu
                                        icon={<MoreVertIcon/>}
                                        handleEditPost={this.handleEditPost}
                                        handleDeletePost={this.handleDeletePost}
                                    /> : null
                                }
                            </Grid>
                        </Grid>
                        <CardContent className={classes.padding}>
                            <Typography component="div" >
                                <TextEllipses lines={'1'} text={posttext} />
                            </Typography>
                            {!this.state.expanded && (Images.length || videos.length || misc.length) ?
                                <button className={classes.moreBtn} onClick={this.handleExpandClick}>
                                    see media
                                </button> : null }
                            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                {!!Images.length && (<ImagePreview Images={Images} data={data} />)}
                                <br />
                                {!!videos.length && videos.map(video =>
                                    (<VideoPlayer
                                        key={video}
                                        posterUrl=""
                                        videoUrl={video}
                                    />))}
                                {!!videos.length && misc && misc.length ? <br/> : null}
                                {!!misc.length && misc.map((doc, index) =>
                                    (<a
                                        key={index.toString()}
                                        href={doc}
                                        target="_blank"
                                        style={{marginRight: 5}}
                                    >
                                        <Chip
                                            icon={<PDFIcon />}
                                            label={`Doc${index+1}`}
                                            className={classes.chip}
                                            variant="outlined"
                                            style={{cursor: "pointer"}}
                                        />
                                    </a>))}
                                <button className={classes.moreBtn} onClick={this.handleExpandClick}>
                                    hide media
                                </button>
                            </Collapse>
                            <Grid container justify="space-between">
                                <span className={classes.posted}> Posted {data.timeago}</span>
                                <span className={classes.posted} style={{ textTransform: 'capitalize' }}> by {data.user.display_name}</span>
                            </Grid>
                        </CardContent>
                    </Card>
                </Fragment>
            </Grid>
        )
    }
}

export default withStyles(styles)(PinnedPost);