import React, { Component } from 'react';
import {
    Select as MartialSelect,
    MenuItem, Badge
} from '@material-ui/core';


class Select extends Component {

    onChange = (event) => {
        const { input, onSelectChange } = this.props
        input.onChange(event)
        if (onSelectChange) {
            onSelectChange(event.target.value)
        }
    }

    render() {
        const { options, MenuProps, input, wrapperInput, totalStu } = this.props;
        const extraProps = {}

        if (MenuProps) {
            extraProps.MenuProps = MenuProps
        }

        if (input) {
            extraProps.input = wrapperInput
        }

        return (
            <div>
                {
                    (input.value === "STUDENT") && (totalStu > 0) ?
                        <Badge color="secondary" badgeContent={totalStu}>
                            <MartialSelect
                                {...extraProps}
                                {...input}
                                onChange={this.onChange}
                            >
                                {Array.isArray(options) &&
                                options.map((options, index) => (
                                    <MenuItem key={index} value={options.value}>
                                        {options.label}
                                    </MenuItem>
                                ))}
                            </MartialSelect>
                        </Badge> :
                        <MartialSelect
                            {...extraProps}
                            {...input}
                            onChange={this.onChange}
                        >
                            {Array.isArray(options) &&
                            options.map((options, index) => (
                                <MenuItem key={index} value={options.value}>
                                    {options.label}
                                </MenuItem>
                            ))}
                        </MartialSelect>
                }
            </div >
        )
    }
}

export default Select
