import React, { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

import UpdatePost from '../../container/UpdatePost';
import CreatePost from '../../container/CreatePost';
import PostFilter from '../../container/PostFilter';

import FeedCard, { FakeCard } from './FeedCard';
import PinnedPost from '../PinnedPost/PinnedPost';

const styles = theme => ({
  scrollContent: {
    height: '85vh',
    paddingRight: "10px",
    paddingLeft: "15px",
    overflowX: 'hidden',
    overflowY: 'hidden',
    '&:hover': {
      overflowY: "scroll",
      // background: "#f00",
   },
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  mb10: {
    marginBottom: '10px'
  },
  fontBold: {
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
});

class FeedPost extends Component {

  loadPostItems(page) {
    const { fetchPostAction, userGroups, post: { query: _query } } = this.props;
    const groups = userGroups.map(group => group.groupid);
    if (!!groups.length) {
      const query = {
        'page': page - 1,
        'itemsperpage': 10,
        'groups': _query.groups.length > 0 ? _query.groups : groups
      }
      fetchPostAction.request(query);
    }
  }

  loadPinnedPostItems(page) {
    const { fetchPinnedPostAction, userGroups, pinned: { query: _query } } = this.props;
    const groups = userGroups.map(group => group.groupid);
    if (!!groups.length) {
      const query = {
        'page': page - 1,
        'itemsperpage': 10,
        'groups': _query.groups.length > 0 ? _query.groups : groups,
        'ispinned': true
      }
      fetchPinnedPostAction.request(query);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.body.style.overflowY = "hidden";
    this.loadPostItems(1);
    this.loadPinnedPostItems(1);
  }

  UNSAFE_componentWillReceiveProps() {
    document.body.style.overflowY = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflowY = "auto";
  }

  render() {
    const {
      classes,
      post: {
        feeds,
        query: { hasMoreItems },
      },
      pinned: {
        feeds: pinnedfeeds,
        query: { hasMoreItems: pinnedhasMoreItems },
      },
      userGroups,
      currentUser,
      saveCommentAction,
      updateCommentAction,
      deleteCommentAction,
      deletePostAction,
      likePostAction,
      unlikePostAction,
      cache,
      getUserCatch
    } = this.props;
    const postItems = [], pinnedItems = [];
    const fake = Array.from({ length: 2 }, (e, index) => (<FakeCard key={index} />));
    feeds && feeds.length && feeds.sort(function (a, b) {
      return a.row_num - b.row_num;
    });
    feeds.forEach((feed, i) => {
      postItems.push(
        <FeedCard
          key={i}
          data={feed}
          showPopOver
          currentUser={currentUser}
          openDialogAction={this.props.openDialogAction}
          formInitializeAction={this.props.formInitializeAction}
          deletePostAction={deletePostAction}
          saveCommentAction={saveCommentAction}
          updateCommentAction={updateCommentAction}
          deleteCommentAction={deleteCommentAction}
          likePostAction={likePostAction}
          unlikePostAction={unlikePostAction}
          postViewAction={this.props.postViewAction}
          userList={cache && cache.userCache}
        />);
    });

    pinnedfeeds.forEach((feed, i) => {
      pinnedItems.push(
        <PinnedPost
          key={i}
          loadPinPost={this.loadPinnedPostItems.bind(this)}
          data={feed}
          openDialogAction={this.props.openDialogAction}
          formInitializeAction={this.props.formInitializeAction}
          deletePostAction={deletePostAction}
          currentUser={currentUser}
          userList={cache && cache.userCache}
        />
      );
    });

    if (userGroups.length !== 0) {
      return (
        <Fragment>
          <Grid container>
            <Grid item id="custom-scroll" className={classes.scrollContent}>
              <CreatePost />
              <PostFilter />
              <UpdatePost loadPinPost={this.loadPinnedPostItems.bind(this)}/>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadPostItems.bind(this)}
                hasMore={hasMoreItems}
                useWindow={false}
                threshold={500}
              >
                {feeds.length > 0 ? postItems : fake}
              </InfiniteScroll>
            </Grid>
            <Grid item id="custom-scroll" className={classes.scrollContent}>
              <Grid item className={classes.mb10}>
                <Typography variant="h6" className={classes.fontBold}>
                  Pinned Posts
                </Typography>
              </Grid>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadPinnedPostItems.bind(this)}
                hasMore={pinnedhasMoreItems}
                useWindow={false}
                threshold={500}
              >
                {pinnedfeeds.length > 0 && pinnedItems}
              </InfiniteScroll>

            </Grid>
          </Grid>
        </Fragment>
      )
    }
    return (<div />)
  }
}
export default withStyles(styles)(FeedPost);
