import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import store from '../../../redux/store'
import * as routes from "../../../routes/path";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";

import Calendar from "../../../assets/images/20.png";
import Invitation from "../../../assets/images/23.png";
import Meeting from "../../../assets/images/21.png";
import Volunteer from "../../../assets/images/22.png";

import { handleFormInitalValue } from "../../../redux/actions/event";
import { getEventInitialValue } from "../../../shared/consts/eventintialvalue";
import { EVENT_TYPES } from "../../../shared/enums";

const styles = {
  event: {
    color: "#3C3989",
    fontWeight: "bolder"
  },
  speedDial:{

  }
};

const actions = [
  {
    icon: <img src={Calendar} alt="event" className="button-float" />,
    name: "Generic Event",
    eventAction: "addEvent"
  },
  {
    icon: <img src={Invitation} alt="invitation" className="button-float" />,
    name: "Rsvp Event",
    eventAction: "addRsvp"
  },
  {
    icon: <img src={Meeting} alt="meeting" className="button-float" />,
    name: "Parent-Teacher Event",
    eventAction: "addMeeting"
  },
  {
    icon: <img src={Volunteer} alt="volunteer" className="button-float" />,
    name: "Volunteer Event",
    eventAction: "addVolunteer"
  }
];

const actions1 = [
  {
    icon: <img src={Invitation} alt="invitation" className="button-float" />,
    name: "Rsvp Event",
    eventAction: "addRsvp"
  }
];

class AddEventButton extends React.Component {
  state = {
    open: false,
    hidden: false
  };

  onClickEvent = action => (event) => {
    if (action === "addEvent") {
      const value = getEventInitialValue(EVENT_TYPES.Generic_Event)
      store.dispatch(handleFormInitalValue(value));
      this.props.history.push(routes.MANAGE_EVENT);
    } else if (action === "addRsvp") {
      const value = getEventInitialValue(EVENT_TYPES.RSVP_Event)
      store.dispatch(handleFormInitalValue(value));
      this.props.history.push(routes.MANAGE_RSVP_EVENT);
    } else if (action === "addMeeting") {
      const value = getEventInitialValue(EVENT_TYPES.Parent_Teacher_Event)
      store.dispatch(handleFormInitalValue(value));
      this.props.history.push(routes.MEETING_EVENT);
    } else if (action === "addVolunteer") {
      const value = getEventInitialValue(EVENT_TYPES.Volunteer_Event)
      store.dispatch(handleFormInitalValue(value));
      this.props.history.push(routes.VOLUNTEER_EVENT);
    } else {
      this.props.history.push(routes.EVENT);
    }
  };

  handleVisibility = () => {
    this.setState(state => ({
      open: false,
      hidden: !state.hidden
    }));
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { classes, userInfo: { role } } = this.props;
    const { hidden, open } = this.state;
    return (
      <div className="stickyAddEvent">
        <SpeedDial
          ariaLabel="SpeedDial openIcon example"
          className={classes.speedDial}
          hidden={hidden}
          icon={<SpeedDialIcon openIcon={<EditIcon />} />}
          onBlur={this.handleClose}
          onClick={this.handleClick}
          onClose={this.handleClose}
          onFocus={this.handleOpen}
          onMouseEnter={this.handleOpen}
          onMouseLeave={this.handleClose}
          open={open}
        >
          {role !== 'parent' && actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={this.onClickEvent(action.eventAction)}
               className="prevIcon"
              // style={{margin:'5px'}}
            />
          ))}

          {role === 'parent' && actions1.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={this.onClickEvent(action.eventAction)}
              // className="prevIcon"
            />
          ))}
        </SpeedDial>
      </div>
    );
  }
}
AddEventButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(AddEventButton));
