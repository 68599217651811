import { all,fork } from 'redux-saga/effects';
import rootCache from './cache';
import rootFeedPost from './feed-post';
import rootPostManage from './post-manage';
import rootBriefCase from './breifcase';
import rootEvent from './event';

export default function* root() {
    yield all([
      fork(rootCache),
      fork(rootFeedPost),
      fork(rootPostManage),
      fork(rootBriefCase),
      fork(rootEvent),
    ]);
  }