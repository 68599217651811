// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

import API from './awa-api';



const awsConfig = {
        "app_header": "Bugs Hub 360",
        "Storage": {
                "bucket": 'hugnbugs-userfiles-mobilehub-721870535', //REQUIRED -  Amazon S3 bucket
        },
        'aws_cloud_logic': 'enable',
        'aws_cloud_logic_custom': [{"id":"o224rjrz0c","name":"BUGSHUB 2019","description":"","endpoint":"https://o224rjrz0c.execute-api.us-east-1.amazonaws.com/PROD","region":"us-east-1","paths":["/admin","/admin/attendance","/admin/attendance/markattendance","/admin/attendance/summarybyclass","/admin/attendance/today","/admin/attendance/today/summary","/admin/attendance/today/unmarked","/admin/bulkupload","/admin/bulkupload/status","/admin/child","/admin/child/add","/admin/child/addgroup","/admin/child/addparent","/admin/child/attendance","/admin/child/delete","/admin/child/group-delete","/admin/child/id","/admin/child/update","/admin/dashboard/studentcount","/admin/dashboard/usercount","/admin/groups","/admin/groups/add","/admin/groups/delete","/admin/groups/students","/admin/groups/students/add","/admin/groups/update","/admin/me","/admin/photos","/admin/user","/admin/user/id","/admin/users","/admin/users/add","/admin/users/adddb","/admin/users/delete","/admin/users/deletedb","/admin/users/details","/admin/users/disable","/admin/users/groups","/admin/users/groups/add","/admin/users/groups/remove","/admin/users/lastlogin","/admin/users/resetpasswordflow","/admin/users/search","/admin/users/sendinvite","/admin/users/students","/admin/users/update","/attendance","/attendance/attendanceoverride","/attendance/authorizedusers","/attendance/getattendance","/attendance/markvacation","/attendance/signinchild","/attendance/students","/authenticate","/config","/config/firebase","/config/parentofcache","/config/usercache","/events","/events/add","/events/delete","/events/edit","/events/view","/group","/group/updatephoto","/misc","/misc/feedback","/posts","/posts/add","/posts/comments","/posts/comments/add","/posts/comments/delete","/posts/comments/edit","/posts/delete","/posts/edit","/posts/like","/posts/unlike","/posts/view","/user","/user/addupdatepin","/user/deleteAuthUser","/user/me","/user/me/children","/user/me/updatepicture","/user/updatechildphoto"]}],
        'aws_cognito_identity_pool_id': 'us-east-1:014b66df-8c36-4582-a755-6ef679e01722',
        'aws_cognito_region': 'us-east-1',
        'aws_content_delivery': 'enable',
        'aws_content_delivery_bucket': 'hugnbugs-hosting-mobilehub-721870535',
        'aws_content_delivery_bucket_region': 'us-east-1',
        'aws_content_delivery_cloudfront': 'enable',
        'aws_content_delivery_cloudfront_domain': 'd32d20ok7wlgks.cloudfront.net',
        'aws_mobile_analytics_app_id': 'ed063587759b47798284928891d5724f',
        'aws_mobile_analytics_app_region': 'us-east-1',
        'aws_project_id': 'a1794a4e-4474-487b-9273-f94f099eeb1f',
        'aws_project_name': 'HUGNBUGS',
        'aws_project_region': 'us-east-1',
        'aws_resource_name_prefix': 'hugnbugs-mobilehub-721870535',
        'aws_sign_in_enabled': 'enable',
        'aws_user_files': 'enable',
        'aws_user_files_s3_bucket': 'hugnbugs-userfiles-mobilehub-721870535',
        'aws_user_files_s3_bucket_region': 'us-east-1',
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'us-east-1_R0YDYYa4Q',
        'aws_user_pools_web_client_id': '701nam32sgpusov2v8s382at4n',
    API
}



export default awsConfig;