import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import CreatePost from "../component/CreatePost";
import { MangePostSelector } from "../redux/selector";
import { createPost, editPost } from "../redux/actions/feed-post";
import { POST_TYPE } from "../component/CreatePost/reduxForm/context";
import { fetchStaff, fetchStudents } from '../redux/actions/post-manage';
import { openDialogBox } from '../redux/actions/component';

const selector = formValueSelector('managePost')

const mapStateToProps = state => {
    const GroupOptions = MangePostSelector.convertGroupOptions(state)
    return {
        GroupOptions,
        initialValues: {
            groupids: GroupOptions[0].value || '',
            posttype: POST_TYPE[0].value,
            pinned_until: new Date()
        },
        staffList:state.postManage.staffList,
        studentsList: state.postManage.studentsList,
        FormValues: selector(state, 'posttime', 'posttype','img','vid','groupids'),
        currentUser: state.currentUser,
        loading: state.feed.isLoading,
        filterpost: state.filterpost
    }
}

const mapDispatchToProps = dispatch => ({
    createPostAction: bindActionCreators(createPost, dispatch),
    editPostAction: bindActionCreators(editPost, dispatch),
    fetchStaffAction: bindActionCreators(fetchStaff, dispatch),
    fetchStudentsAction: bindActionCreators(fetchStudents, dispatch),
    openDialogAction: bindActionCreators(openDialogBox, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);
