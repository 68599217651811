import moment from "moment";
import { EVENT_TYPES } from '../enums'

const GENERIC_VALUE = {
    event_type: EVENT_TYPES['Generic_Event'],
    start_time: moment(new Date(), "HH:mm").toDate(),
    end_time: moment(new Date(), "HH:mm").toDate(),
    start_date: moment(new Date(), "HH:mm").toDate(),
    end_date: moment(new Date(), "HH:mm").toDate(),
    location: '',
    img_url: [{key:'1562189344301',name:'default.jpg'}],
    eventname: '',
    eventdesc: '',
    attachments: [],
    groupParentSelection: "",
    notifyOneDayAgo: false,
    notifyTwoDayAgo: false,
    notifyThreeDayAgo: false,
    notifyParent: false,
}

const RSVP_VALUE = {
    event_type: EVENT_TYPES['RSVP_Event'],
    start_time: moment(new Date(), "HH:mm").toDate(),
    end_time: moment(new Date(), "HH:mm").toDate(),
    start_date: moment(new Date(), "HH:mm").toDate(),
    end_date: moment(new Date(), "HH:mm").toDate(),
    location: '',
    img_url: [{key:'1562189344301',name:'default.jpg'}],
    eventname: '',
    eventdesc: '',
    attachments: [],
    groupParentSelection: "",
    notifyOneDayAgo: false,
    notifyTwoDayAgo: false,
    notifyThreeDayAgo: false,
    notifyParent: false,
    rsvp: "true"
}

const MEETING_VALUE = {
    event_type: EVENT_TYPES['Parent_Teacher_Event'],
    location: '',
    img_url: [{key:'1562189344301',name:'default.jpg'}],
    eventname: '',
    eventdesc: '',
    attachments: [],
    groupParentSelection: "",
    notifyOneDayAgo: false,
    notifyTwoDayAgo: false,
    notifyThreeDayAgo: false,
    notifyParent: false,
    parentTeacherResponse: []
}

const VOLUNTEER_VALUE = {
    event_type: EVENT_TYPES['Volunteer_Event'],
    location: '',
    img_url: [{key:'1562189344301',name:'default.jpg'}],
    eventname: '',
    eventdesc: '',
    attachments: [],
    groupParentSelection: "",
    notifyOneDayAgo: false,
    notifyTwoDayAgo: false,
    notifyThreeDayAgo: false,
    notifyParent: false,
    volunteertype: '1',
    daterangetype: '1',
    volunteer: [],
    slotTime: [],
    dates:{},
}

export function getEventInitialValue(eventType) {
    switch (eventType) {
        case EVENT_TYPES.Generic_Event:
            return GENERIC_VALUE
        case EVENT_TYPES.RSVP_Event:
            return RSVP_VALUE
        case EVENT_TYPES.Parent_Teacher_Event:
            return MEETING_VALUE
        case EVENT_TYPES.Volunteer_Event:
            return VOLUNTEER_VALUE
        default:
            return GENERIC_VALUE
    }
}