import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import {
    withStyles, Grid, Avatar,
    Card, Button, Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Popconfirm, message } from 'antd';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ReactExport from "react-data-export";

import * as routes from '../routes/path';

import CustomLoadingOverlay from "../component/common/customLoadingOverlay.jsx";
import studentService from '../service/student';
import groupService from '../service/group';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    row: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(1) * 2,
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1) * 2,
    },
    select: {
        margin: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },

});

const INITIAL_STATE = {
    rowData: [],
    excelData: [],
    currentStudent: {},
    currentParent: {},
    style: { width: '100%', height: '100%' },
    openParent: false,
    openStudent: false,
    columnDefs: null,
    onGridReady: null,
    frameworkComponents: { customLoadingOverlay: CustomLoadingOverlay },
    loadingOverlayComponent: "customLoadingOverlay",
    loadingOverlayComponentParams: { loadingMessage: "Loading Students.... Please wait.." },
}

class groupDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
            groupname: this.props.match.params.groupname,
            columnDefs: this.createColumnDefs(),
            onGridReady: this.onGridReady,
        };
    }
    componentDidMount() {
        this.getStudentList();
    }


    createColumnDefs() {
        return [
            {
                headerName: "Profile",
                field: "pictureurl",
                autoHeight: true,
                cellRendererFramework: this.profilePictureRenderer,
                width: 80
            },
            {
                headerName: "Student Name",
                field: "firstname",
                cellRendererFramework: (event) => (
                    <button
                        className="btn btn-link"
                        value={event.data.childid}
                        onClick={this.onStudentNameClick}>
                        {event.data.firstname} {event.data.lastname}
                    </button>
                ),
                width: 100
            },
            {
                headerName: "Parents",
                field: "parents",
                cellRendererFramework: this.parentColumnRender,
                autoHeight: true
            },
            {
                headerName: "Action",
                field: "childid",
                width: 150,
                cellRendererFramework: this.actionColumnRenderer,
                autoHeight: true
            },
        ]
    }

    profilePictureRenderer = event => {
        const { classes } = this.props;
        return (
            <Avatar className={classes.avatar}>{event.data.firstname[0].toUpperCase()}</Avatar>
        )
    }

    parentColumnRender = event => {
        return (
            <Fragment>
                {event.data && event.data.parents.map((parent, index) => (
                    <div key={index}>
                        <button className="btn btn-link" onClick={e => { this.onParentEmailClick(parent) }}>
                            {parent}
                        </button>
                    </div>
                ))}
            </Fragment>
        )
    }

    actionColumnRenderer = event => {
        return (
            <div>
                <Popconfirm title="Are you sure delete this task?" onConfirm={() => this.deleteChild(event)} okText="Yes" cancelText="No">
                    <button type="button" className="btn btn-link">delete</button>
                </Popconfirm>
            </div>
        )
    }

    getStudentList = async () => {
        this.startLoading();
        const { groupname } = this.props.match.params;

        const response = await studentService.getStudentList(groupname);
        if (response.data) {
            const excelData = response.data.map(student => {
                return {
                    ...student,
                    studentname: student.firstname + student.lastname,
                    groupname: student.groups.join(),
                    parent1: student.parents[0],
                    parent2: student.parents[1]
                }
            })

            this.setState({ groupname, rowData: response.data, excelData });
        }
        setTimeout(() => {
            this.gridApi.resetRowHeights();
        }, 100);
        this.stopLoading();
    }

    deleteChild = async (event) => {
        try {
            const { groupname } = this.state;
            const data = {
                'childid': event.value,
                'group': groupname
            }
            await groupService.childGroupDelete(data);
            await this.getStudentList();
            message.success('delete success')
        } catch (error) {
            message.error('something not right!')

        }
    }

    onParentEmailClick = username => {
        this.props.history.push(routes.PARENTS_EDIT, { username })
    }

    onStudentNameClick = event => {
        event.preventDefault();
        const { value } = event.target;
        const childid = value;
        this.props.history.push(routes.USERS_EDIT, { childid })
    }

    onClickEditGroup = () => {
        const { groupname } = this.state;
        this.props.history.push(`/admin/group/bulk/${groupname}`)
    }

    handleClose = () => {
        this.setState({
            openParent: false,
            openStudent: false,
            currentParent: {},
            currentStudent: {}
        });
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
        this.startLoading();
    }

    onColumnResized(event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    }

    startLoading = () => {
        if (this.gridApi)
            this.gridApi.showLoadingOverlay();
    }

    stopLoading = () => {
        this.gridApi.hideOverlay();
    }

    render() {
        const { classes, groups } = this.props;
        const { style, groupname, excelData } = this.state;
        const group = groups[groupname];



        return (
            <div className={classes.root}>

                <Grid container spacing={40}  >
                    <Grid item xs={12} >
                        <Card className={classNames(classes.paper)}>
                            <Grid container spacing={4} alignItems="center"  >
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        className={classes.button}
                                        onClick={(e) => { this.props.history.goBack() }}>
                                        <ArrowBack />
                                        Go Back
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Group Name :  {group && group.GroupName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Description :  {group && group.Description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        className={classes.button}
                                        onClick={this.onClickEditGroup}>
                                        Edit Group
                                    </Button>

                                    <ExcelFile element={
                                        <Button variant="contained" size="small" color="primary" className={classes.button} >Download</Button>
                                    }
                                    >
                                        <ExcelSheet data={excelData} name="students">
                                            <ExcelColumn label="Group Name" value="groupname" />
                                            <ExcelColumn label="# of Students" value="childid" />
                                            <ExcelColumn label="Students Name" value="studentname" />
                                            <ExcelColumn label="Student DOB" value="dob" />
                                            <ExcelColumn label="Allergies" value="allergies" />
                                            <ExcelColumn label="Parent1 Email" value="parent1" />
                                            <ExcelColumn label="Parent2 Email" value="parent2" />
                                        </ExcelSheet>

                                    </ExcelFile>



                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <Card >
                            <div id="myGrid" className="ag-theme-balham" style={style}>
                                <AgGridReact
                                    frameworkComponents={this.state.frameworkComponents}
                                    loadingOverlayComponent={this.state.loadingOverlayComponent}
                                    loadingOverlayComponentParams={this.state.loadingOverlayComponentParams}
                                    animateRows={true}
                                    enableFilter={true}
                                    enableColResize={true}
                                    gridAutoHeight={true}
                                    onColumnResized={this.onColumnResized.bind(this)}
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}
                                    onGridReady={this.state.onGridReady} />
                            </div>
                        </Card>
                    </Grid>
                </Grid>

            </div >
        )
    }
}

const mapStateToProps = state => ({
    groups: state.currentUser.userInfo.Groups || [],
    userInfo: state.currentUser.userInfo
});

export default connect(mapStateToProps)(withStyles(styles)(groupDetail))