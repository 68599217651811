import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Typography, Grid
} from '@material-ui/core';
import TextEllipses from '../TextEllipses/TextEllipses';
import CreateComment from './CreateComment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import grey from '@material-ui/core/colors/grey';
import PopOverMenu from '../PopOverMenu/PopOverMenu';

const styles = theme => ({
    container: {
        marginBottom: '10px',
        marginLeft: '2px'
    },
    commentTextGrid: {
        backgroundColor: grey[100],
        borderRadius: '5px',
        padding: '5px',
        width: '90%',
        display: 'flex',
        marginTop: '5px',
        marginLeft: '-35px'
    },
    padding0: {
        padding: 0,
    },
    commentName: {
        background: grey[100],
        marginLeft: '-15px',
        paddingLeft: '20px',
        marginTop: '5px',
        borderRadius: '10px 0px 0px 10px',
    },
    comment: {
        background: grey[100],
        paddingLeft: '5px',
        borderRadius: '0px 10px 10px 0px',
        marginTop: '5px'
    },
    padT7: {
        paddingTop: '5px'
    },
    capitalize: {
        textTransform: 'capitalize',
        fontWeight: 'bolder'
    }
});

class CommentCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            showPopOver: false
        }
    }

    handleOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleEdit = (e) => {
        this.setState({
            anchorEl: null,
            showPopOver: true
        });
    }

    handleDelete = (e) => {
        const { comment } = this.props;
        this.props.deleteCommentAction.request(comment);
        this.setState({ anchorEl: null });
    }

    updateComment = (query) => {
        this.props.updateCommentAction.request(query);
        this.setState({
            showPopOver: false
        });
    }

    render() {
        const { classes, comment, currentUser } = this.props;
        const { showPopOver } = this.state;
        const isCommentOwner = comment.user.username === currentUser.userInfo.Username || false;

        return (
            <Fragment>
                {!showPopOver &&
                    <Grid
                        container
                        direction="row"
                        className={classes.container} >
                        <Grid item>
                            <Avatar
                                alt='user0image'
                                src={comment.user.profile_photo}
                            >
                                {comment.user.display_name[0]}
                            </Avatar>
                        </Grid>

                        <Grid item className={classes.commentName}>
                            <Typography
                                component="div"
                                className={classes.padT7}
                                paragraph={false}
                            >
                                <span className={classes.capitalize}>
                                    {comment.user.display_name} {' '}
                                </span>
                            </Typography>
                        </Grid>

                        <Grid item xs={8} className={classes.comment}>
                            <Typography
                                className={classes.padT7}
                                component="div"
                                paragraph={false}
                            >
                                <TextEllipses lines={'3'} text={comment.comment} />
                            </Typography>
                        </Grid>

                        <Grid item xs={1}>
                            {isCommentOwner &&
                                <PopOverMenu
                                    icon={<MoreHorizIcon />}
                                    handleEditPost={this.handleEdit}
                                    handleDeletePost={this.handleDelete}
                                />
                            }
                        </Grid>
                    </Grid>
                }

                {showPopOver &&
                    <CreateComment
                        comment={comment}
                        currentUser={currentUser}
                        sendComment={this.updateComment}
                    />
                }

            </Fragment>
        )
    }
}

export default withStyles(styles)(CommentCard);