import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import EditNewsLetter from "../component/NewsLetter/EditNewsLetter";
import { newsLetterActiveStep } from "../redux/actions/newsletter"

const mapStateToProps = state => {
    return {
        newsletter: state.newsletter
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    newsLetterActiveStep
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditNewsLetter);
