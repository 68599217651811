import React, { Fragment } from 'react';
import LinesEllipsis from 'react-lines-ellipsis'

class TextEllipses extends React.Component{
    state = {
        useEllipsis: true,
    }

    onTextClick = (e) => {
        e.preventDefault();
        this.setState({ useEllipsis: false })
    }

    render() {
        const { useEllipsis } = this.state;
        const {text} = this.props;
        return(
            <Fragment>
                { useEllipsis
                ? (
                    <div>
                        { text.length < 250 ? text.split("\n").map(m => m ? <div>{m}</div> : <br/>) :
                           <div>
                               {text.substring(0, 250).split("\n").map(m => m ? <div>{m}</div> : <br/>) }
                               <a onClick={() => this.setState({useEllipsis: false})} style={{color: "blue"}}>...more</a>
                           </div>
                        }
                    </div>
                ) : text.split("\n").map(m => m ? <div>{m}</div> : <br/>)}
            </Fragment>
        )
    }
}

export default TextEllipses;