import React, { Component } from 'react'
import HorizontalStepper from './HorizontalStepper';
import NewsLetterReview from './NewsLetterReview';
import NewsLetterForm from './NewsLetterForm';

function getSteps() {
    return ['Create Newsletter', 'Review Newsletter'];
}

class EditNewsLetter extends Component {

    getStepContent = (stepIndex) => {
        const { newsletter, newsLetterActiveStep } = this.props;
        const steps = getSteps();
        const activeStep = newsletter.activeStep;
        switch (stepIndex) {
            case 0:
                return <NewsLetterForm
                    steps={steps}
                    activeStep={activeStep}
                    newsLetterActiveStep={newsLetterActiveStep}
                />;
            case 1:
                return <NewsLetterReview
                    steps={steps}
                    activeStep={activeStep}
                    newsLetterActiveStep={newsLetterActiveStep}
                />;
            default:
                return 'Uknown stepIndex';
        }
    }

    render() {
        const { newsletter, newsLetterActiveStep } = this.props;

        const activeStep = newsletter.activeStep;
        const steps = getSteps();
        return (
            <>
                <HorizontalStepper
                    steps={steps}
                    activeStep={activeStep}
                    newsLetterActiveStep={newsLetterActiveStep}
                >
                    {this.getStepContent(activeStep)}
                </HorizontalStepper>

            </>
        )
    }
}

export default EditNewsLetter
