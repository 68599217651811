import {
    takeEvery,
    fork,
    all,
    put,
    select,
    call
} from 'redux-saga/effects';
import moment from 'moment';
import eventService from '../../service/event';

import {
    ADD_EVENT,
    addEvent,
    GET_EVENT,
    RSVP_RESPONSE_TYPE,
    UPDATE_RSVP_RESPONSE_TYPE,
    getEvent,
    resetStepperActiveStep,
    getEventById,
    GET_EVENT_BY_ID,
    GET_EVENT_RESPONSE,
    fetchEventResponse,
    FETCH_RSVP_EVENT_BY_USER,
    fetchUserRSVPEvent,
    DELETE_EVENT,
    deleteEvent,
    SET_EVENT_RESPONSE,
    setEventResponse,
    TRANSFER_OWNERSHIP,
    DELETE_SLOT_TIME,
    FETCH_FILTER_BY_USER,
    fetchFilterEvent,
    REMOVE_CHILD_EVENT
} from '../actions/event';

import {
    showToastMessage, setPageLoaderFinish, setPageLoaderStart,
} from '../actions/component';

import { REQUEST } from '../actions/common';
import * as routes from "../../routes/path";
import { closeDialogBox } from '../actions/component';

function transformEvent(data) {
    const event = Object.assign({}, data)
    if (event.groupParentSelection === 'group') {
        delete event["parents"]
        const groups = event.groups.length > 0 ? event.groups.map(group => group.groupId) : [];
        event.groups = groups;
    }

    if (event.groupParentSelection === 'parents') {
        delete event["groups"]
        const parents = event.parents.length > 0 ? event.parents.map(parent => parent.username) : []
        event.parents = parents;
    }

    if (event.parentTeacherResponse && event.parentTeacherResponse.length > 0) {
        //const dates = event.dates.length > 0 ? event.dates.map(date => moment(date).format("D-MM-YYYY")) : []
        // event.event_dates = dates;

        const parentTeacherResponse = event.parentTeacherResponse.length > 0 ? event.parentTeacherResponse.map((date, index) => {
            var rObj = {};
            rObj["event_date"] = moment(date.event_date).toISOString();
            rObj["slotTime"] = event.parentTeacherResponse[index].slotTime.length > 0 ? event.parentTeacherResponse[index].slotTime.map(slot => {
                var tempSlot = {};
                tempSlot["start_time"] = moment(slot.start_time).toISOString();
                tempSlot["end_time"] = moment(slot.end_time).toISOString();
                return tempSlot;
            }) : [];
            return rObj;
        }) : []
        event.parentTeacherSlots = parentTeacherResponse;
        delete event["parentTeacherResponse"];
    }

    const attachments = event.attachments.length > 0 ? event.attachments.map(attachment => attachment.key) : [];
    const img_url = event.img_url[0] ? event.img_url[0].key : "";
    event.attachments = attachments;
    event.img_url = img_url;

    return event;
}

function* createEvent(action) {
    try {
        const event = yield select(state => state.event.formInitialValue);
        const _event = yield transformEvent(event);
        var res = {};
        if (_event.event_id) {
            res = yield eventService.updateEvent(_event);
        } else {
            res = yield eventService.addEvent(_event);
        }
        if (res.statusCode === 200) {
            action.query.history.push(routes.EVENT);
            yield put(addEvent.success());
            yield (put(resetStepperActiveStep()))
        }
    } catch (error) {
        console.log(error.message);
        yield put(addEvent.failure(error.message));
    }
}

function* deleteEventS(action) {
    try {
        const res = yield eventService.deleteEvent(action.query);
        if (res.statusCode === 200) {
            // const body = { filter: "upcomming" }
            // yield put(fetchFilterEvent.request(body));
            yield put(deleteEvent.success());
            yield put(fetchUserRSVPEvent.request());
            yield put(getEvent.request());
        }
    } catch (error) {
        console.log(error.message);
        yield put(deleteEvent.failure(error.message));
    }
}

function* getEventList(action) {
    try {
        yield put(setPageLoaderStart());
        const { results } = yield eventService.getEventList();
        yield put(getEvent.success(results));
        yield put(setPageLoaderFinish());
        //yield put(showToastMessage({message: 'Event list fetched successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(getEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* getEventByEventId(action) {
    try {
        yield put(setPageLoaderStart());
        const { results } = yield eventService.getEventById(action.query);
        yield put(getEventById.success(results));
        yield put(setPageLoaderFinish());
        //yield put(showToastMessage({message: 'Event fetched successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(getEventById.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* setRSVPEventResponse(action) {
    try {
        const { event_id } = action.query;
        yield put(setPageLoaderStart());
        const { statusCode } = yield eventService.setRsvpEventResponse(action.query);
        if (statusCode === 200) {
            yield put(getEvent.request());
            yield put(fetchUserRSVPEvent.request());
            // const body = { filter: "upcomming" }
            // yield put(fetchFilterEvent.request(body));
        }
        yield put(setPageLoaderFinish());
        yield put(showToastMessage({ message: 'RSVP updated successfully', type: 'success' }));
    } catch (error) {
        console.log(error);
        yield put(getEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* removeSignup(action) {
    try {
        yield put(setPageLoaderStart());
        const { statusCode } = yield eventService.removeChildFromSlot(action.query);
        if (statusCode === 200) {
            // const body = { filter: "upcomming" }
            // yield put(fetchFilterEvent.request(body));
            yield put(fetchUserRSVPEvent.request());
            yield put(getEvent.request());
            yield put(fetchEventResponse.request({ event_id: action.query.eventid }));
            yield put(getEventById.request({ event_id: action.query.eventid }))
        }

    } catch (error) {
        yield put(setEventResponse.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* sendEventResponse(action) {
    try {
        yield put(setPageLoaderStart());
        const { statusCode } = yield eventService.setRsvpEventResponse(action.query);
        if (statusCode === 200) {
            // const body = { filter: "upcomming" }
            // yield put(fetchFilterEvent.request(body));
            yield put(fetchUserRSVPEvent.request());
            yield put(fetchEventResponse.request({ event_id: action.query.eventid }));
            yield put(getEventById.request({ event_id: action.query.eventid }))
        }

    } catch (error) {
        yield put(setEventResponse.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* updateRsvpResponse(action) {
    try {
        yield put(setPageLoaderStart());
        const { statusCode } = yield eventService.updateRsvpEventResponse(action.query);
        if (statusCode === 200) {
            const data = {
                query: {
                    event_id: action.query.event_id
                }
            }
            yield put(closeDialogBox())
            yield call(getSelectedEventResponse, data)
        }
        yield put(setPageLoaderFinish());
        yield put(showToastMessage({ message: 'RSVP updated successfully', type: 'success' }));
    } catch (error) {
        console.log(error);
        yield put(getEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* getSelectedEventResponse(action) {
    try {
        yield put(setPageLoaderStart());
        const { result } = yield eventService.getRsvpEventResponse(action.query);
        if (result) {
            yield put(fetchEventResponse.success(result));
        } else {
            const error = { message: 'Fetch Failed' }
            throw error
        }
        yield put(setPageLoaderFinish());
        //yield put(showToastMessage({message: 'Get event successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(fetchEventResponse.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* getUserRSVPEvent() {
    try {
        yield put(setPageLoaderStart());
        const { result } = yield eventService.getUserResponse();
        if (result) {
            yield put(fetchUserRSVPEvent.success(result));
        } else {
            const error = { message: 'Fetch Failed' }
            throw error
        }
        yield put(setPageLoaderFinish());
        //yield put(showToastMessage({message: 'Get RSVP successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(fetchUserRSVPEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* getRSVPFilterEvent(action) {
    try {
        yield put(setPageLoaderStart());
        const { result, count } = yield eventService.getUserResponse(action.query);
        if (result) {
            yield put(fetchFilterEvent.success({ result, count }));
        } else {
            const error = { message: 'Fetch Failed' }
            throw error
        }
        yield put(setPageLoaderFinish());
        //yield put(showToastMessage({message: 'Get RSVP successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(fetchFilterEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* transferOwnerShip(action) {
    try {
        yield put(setPageLoaderStart());
        const { statusCode } = yield eventService.trasnferOwnerShip(action.query);
        if (statusCode === 200) {
            const { results } = yield eventService.getEventList();
            yield put(getEvent.request());
            yield put(showToastMessage({ message: 'Owner changed successfully', type: 'success' }));
        }
    } catch (error) {
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* applyDeleteTimeSlot(action) {
    try {
        yield put(setPageLoaderStart());
        const { statusCode } = yield eventService.deleteEventSlot(action.query);
        if (statusCode === 200) {
            const { results } = yield eventService.getEventList();
            yield put(getEvent.request());
            yield put(getEventById.request({ event_id: action.query.eventid }))
            yield put(showToastMessage({ message: 'Delete Slot', type: 'success' }));
        }
    } catch (error) {
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

/**  all watcher */

function* watchGetEvent() {
    yield takeEvery(GET_EVENT[REQUEST], getEventList)
}
function* watchGetEventById() {
    yield takeEvery(GET_EVENT_BY_ID[REQUEST], getEventByEventId)
}
function* watchCreateEvent() {
    yield takeEvery(ADD_EVENT[REQUEST], createEvent)
}

function* watcherDeleteEvent() {
    yield takeEvery(DELETE_EVENT[REQUEST], deleteEventS)
}

function* watchSetRSVPEventResponse() {
    yield takeEvery(RSVP_RESPONSE_TYPE[REQUEST], setRSVPEventResponse)
}

function* watchUpdateRSVPEventResponse() {
    yield takeEvery(UPDATE_RSVP_RESPONSE_TYPE[REQUEST], updateRsvpResponse)
}

function* watchGetSelectedEventResponse() {
    yield takeEvery(GET_EVENT_RESPONSE[REQUEST], getSelectedEventResponse)
}

function* watcherGetUserRSVPEvent() {
    yield takeEvery(FETCH_RSVP_EVENT_BY_USER[REQUEST], getUserRSVPEvent)
}

function* watcherGetRSVPFilterEvent() {
    yield takeEvery(FETCH_FILTER_BY_USER[REQUEST], getRSVPFilterEvent)
}

function* watcherSendEventResponse() {
    yield takeEvery(SET_EVENT_RESPONSE[REQUEST], sendEventResponse)
}

function* watcherRemoveSignup() {
    yield takeEvery(REMOVE_CHILD_EVENT[REQUEST], removeSignup)
}

function* watcherTransferOwnerResponse() {
    yield takeEvery(TRANSFER_OWNERSHIP[REQUEST], transferOwnerShip)
}

function* watcherDeleteTimeSlot() {
    yield takeEvery(DELETE_SLOT_TIME[REQUEST], applyDeleteTimeSlot)
}

export default function* rootVolunteer() {
    yield all([
        fork(watchCreateEvent),
        fork(watchGetEvent),
        fork(watcherDeleteEvent),
        fork(watchGetEventById),
        fork(watchGetSelectedEventResponse),
        fork(watchSetRSVPEventResponse),
        fork(watcherSendEventResponse),
        fork(watcherRemoveSignup),
        fork(watchUpdateRSVPEventResponse),
        fork(watcherGetUserRSVPEvent),
        fork(watcherTransferOwnerResponse),
        fork(watcherDeleteTimeSlot),
        fork(watcherGetRSVPFilterEvent)
    ]);
}