import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import SecondGrid  from './SecondGrid';

const styles = theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  fontBold: {
    fontWeight: 'bold'
  }
});



export class NewsLetter extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container item className={classes.marginBottom} >
          <Typography variant="h5" className={classes.fontBold}>
            Coming Soon...
          </Typography>
        </Grid>
        {/* <SecondGrid history = {this.props.history} /> */}


      </>
    )
  }
}

export default withStyles(styles)(NewsLetter);
